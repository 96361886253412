//Generate merged PDF
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "../vfs_fonts";
import { calculateWidths, isRawAndColumnLength } from "./arrayToPdf";
import { FormatCalendar } from "../redux/utils";

// Register fonts

export async function arrayToPdfMerged(dataArray, translations, branchName) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  pdfMake.fonts = {
    SegoUi: {
      normal: "segoeuiRegular.ttf",
      bold: "segoeuiBold.ttf",
    },
  };

  const pdfDocs = [];
  const filenames = [];
  const formattedNameLeft = formatName(dataArray[0]);
  const formattedNameRight = formatName(dataArray[1]);

  for (let i = 0; i < dataArray.length; i++) {
    const data = dataArray[i];
    const tables = JSON.parse(JSON.stringify(data.tables));
    const formattedName = formatName(data);
    const filename = `${branchName[i]}-${formattedName}-${i + 1}.pdf`;
    filenames.push(filename);

    const mainHeader = {
      name: formattedName,
      startDate: FormatCalendar(data.startDate, ".", true, false),
      endDate: FormatCalendar(data.endDate, ".", true, false),
      header: data.header,
    };

    isRawAndColumnLength(tables);

    const documentDefinition = {
      content: [
        [
          branchName[i] && {
            text: `${translations.branchName}: ${branchName[i]}`,
            fontSize: 12,
            semibold: true,
            margin: [0, 0, 0, 5],
          },
          {
            text: `${translations.name}: ${mainHeader.name}`,
            fontSize: 12,
            semibold: true,
            margin: [0, 0, 0, 5],
          },
          {
            text: `${translations.startD}: ${mainHeader.startDate}`,
            fontSize: 12,
            semibold: true,
            margin: [0, 0, 0, 5],
          },
          {
            text: `${translations.endD}: ${mainHeader.endDate}`,
            fontSize: 12,
            semibold: true,
            margin: [0, 0, 0, 5],
          },
          { text: "", margin: [0, 10, 0, 0] }, // Add space between header and tables
        ],
        ...tables.map((table) => [
          {
            text: table.name,
            fontSize: 16,
            semibold: true,
            margin: [0, 0, 0, 0],
          },
          {
            table: {
              headerRows: 1,
              //widths: table.columns.map(() => "*"),
              widths:
                table.columns.length < 6
                  ? table.columns.map(() => "*")
                  : calculateWidths(table.columns, table.rows),
              body: [
                table.columns.map((column) => ({
                  text: column.header || "", // Handle null or undefined header
                  font: "SegoUi",
                  fontSize: 10,
                  semibold: true,
                  alignment: "left",
                  fillColor: "#FAF7F7", // Remove background color
                  color: "#393636", // Change text color to black
                  border: [true, true, true, true], // Add borders to left, top, and right, bottom
                  margin: [2, 0, 0, 0], // Adjust margins
                })),
                ...(table.rows || []).map((row) =>
                  row.cells.map((cell) => ({
                    text: cell || "",
                    font: "SegoUi",
                    fontSize: 10,
                    alignment: "left",
                    border: [true, false, true, true], // Add borders to left and right
                    margin: [2, 0, 0, 0],
                  }))
                ),
              ],
            },
            layout: {
              hLineWidth: () => 0.5, // Horizontal line width
              vLineWidth: () => 0.5, // Vertical line width
              hLineColor: () => "#9F9F9F", // Horizontal line color
              vLineColor: () => "#9F9F9F", // Vertical line color
              paddingLeft: () => 1, // Padding left for each cell
              paddingRight: () => 1, // Padding right for each cell
              paddingTop: () => 5, // Padding top for each cell
              paddingBottom: () => 5, // Padding bottom for each cell
            },
          },
          { text: "", margin: [0, 5, 0, 0] }, // Add space between tables
        ]),
      ],
      defaultStyle: {
        font: "SegoUi",
      },
    };

    //const pdfDoc = pdfMake.createPdf(documentDefinition);
    pdfDocs.push({ docDefinition: documentDefinition, filename: filename });
  }

  const mergedContent = [];
  pdfDocs.forEach((doc, index) => {
    mergedContent.push(doc.docDefinition.content);
    if (index !== pdfDocs.length - 1) {
      mergedContent.push({ text: "", pageBreak: "after" });
    }
  });

  const mergedPdf = pdfMake.createPdf({
    content: mergedContent.flat(), // Flatten the array of content
    defaultStyle: {
      font: "SegoUi",
    },
  });

  // Download the merged PDF
  const filename = `${branchName[0]}-${formattedNameLeft}-${branchName[1]}-${formattedNameRight}.pdf`;
  return mergedPdf.download(`${filename}`);
}
export const formatName = (inputName) => {
  return inputName.name.replace(/^PTR-|[^a-zA-Z\sıIğĞüÜşŞiİöÖçÇ]/g, "").trim();
};
