import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosPrivate } from "../api";
import { formatToUrl } from "../utils";

const api = axiosPrivate();
const port = process.env.REACT_APP_BACKEND_PORT;

const initialState = {
  allReports: {
    reports: [],
    loading: false,
    error: null,
    success: false,
  },
  reportDetails: {
    data: [],
    loading: false,
    error: null,
    success: false,
  },
  reportSummary: null,
};

const reportSlice = createSlice({
  name: "report",
  initialState: initialState,
  reducers: {
    resetAllReportsState: (state) => {
      state.allReports = initialState.allReports;
    },
    resetReportDetailsState: (state) => {
      state.reportDetails = initialState.reportDetails;
    },
    resetGetSummaryState: (state) => {
      state.reportSummary = initialState.reportSummary;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllReports.pending, (state) => {
        state.allReports = {
          loading: true,
          success: false,
          reports: [],
          error: false,
        };
      })
      .addCase(getAllReports.fulfilled, (state, action) => {
        state.allReports = {
          loading: false,
          reports: action.payload,
          success: true,
          error: false,
        };
      })
      .addCase(getAllReports.rejected, (state, action) => {
        state.allReports = {
          loading: false,
          success: false,
          reports: [],
          error: action.error.message,
        };
      })
      .addCase(getReportDetails.pending, (state) => {
        state.reportDetails = {
          loading: true,
          success: false,
          data: [],
          error: false,
        };
      })
      .addCase(getReportDetails.fulfilled, (state, action) => {
        state.reportDetails = {
          loading: false,
          data: action.payload,
          success: true,
          error: false,
        };
      })
      .addCase(getReportDetails.rejected, (state, action) => {
        state.reportDetails = {
          loading: false,
          success: false,
          data: [],
          error: action.error.message,
        };
      })
      .addCase(getReportSummary.pending, (state, action) => {
        state.reportSummary = {
          ...state.reportSummary,
          [action.meta.arg.branchUrl]: {
            loading: true,
            success: false,
            error: null,
          },
        };
      })
      .addCase(getReportSummary.fulfilled, (state, action) => {
        state.reportSummary = {
          ...state.reportSummary,
          [action.payload.branchUrl]: {
            loading: false,
            success: true,
            error: null,
            data: action.payload.data,
          },
        };
      })
      .addCase(getReportSummary.rejected, (state, action) => {
        state.reportSummary = {
          ...state.reportSummary,
          [action.meta.arg.branchUrl]: {
            loading: false,
            success: false,
            error: action.error,
          },
        };
      });
  },
});

export const getAllReports = createAsyncThunk(
  "reports/getAllReports",
  async ({ branchUrl }) => {
    const formattedUrl = formatToUrl(branchUrl);
    //const response = await api.get(`${formattedUrl}/get_reports`);

    const response = await api.get(`${port}/reports/get_reports`, {
      params: {
        path: formattedUrl,
      },
    });
    return response.data;
  }
);

export const getReportDetails = createAsyncThunk(
  "reports/getReportDetails",
  async ({ branchUrl, reportId, start_date, end_date }) => {
    try {
      const formattedUrl = formatToUrl(branchUrl);

      const params =
        start_date && end_date
          ? { start_date, end_date }
          : start_date
          ? { start_date }
          : end_date
          ? { end_date }
          : {};

      //const response = await api.get(`${formattedUrl}/get_report_table`, {
      //  params: {
      //    report: reportId,
      //    use_work_period: false,
      //    ...params,
      //  },
      //});

      //console.log(params, reportId);

      const response = await api.get(`${port}/reports/get_report_table`, {
        params: {
          params: {
            report: reportId,
            use_work_period: false,
            ...params,
          },
          path: formattedUrl,
        },
      });

      return response.data;
    } catch (err) {
      //console.log(err);
      throw err;
    }
  }
);

export const getReportSummary = createAsyncThunk(
  "reports/getSummary",
  async ({ branchUrl }, { rejectWithValue }) => {
    try {
      const formattedUrl = formatToUrl(branchUrl);
      const response = await api.get(`${port}/reports/report_summary`, {
        params: { path: formattedUrl },
      });

      //const response = await api.get(`${formattedUrl}/get_report_table`, {
      //  params: { report: "PTR-Case Summary" },
      //});

      //console.log(response.data);
      const { data } = response;
      if (!data || !data.tables) {
        return rejectWithValue("No rows found in the report data.");
      }

      return {
        branchUrl,
        data: response.data,
      };
    } catch (err) {
      return rejectWithValue(
        err.message || "An error occurred while fetching the report summary."
      );
    }
  }
);

export default reportSlice.reducer;
export const {
  resetAllReportsState,
  resetGetSummaryState,
  resetReportDetailsState,
} = reportSlice.actions;
