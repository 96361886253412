// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

//Language resources
import global_en from "./locales/en/translation.json";
import global_tr from "./locales/tr/translation.json";

//GetUsersLocation
//import getUsersLocation from "./userLocation";
//const language = (await getUsersLocation()) || "en";
const localLang = JSON.parse(localStorage.getItem("lang"));
export const lang = typeof localLang === "string" ? localLang : "tr";

const resources = {
  en: {
    translation: global_en,
  },
  tr: {
    translation: global_tr,
  },
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: lang,
    interpolation: { escapeValue: false },
  });

export default i18n;
