import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosPrivate } from "../api";
import { formatToUrl } from "../utils";

const api = axiosPrivate();
const port = process.env.REACT_APP_BACKEND_PORT;

const initialState = {
  getUnpaidTickets: {
    tickets: [],
    loading: false,
    error: null,
    success: false,
  },
  getTicketDetail: {
    loading: false,
    error: null,
    success: false,
    ticket: null,
  },
};

const ticketSlice = createSlice({
  name: "ticket",
  initialState: initialState,
  reducers: {
    resetGetTicketDetail: (state) => {
      state.getTicketDetail = initialState.getTicketDetail;
    },
    resetGetUnpaidTickets: (state) => {
      state.getUnpaidTickets = initialState.getUnpaidTickets;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUnpaidTickets.pending, (state) => {
        state.getUnpaidTickets = {
          loading: true,
          success: false,
          tickets: [],
          error: false,
        };
      })
      .addCase(getUnpaidTickets.fulfilled, (state, action) => {
        state.getUnpaidTickets = {
          loading: false,
          tickets: action.payload,
          success: true,
          error: false,
        };
      })
      .addCase(getUnpaidTickets.rejected, (state, action) => {
        state.getUnpaidTickets = {
          loading: false,
          success: false,
          tickets: [],
          error: action.error.message,
        };
      })
      .addCase(getTicketDetail.pending, (state) => {
        state.getTicketDetail = {
          loading: true,
          success: false,
          error: false,
          ticket: null,
        };
      })
      .addCase(getTicketDetail.fulfilled, (state, action) => {
        state.getTicketDetail = {
          loading: false,
          success: true,
          error: false,
          ticket: action.payload,
        };
      })
      .addCase(getTicketDetail.rejected, (state, action) => {
        state.getTicketDetail = {
          loading: false,
          success: false,
          error: action.error.message,
          ticket: null,
        };
      });
  },
});

export const getUnpaidTickets = createAsyncThunk(
  "ticket/getUnpaidTickets",
  async ({ branchUrl }) => {
    const formattedUrl = formatToUrl(branchUrl);
    //const response = await api.get(`${formattedUrl}/get_work_period_unpaid_tickets`);
    const response = await api.get(`${port}/tickets/getUnpaidTickets`, {
      params: {
        path: formattedUrl,
      },
    });
    return response.data;
  }
);

export const getTicketDetail = createAsyncThunk(
  "ticket/getTicketDetail",
  async ({ branchUrl, ticket_id }) => {
    const formattedUrl = formatToUrl(branchUrl);
    //console.log(formattedUrl, ticket_id);

    //const response = await api.get(`${formattedUrl}/get_ticket_details`, {
    //  params: { ticket_id },
    //});

    const response = await api.get(`${port}/tickets/getTicketDetail`, {
      params: {
        path: formattedUrl,
        ticket_id,
      },
    });
    return response.data;
  }
);

export default ticketSlice.reducer;
export const { resetGetTicketDetail, resetGetUnpaidTickets } =
  ticketSlice.actions;
