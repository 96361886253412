import toast from "react-hot-toast";
import { toPng } from "html-to-image";
import download from "downloadjs";

export const formatToUrl = (url, params) => {
  const domainExtensionRegex = /\.(com|net|org|edu|gov|co|io|info|app|life)$/i;
  if (
    domainExtensionRegex.test(url) &&
    !url.startsWith("http://") &&
    !url.startsWith("https://") &&
    url.endsWith(RegExp.$1)
  ) {
    url = `https://${url}`;
  } else if (url.startsWith("http://")) {
    url = url.replace("http://", "https://");
    //console.log("problem");
  } else if (!url.startsWith("http://") && !url.startsWith("https://")) {
    if (url.includes(":")) {
      url = `http://${url}`;
    } else {
      url = `http://${url}:9090`;
    }
  }
  if (params) {
    const urlParams = new URLSearchParams(params);
    return `${url}?${urlParams.toString()}`;
  }
  return url;
};

export const extractIp = (branchIp) => {
  return branchIp.replace("http://", "").replace("https://", "");
};

export const formatDate = (date) => {
  const formattedDate = new Date(date);
  const month = (formattedDate.getMonth() + 1).toString().padStart(2, "0");
  const day = formattedDate.getDate().toString().padStart(2, "0");
  const year = formattedDate.getFullYear().toString().padStart(4, "0");
  const hours = formattedDate.getHours().toString().padStart(2, "0");
  const minutes = formattedDate.getMinutes().toString().padStart(2, "0");
  const seconds = formattedDate.getSeconds().toString().padStart(2, "0");
  return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
};

export const formatToDate = (date) => {
  // the param date format will be like 05/12/2024 13:57:46 "month/date/year t:mm:ss" this function gives the correct form
  const [datePart, timePart] = date.split(" ");
  const [day, month, year] = datePart.split("/");
  const [hours, minutes, seconds] = timePart.split(":");
  return new Date(year, day - 1, month, hours, minutes, seconds);
};

export function FormatCalendar(
  inputDate,
  dateJoin,
  withTime = true,
  withSecond = true,
  onlyTime = false
) {
  const date = new Date(inputDate);
  //console.log(inputDate, "input date", date, "date");
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  const formattedDay = (day < 10 ? "0" : "") + day;
  const formattedMonth = (month < 10 ? "0" : "") + month;
  const formattedYear = (year < 10 ? "0" : "") + year;
  const formattedHour = (hour < 10 ? "0" : "") + hour;
  const formattedMinute = (minute < 10 ? "0" : "") + minute;
  const formattedSecond = (second < 10 ? "0" : "") + second;

  const dJoin = dateJoin ? dateJoin : "/";
  const formattedDate = `${formattedDay}${dJoin}${formattedMonth}${dJoin}${formattedYear}`;
  const formattedTime = ` ${formattedHour}:${formattedMinute}`;
  const formattedSec = `:${formattedSecond}`;

  //console.log(formattedDate, "out date");
  const formattedCalendar = withSecond
    ? formattedDate + formattedTime + formattedSec
    : withTime
    ? formattedDate + formattedTime
    : formattedDate;
  return onlyTime ? formattedTime : formattedCalendar;
}

export const formatNumber = (num) => {
  //const step1 = num.toFixed(2);
  //const step2 = parseFloat(step1);
  //const step3 = step2.toLocaleString("tr-TR");
  //console.log(step1, step2, step3);

  const formattedNum = new Intl.NumberFormat("us-EN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num);
  return formattedNum;
};

export const formatPrice = (num) => {
  const formattedNum = new Intl.NumberFormat("us-EN", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(num);
  return formattedNum;
};

export const customMaxInput = (e) => {
  const value = e.target.value;
  const useVal = e.target.value.replace(/[^\d]/g, "");
  const maxAllowed = e.target?.maxLength;

  if (!maxAllowed) {
    return value;
  }

  if (useVal.length > maxAllowed) {
    return value.slice(0, maxAllowed);
  }
  //console.log(value);
  return value;
};

export const htmlToPng = async (
  ref,
  ticketName,
  ticketID,
  branchName,
  success,
  converting,
  error,
  setState
) => {
  if (ref.current) {
    toast.loading(`${converting}...`, { position: "top-right" });
    try {
      const dataUrl = await toPng(ref.current);
      download(dataUrl, `${ticketName} ${ticketID} ${branchName}.png`);
      toast.dismiss();
      toast.success(success, { position: "top-right" });
      setState && setState(false);
    } catch (err) {
      toast.dismiss();
      toast.error(error, {
        position: "top-right",
      });
      setState && setState(false);
      console.error("Error capturing and downloading the image", err);
    }
  }
};

export function today() {
  let now = new Date();
  let start = new Date(now.setHours(0, 0, 0, 0));
  let end = new Date();
  return { start: formatDate(start), end: formatDate(end) };
}

export function yesterday() {
  let now = new Date();
  let start = new Date(now.setDate(now.getDate() - 1));
  start.setHours(0, 0, 0, 0);
  let end = new Date(start);
  end.setHours(23, 59, 59, 999);
  return { start: formatDate(start), end: formatDate(end) };
}

export function thisWeek() {
  let now = new Date();
  let start = new Date(
    now.setDate(now.getDate() - now.getDay() + (now.getDay() === 0 ? -6 : 1))
  );
  start.setHours(0, 0, 0, 0);
  let end = new Date();
  return { start: formatDate(start), end: formatDate(end) };
}

export function lastWeek() {
  let now = new Date();
  let start = new Date(
    now.setDate(
      now.getDate() - now.getDay() + (now.getDay() === 0 ? -6 : 1) - 7
    )
  );
  start.setHours(0, 0, 0, 0);
  let end = new Date(start);
  end.setDate(end.getDate() + 6);
  end.setHours(23, 59, 59, 999);
  return { start: formatDate(start), end: formatDate(end) };
}

export function thisMonth() {
  let now = new Date();
  let start = new Date(now.getFullYear(), now.getMonth(), 1);
  start.setHours(0, 0, 0, 0);
  let end = new Date();
  return { start: formatDate(start), end: formatDate(end) };
}

export function lastMonth() {
  let now = new Date();
  let start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
  start.setHours(0, 0, 0, 0);
  let end = new Date(now.getFullYear(), now.getMonth(), 0);
  end.setHours(23, 59, 59, 999);
  return { start: formatDate(start), end: formatDate(end) };
}

export function thisYear() {
  let now = new Date();
  let start = new Date(now.getFullYear(), 0, 1);
  start.setHours(0, 0, 0, 0);
  let end = new Date();
  return { start: formatDate(start), end: formatDate(end) };
}
