import { FormatCalendar, formatNumber } from "../../redux/utils";
import "../common/customTable.css";
import { useTranslation } from "react-i18next";

const OrdersTable = ({ orders, className }) => {
  const { t } = useTranslation();
  return (
    <div className={`border-[.5px] border-solid border-[--gr-3] ${className}`}>
      <table className="w-full text-sm text-center text-[--gr-5]">
        <thead>
          <tr>
            <th
              className="sticky-column px-2 py-[1px] text-[.6rem] font-normal text-[--tx-1] bg-[--gr-1] flex items-start"
              colSpan="2"
            >
              {`${t("ticketDetails.orderNo")}: ${orders.id} - ${
                orders.orders[0].user
              }`}{" "}
              {FormatCalendar(orders.orders[0].date, "-", true, false, true)}
            </th>
          </tr>
        </thead>
        <tbody>
          {orders.orders.map((order, index) => {
            return (
              <tr
                key={index}
                className="odd:bg-[--btn-txt] even:bg-[--gr--0] border-b flex justify-between"
              >
                <td className="px-6 py-4 flex-col flex items-start justify-start">
                  <div className="flex">
                    <div>
                      <span className="bg-[--gr-1] px-3 mr-1 rounded-md">
                        {order.quantity}
                      </span>
                    </div>
                    <div className="text-start">
                      <p>
                        {order.name}{" "}
                        {order.portion !== "Normal" && order.portion}
                      </p>
                      {order.states.map(
                        (state, index) =>
                          state.stateValue && (
                            <p
                              key={index}
                              className="text-xs text-red-500 block"
                            >
                              {state.state}-{state.stateValue}
                            </p>
                          )
                      )}
                      {order.tags &&
                        order.tags.map((tag, index) => (
                          <p key={index} className="text-xs text-[--tx-6]">
                            {tag.quantity > 1 && `${tag.quantity} x `}
                            {tag.tag}
                          </p>
                        ))}
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 font-normal text-green-500">
                  <div className="text-end">
                    {formatNumber(order.price * order.quantity)}
                  </div>
                  <div className="flex flex-col text-end">
                    {order.tags &&
                      order.tags.map((tag, index) => (
                        <span key={index} className="text-xs text-green-500">
                          {formatNumber(
                            tag.price * tag.quantity * order.quantity
                          )}
                        </span>
                      ))}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default OrdersTable;
