import { useTranslation } from "react-i18next";
import NotFoundSvg from "./TheSvg";
import Footer from "../footer/footer";

const NotFound = ({nav}) => {
	const { t } = useTranslation();

	const goBack = () => {
		window.history.back()
	}

  return ( 
		<>
		<section className="px-0">
			{nav && nav}
			<div className="bg-[var(--gr-2)] text-[var(--gr-7)]">
				<div className="flex h-screen">
					<div className="m-auto text-center">
						<div className="px-[4%]">
							<NotFoundSvg/>
						</div>
						<p className="text-sm md:text-base p-2 mb-4">{t("404.msg")}
						</p>
						<button onClick={goBack}
							className="bg-[#656ED3] text-[var(--tx-4)] py-3 px-[10%] hover:bg-[#656ed3e9] duration-300 rounded-full">
							{t("404.back")}</button>
					</div>
				</div>
			</div>
		</section>
		<Footer/>
		</>
   );
}
 
export default NotFound;