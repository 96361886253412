import { useState } from "react";
//import CustomSelect from "../components/common/customSelect";
import ProductPrice from "./productPrice";
import OrderTags from "./orderTags";
import { useTranslation } from "react-i18next";

const FiyatListesi = () => {
  const { t } = useTranslation();
  /*  const showOptions = [
    {
      label: "Products",
      value: "products",
    },
    {
      label: "Order Tags",
      value: "orderTags",
    },
  ];
  const [show, setShow] = useState(showOptions[0]); */
  const [show, setShow] = useState("products");
  const handleShowChange = (value) => {
    setShow(value);
  };

  return (
    <div className="full_height-footer">
      {/* <div className="my-4 mx-4 sm:mx-6 md:mx-8 lg:mx-12 flex items-center justify-start gap-3">
        <div className="hidden sm:block text-[--tx-1]">Show: </div>
        <CustomSelect
          value={show}
          onChange={setShow}
          options={showOptions}
          className="w-full col-span-5" 
        />
      </div>
      {show.value === "products" ? <ProductPrice /> : <OrderTags />} */}
      <div className="my-4 px-[4%] flex items-center justify-start gap-3">
        <div className="flex gap-3">
          <button
            key={0}
            className={`px-4 py-2 rounded-lg bg-[--primary] text-[--btn-txt] ${
              show === "products" ? "bg-green-600 opacity-100" : "opacity-50"
            }`}
            onClick={() => handleShowChange("products")}
          >
            {t("priceList.prods")}
          </button>
          <button
            key={1}
            className={`px-4 py-2 rounded-lg bg-[--primary] text-[--btn-txt] ${
              show === "orderTags" ? "bg-green-600 opacity-100" : "opacity-50"
            }`}
            onClick={() => handleShowChange("orderTags")}
          >
            {t("nav.tags")}
          </button>
        </div>
      </div>
      {show === "products" ? <ProductPrice /> : <OrderTags />}
    </div>
  );
};

export default FiyatListesi;
