import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ViewReport from "../components/reportsPage/viewCustomButton";
import EditUser from "../components/userForm/editUser";
import AddUser from "../components/userForm/addUser";
import TableSkeleton from "../components/common/tableSkeleton";
import ErrorDisplay from "../components/common/error";
import {
  getUsers,
  resetAddUser,
  resetEditUser,
} from "../redux/users/usersSlice";
import { filterUsers, findRoleName } from "../utils/filterUsers";
import CustomInput from "../components/common/customInput";
import { useTranslation } from "react-i18next";
import { FaQuestion } from "react-icons/fa6";
import { extractIp } from "../redux/utils";
import { customToast } from "../components/branches/branchCard";

const UserActions = () => {
  const { t } = useTranslation();

  const branchUrl = useParams().id;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [selected, setSelected] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isHovered, setIsHovered] = useState({});

  const handleClick = (user) => {
    setSelected(user);
    setOpen(true);
  };

  useEffect(() => {
    dispatch(getUsers({ branchUrl }));
  }, [dispatch, branchUrl]);

  const usersReducer = useSelector((state) => state.users);
  const {
    loading: usersLoading,
    success: usersSuccess,
    error: usersError,
    users,
  } = usersReducer.getUsers;

  useEffect(() => {
    return () => {
      dispatch(resetAddUser());
      dispatch(resetEditUser());
    };
  });
  const filteredUsers = filterUsers(users, searchQuery);

  const handleMouseEnter = (id) => {
    setIsHovered((prevHoverStates) => ({
      ...prevHoverStates,
      [id]: true,
    }));
  };
  const handleMouseLeave = (id) => {
    setIsHovered((prevHoverStates) => ({
      ...prevHoverStates,
      [id]: false,
    }));
  };

  useEffect(() => {
    if (open || openAdd) {
      document.body.classList.add("body_overflow_hidden");
    } else {
      document.body.classList.remove("body_overflow_hidden");
    }

    return () => {
      document.body.classList.remove("body_overflow_hidden");
    };
  }, [open, openAdd]);

  ///************* BRANCH STATUS AND LICENCE CONTROL *****************///
  const branchIp = useParams().id;
  const navigate = useNavigate();
  const { branches: allBranches } = useSelector((state) => state.branches);
  const allSummaryData = useSelector((state) => state.reports.reportSummary);
  useEffect(() => {
    const thisBranch = [];
    if (allBranches.length > 0) {
      const pushBranch = allBranches.filter(
        (branch) => extractIp(branch.ipAddress) === String(branchIp)
      );
      thisBranch.push(pushBranch[0]);
    }

    if (!allSummaryData) return;
    if (thisBranch.length > 0 && Object.keys(allSummaryData).length > 0) {
      const thisBranchIp = thisBranch[0].ipAddress;
      if (!allSummaryData?.[thisBranchIp].loading) {
        if (
          !allSummaryData?.[thisBranchIp].success ||
          thisBranch[0]?.status !== 1
        ) {
          console.log(false);
          navigate("/");
          customToast(`${t("branches.inActive", { branch: "" })}`);
          //console.log(allSummaryData?.[thisBranchIp]);
          //console.log(thisBranch[0]?.status);
        } else {
          console.log(true);
        }
      }
    }
  }, [allBranches, allSummaryData, branchIp, navigate, t]);
  ///************* BRANCH STATUS AND LICENCE CONTROL *****************///

  return (
    <section className="min-h-[100vh] flex flex-col items-center">
      <main className="w-full md:w-[40rem]">
        <div className="flex flex-col sm:flex-row justify-between sm:items-center">
          <div className="sm:mr-2 md:w-full md:max-w-80">
            <CustomInput
              type="text"
              placeholder={t("nav.search")}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full left-0 mb-2 p-3 rounded text-[--gr-4] text-sm border border-[--gr-2] border-solid cursor-text"
            />
          </div>
          {usersSuccess && (
            <button
              type="button"
              onClick={() => setOpenAdd(true)}
              className="text-white bg-[--primary] hover:bg-[--primary-hover] justify-center font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
            >
              <svg
                className="w-5 h-5 me-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 21"
              >
                <path
                  fillRule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              {t("userMgmnt.add")}
            </button>
          )}
        </div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mb-8">
          {usersSuccess ? (
            <>
              {filteredUsers.length > 0 ? (
                <table className="w-full text-sm text-left rtl:text-right text-[--gr-5]">
                  <thead className="text-xs text-[--gr-7] uppercase bg-[--gr-0]">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-6 font-medium text-lg normal-case"
                      >
                        {t("userMgmnt.name")}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-6 font-medium text-lg normal-case"
                      >
                        {t("userMgmnt.pass")}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-6 font-medium text-lg normal-case"
                      >
                        {t("userMgmnt.role")}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-6 font-medium text-lg text-right normal-case"
                      >
                        {t("userMgmnt.act")}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {filteredUsers.map((entity, index) => (
                      <tr
                        key={index}
                        className="odd:bg-[--btn-txt] even:bg-[--gr-0]"
                      >
                        <th
                          scope="row"
                          className="px-6 py-2 font-medium text-[--gr-9] whitespace-nowrap"
                        >
                          {entity.name}
                        </th>
                        <th
                          scope="row"
                          className="px-6 py-2 font-medium text-[--gr-9] whitespace-nowrap"
                        >
                          {entity.pinCode}
                        </th>
                        <th
                          scope="row"
                          className="px-6 py-2 font-medium text-[--gr-9] whitespace-nowrap"
                        >
                          {findRoleName(entity.userRoleId, users)}
                        </th>
                        <td className="px-6 py-2 text-right">
                          {entity.isEditable ? (
                            <ViewReport
                              onClick={() => handleClick(entity)}
                              text={t("userMgmnt.edit")}
                            />
                          ) : (
                            <div className="flex justify-end relative ">
                              <div
                                className="cursor-pointer rounded-full border border-[--gr9] border-solid p-1 transition-all"
                                key={entity.id}
                                onMouseEnter={() => handleMouseEnter(entity.id)}
                                onMouseLeave={() => handleMouseLeave(entity.id)}
                              >
                                <FaQuestion />
                              </div>
                              <div
                                className={`py-8 px-4 bg-[--gr-2] text-[--gr-9] w-max absolute -top-1 right-6 rounded-md ${
                                  isHovered[entity.id] ? "visible" : "invisible"
                                }`}
                              >
                                <p>{t("userMgmnt.msg")}</p>
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <ErrorDisplay error="No Results Found" />
              )}
              <AddUser
                open={openAdd}
                onClose={() => setOpenAdd(false)}
                roles={users.userRoles}
                users={users.users}
              />
              <EditUser
                open={open}
                onClose={() => setOpen(false)}
                roles={users.userRoles}
                user={selected}
                users={users.users}
              />
            </>
          ) : usersLoading ? (
            <TableSkeleton />
          ) : usersError ? (
            <ErrorDisplay
              error={usersError}
              onRetry={() => dispatch(getUsers({ branchUrl }))}
            />
          ) : (
            <></>
          )}
        </div>
      </main>
    </section>
  );
};

export default UserActions;
