const CustomInput = ({
  type,
  id,
  value,
  onChange,
  disabled,
  className,
  placeholder,
  required,
  checked,
  name,
  reference: ref,
  onKeyDown,
  minLength,
  maxLength,
  autoFocus,
}) => {
  return (
    <input
      autoFocus={autoFocus}
      type={type}
      id={id}
      ref={ref}
      name={name}
      value={value || ""}
      onChange={onChange}
      className={`w-full p-2 border border-[--gr--3] border-solid rounded-md bg-[--btn-txt] text-[--gr-5] ${className}`}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      checked={checked}
      onKeyDown={onKeyDown}
      minLength={minLength}
      maxLength={maxLength}
    />
  );
};

export default CustomInput;
