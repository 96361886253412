import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosPrivate } from "../api";
import { formatToUrl } from "../utils";

const api = axiosPrivate();
const port = process.env.REACT_APP_BACKEND_PORT;

const initialState = {
  restartMesgServer: {
    loading: false,
    success: false,
    reponse: null,
    error: null,
  },
};

const messagingServerSlice = createSlice({
  name: "messagingServer",
  initialState: initialState,
  reducers: {
    resetRestartMsgServerState: (state) => {
      state.restartMesgServer.loading = false;
      state.restartMesgServer.success = false;
      state.restartMesgServer.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(restartMessagingServer.pending, (state) => {
        state.restartMesgServer.loading = true;
        state.restartMesgServer.success = false;
        state.restartMesgServer.error = null;
      })
      .addCase(restartMessagingServer.fulfilled, (state, action) => {
        state.restartMesgServer.loading = false;
        state.restartMesgServer.success = true;
        state.restartMesgServer.error = null;
        state.restartMesgServer.reponse = {
          ...state.restartMesgServer.reponse,
          [action.payload.branchUrl]: action.payload.data,
        };
      })
      .addCase(restartMessagingServer.rejected, (state, action) => {
        state.restartMesgServer.loading = false;
        state.restartMesgServer.success = true;
        state.restartMesgServer.error = action.error;
      });
  },
});

export const restartMessagingServer = createAsyncThunk(
  "branch/restartMessagingServer",
  async ({ branchUrl }) => {
    const formattedUrl = formatToUrl(branchUrl);
    //const response = await api.post(`${formattedUrl}/restart_messaging_server`);
    const response = await api.post(`${port}/other/server`, {
      path: formattedUrl,
    });

    return {
      branchUrl,
      data: response.data,
    };
  }
);

export default messagingServerSlice.reducer;
export const { resetRestartMsgServerState } = messagingServerSlice.actions;
