export function filterReportTable(data, searchQuery) {
  return (
    data &&
    data.tables &&
    data.tables
      .map((table) => {
        const nameMatches =
          table.name &&
          table.name.toLowerCase().includes(searchQuery.toLowerCase());

        const columnMatches =
          table.columns &&
          table.columns.some(
            (column) =>
              column.header &&
              column.header.toLowerCase().includes(searchQuery.toLowerCase())
          );

        const filteredRows = table.rows.filter((row) =>
          row.cells.some((cell) =>
            String(cell).toLowerCase().includes(searchQuery.toLowerCase())
          )
        );

        if (nameMatches || columnMatches || filteredRows.length > 0) {
          return {
            ...table,
            rows: nameMatches || columnMatches ? table.rows : filteredRows,
          };
        } else {
          return null;
        }
      })
      .filter((table) => table !== null)
  );
}

export const isEmpty = (report) => {
  return report && report.every((table) => table.rows.length === 0);
};
