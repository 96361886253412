import { useDispatch, useSelector } from "react-redux";
import { toggleDarkMode } from "../../redux/theme/themeSlice";
import { MdOutlineDarkMode } from "react-icons/md";
import { MdOutlineLightMode } from "react-icons/md";

const ThemeToggle = ({ className }) => {
  const { darkMode } = useSelector((state) => state.theme);
  const dispatch = useDispatch();

  const toggleMode = () => {
    dispatch(toggleDarkMode());
  };
  return (
    <div className="mr-2 flex items-center">
      <div
        onClick={() => toggleMode(!darkMode)}
        className={`cursor-pointer text-3xl text-[--tx-1] ${className}`}
      >
        <MdOutlineDarkMode className={`rotate-45 ${!darkMode && "hidden"}`} />
        <MdOutlineLightMode className={`${darkMode && "hidden"}`} />
      </div>
    </div>
  );
};

export default ThemeToggle;
