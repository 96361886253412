import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import tr from 'date-fns/locale/tr'; // Import Turkish locale from date-fns
import { useTranslation } from "react-i18next";

const DateTimePicker = ({ value, onChange, placeholder, isDisabled }) => {
  const { i18n } = useTranslation();
  registerLocale('tr', tr);
  return (
    <DatePicker
      locale={i18n.language}
      selected={value}
      onChange={onChange}
      showTimeSelect
      dateFormat="dd.MM.yyyy HH:mm"
      timeFormat="HH:mm"
      placeholderText={placeholder}
      className={`w-full p-2 border border-[--gr-3] border-solid rounded-md bg-[--btn-txt] text-[--gr-5] text-sm cursor-pointer`}
      disabled={isDisabled}
      popperClassName="react-datepicker-popper"
      styles={{
        popper: {
          left: "60px"
        }
      }}
    />
  ); //left 60px 640px-950px
};

export default DateTimePicker;
