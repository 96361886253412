const SidebarSkeleton = () => {
  const skeletonItems = Array.from({ length: 6 }, (_, index) => index);

  return (
    <div
      className={`hidden animate-pulse absolute top-0 h-full 950m:block 950m:static w-72 z-40 bg-[var(--bg-1)] transition-all`}
    >
      <div
        className={`border-[.5px] border-solid border-[--gr-3] bg-white/50 h-full flex flex-col gap-[.1rem] relative`}
      >
        <div className="border-0 border-b border-[--gr-2] border-solid rounded flex items-center justify-center py-6 animate-pulse">
          <div className="animate-pulse w-2/3 h-8 bg-[--gr-3] rounded"></div>
        </div>

        {skeletonItems.map((index) => (
          <div
            key={index}
            className="h-12 animate-pulse flex items-center p-2 pl-8 text-[var(--tx-1)] hover:bg-[#F9FAFB] hover:text-hoverBo1-important"
          >
            <div className="mr-2 border-[1.5px] border-solid w-8 h-full flex justify-center items-center rounded-lg bg-[--gr-3]"></div>
            <div className="w-2/3 h-full bg-[--gr-3] rounded"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SidebarSkeleton;
