import { useTranslation } from "react-i18next";
import { CiFaceFrown } from "react-icons/ci";

const ErrorDisplay = ({ error, onRetry, text }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex flex-col justify-center items-center h-96">
      <h1 className="text-xl font-normal text-[var(--tx-2)] Poppins">
        {error}
      </h1>
      <CiFaceFrown className="text-8xl text-[var(--tx-2)]" />
      {onRetry && (
        <button
          type="button"
          className="text-white bg-[--primary] hover:bg-[--primary-hover] font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2"
          onClick={onRetry}
        >
          {t("404.tryagain")}
        </button>
      )}
    </div>
  );
};

export default ErrorDisplay;
