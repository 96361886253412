import { HiRefresh } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { getReportSummary } from "../../redux/reports/reportsSlice";
import { useState } from "react";

const RefreshAllSummaries = () => {
  const dispatch = useDispatch();
  const { branches } = useSelector((state) => state.branches);
  const [isClicked, setIsClicked] = useState(false);

  const refreshAll = () => {
    setIsClicked(true);
    setTimeout(() => {
      setIsClicked(false);
    }, 1000);
    branches.forEach((branch) => {
      dispatch(getReportSummary({ branchUrl: branch.ipAddress }));
    });
  };

  return (
    <>
      <div
        className={`w-max h-max rounded-full text-xl bg-[--primary] p-[.2rem] cursor-pointer text-white ${
          isClicked && "rotate_360"
        }`}
        onClick={refreshAll}
      >
        <HiRefresh />
      </div>
    </>
  );
};

export default RefreshAllSummaries;
