import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { formatToUrl } from "../utils";
import { axiosPrivate } from "../api";

const api = axiosPrivate();
const port = process.env.REACT_APP_BACKEND_PORT;

const initialState = {
  getAutoCommand: {
    loading: false,
    success: false,
    error: null,
    data: null,
  },
  excuteAutoCommand: {
    loading: false,
    success: false,
    error: null,
  },
};

const autoCommandSlice = createSlice({
  name: "create automation command",
  initialState: initialState,
  reducers: {
    resetExcuteAutoCommand: (state) => {
      state.excuteAutoCommand.loading = false;
      state.excuteAutoCommand.success = false;
      state.excuteAutoCommand.error = false;
    },
    resetGetAutoCommand: (state) => {
      state.getAutoCommand.loading = false;
      state.getAutoCommand.success = false;
      state.getAutoCommand.error = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(excuteAutoCommands.pending, (state) => {
        state.excuteAutoCommand.loading = true;
        state.excuteAutoCommand.success = false;
        state.excuteAutoCommand.error = null;
      })
      .addCase(excuteAutoCommands.fulfilled, (state) => {
        state.excuteAutoCommand.loading = false;
        state.excuteAutoCommand.success = true;
        state.excuteAutoCommand.error = null;
      })
      .addCase(excuteAutoCommands.rejected, (state, action) => {
        state.excuteAutoCommand.loading = false;
        state.excuteAutoCommand.success = false;
        state.excuteAutoCommand.error = action.error.message;
      })
      .addCase(getAutomationCommands.pending, (state) => {
        state.getAutoCommand.loading = true;
        state.getAutoCommand.success = false;
        state.getAutoCommand.error = null;
      })
      .addCase(getAutomationCommands.fulfilled, (state, action) => {
        state.getAutoCommand.loading = false;
        state.getAutoCommand.success = true;
        state.getAutoCommand.error = null;
        state.getAutoCommand.data = {
          ...state.getAutoCommand.data,
          [action.payload.branchUrl]: action.payload.data,
        };
      })
      .addCase(getAutomationCommands.rejected, (state, action) => {
        state.getAutoCommand.loading = false;
        state.getAutoCommand.success = false;
        state.getAutoCommand.error = action.error.message;
      });
  },
});

export const excuteAutoCommands = createAsyncThunk(
  "branch/excuteAutoCommand",
  async (data) => {
    const { branchUrl, str } = data;
    const formattedUrl = formatToUrl(branchUrl);
    //const response = await api.post(
    //  `${formattedUrl}/execute_automation_command`,
    //  str
    //);
    const response = await api.post(`${port}/other/commands`, {
      path: formattedUrl,
      str,
    });

    return { data: response.data };
  }
);

export const getAutomationCommands = createAsyncThunk(
  "branch/getautomationcommands",
  async ({ branchUrl }) => {
    const formattedUrl = formatToUrl(branchUrl);
    //const response = await api.get(`${formattedUrl}/get_automation_commands`);
    const response = await api.get(`${port}/other/commands`, {
      params: {
        path: formattedUrl,
      },
    });

    return {
      branchUrl,
      data: response.data,
    };
  }
);

export default autoCommandSlice.reducer;
export const { resetExcuteAutoCommand, resetGetAutoCommand } =
  autoCommandSlice.actions;
