import BranchesNav from "../components/branches/branchesNav";
import { useEffect } from "react";
import BranchSkeleton from "../components/branches/branchSkeleton";
import BranchCard from "../components/branches/branchCard";
import { useSelector, useDispatch } from "react-redux";
import useAuth from "../hooks/useAuth";
import { getAllBranches } from "../redux/branches/branchSlice";
import ErrorDisplay from "../components/common/error";
//import { useTranslation } from "react-i18next";
import { getReportSummary } from "../redux/reports/reportsSlice";


const Branches = () => {
  //const { t } = useTranslation();
  const auth = useAuth().auth();
  const dispatch = useDispatch();
  const { loading, success, error, branches } = useSelector(
    (state) => state.branches
  );
  const reportSummary = useSelector((state) => state.reports.reportSummary)

  useEffect(() => {
    dispatch(getAllBranches(auth.user_id)); 
  }, [auth.user_id, dispatch]);

  const handleSummaryClick = (branchIp) => {
    dispatch(getReportSummary({branchUrl: branchIp}))
  }

  useEffect(() => {
    if (success && branches.length > 0) {
      branches.forEach(branch => {
        dispatch(getReportSummary({ branchUrl: branch.ipAddress }));
      });
    }
  }, [success, branches, dispatch]);  
  

  return (
    <>
      <BranchesNav />
      {error ? (
        <ErrorDisplay
          error={error}
          onRetry={() => dispatch(getAllBranches(auth.user_id))}
        />
      ) : (
        <section id="branches" className="min-h-[100vh] pb-4 pt-2">
          <div>
            {loading ? (
              <div className="flex w-full flex-col sm:grid sm:grid-cols-2 950m:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5">
                {Array.from({ length: 6 }).map((_, index) => (
                  <BranchSkeleton key={index} />
                ))}
              </div>
            ) : success ? (
              <div className="flex w-full flex-col sm:grid sm:grid-cols-2 950m:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5">
                {branches.map((branch, index) => (
                  <BranchCard
                    key={index}
                    branch={branch}
                    allSummaryData={reportSummary}
                    handleSummaryClick={handleSummaryClick}
                  />
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default Branches;
