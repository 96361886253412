import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api";

const initialState = {
  branches: [],
  loading: false,
  error: null,
  success: false,
};

const branchSlice = createSlice({
  name: "branches",
  initialState: initialState,
  reducers: {
    resetBranchesState: (state) => {
      state.loading = false;
      state.success = false;
      state.error = null;
      state.branches = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBranches.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
        state.branches = [];
      })
      .addCase(getAllBranches.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.branches = action.payload;
      })
      .addCase(getAllBranches.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.branches = [];
        state.error = action.error.message;
      });
  },
});

export const getAllBranches = createAsyncThunk(
  "branches/getAllBranches",
  async (userId) => {
    const response = await api.get(
      `https://liwapos.com/samba.mobil/Json/JsonSambaPatronCustomers?userid=${userId}`
    );
    return response.data;
  }
);

export default branchSlice.reducer;
export const { resetBranchesState } = branchSlice.actions;
