import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { formatToUrl } from "../utils";
import { axiosPrivate } from "../api";

const api = axiosPrivate();
const port = process.env.REACT_APP_BACKEND_PORT;

const initialState = {
  getProductTypes: {
    loading: false,
    success: false,
    productTypes: [],
    error: null,
  },
  getProducts: {
    loading: false,
    success: false,
    products: [],
    error: null,
  },
  updateProduct: {
    loading: false,
    success: false,
    error: false,
  },
  createProduct: {
    loading: false,
    success: false,
    error: false,
  },
};

const productsSlice = createSlice({
  name: "products",
  initialState: initialState,
  reducers: {
    resetGetProductsTypes: (state) => {
      state.getProductTypes = initialState.getProductTypes;
    },
    resetGetProducts: (state) => {
      state.getProducts = initialState.getProducts;
    },
    resetUpdateProduct: (state) => {
      state.updateProduct = initialState.updateProduct;
    },
    resetCreateProduct: (state) => {
      state.createProduct = initialState.createProduct;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.getProducts = {
          loading: true,
          success: false,
          products: [],
          error: null,
        };
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.getProducts = {
          loading: false,
          success: true,
          products: action.payload,
          error: null,
        };
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.getProducts = {
          loading: false,
          success: false,
          products: [],
          error: action.error.message,
        };
      })
      .addCase(updateProduct.pending, (state) => {
        state.updateProduct = {
          loading: true,
          success: false,
          error: null,
        };
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.updateProduct = {
          loading: false,
          success: true,
          error: null,
        };
        const editedPrices = action.payload.editedPrices;
        const products = state.getProducts.products.products;
        editedPrices.forEach((editedPrice) => {
          const product = products.find(
            (product) => product.name === editedPrice.productName
          );
          if (product) {
            editedPrice.prices.forEach((price) => {
              const portion = product.portions.find(
                (portion) => portion.name === editedPrice.portion
              );
              if (portion) {
                portion.price = price.price;
              }
              portion.prices.find(
                (priceTag) => priceTag.priceTag === price.priceTag
              ).price = price.price;
            });
          }
        });

        state.getProducts.products.products = products;
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.updateProduct = {
          loading: false,
          success: false,
          error: action.error.message,
        };
      })
      .addCase(createProduct.pending, (state) => {
        state.createProduct = {
          loading: true,
          success: false,
          error: null,
        };
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        state.createProduct = {
          loading: false,
          success: true,
          error: null,
        };
        state.getProducts.products.products.push({
          ...action.payload.product,
          // make the id a random number
          id: Math.floor(Math.random() * 1000),
        });
      })
      .addCase(createProduct.rejected, (state, action) => {
        state.createProduct = {
          loading: false,
          success: false,
          error: action.error.message,
        };
      });
  },
});

export const getProducts = createAsyncThunk(
  "products/getProducts",
  async ({ branchUrl }) => {
    const formattedUrl = formatToUrl(branchUrl);
    //const response = await api(`${formattedUrl}/get_products`);
    const response = await api.get(`${port}/prices/getProducts`, {
      params: {
        path: formattedUrl,
      },
    });
    return response.data;
  }
);

export const updateProduct = createAsyncThunk(
  "products/updateProduct",
  async ({ branchUrl, editedPrices }) => {
    const formattedUrl = formatToUrl(branchUrl);
    let errorCount = 0;
    try {
      const results = await Promise.all(
        editedPrices.map(async (entity) => {
          try {
            //const res = await api.post(
            //  `${formattedUrl}/update_product_price`,
            //  entity
            //);
            const res = await api.post(`${port}/prices/updateProduct`, {
              path: formattedUrl,
              entity,
            });

            return res.data;
          } catch (error) {
            errorCount++;
            if (errorCount >= 0.75 * formattedUrl.length) {
              throw new Error("Error updating product");
            }
            console.log(error);
            throw error;
          }
        })
      );

      return { data: results, editedPrices };
    } catch (error) {
      throw new Error("Error updating product");
    }
  }
);

export const createProduct = createAsyncThunk(
  "products/createProduct",
  async ({ branchUrl, product }) => {
    const formattedUrl = formatToUrl(branchUrl);
    //const response = await api.post(`${formattedUrl}/add_product`, product);
    const response = await api.post(`${port}/prices/createProduct`, {
      path: formattedUrl,
      product,
    });

    return { data: response.data, product };
  }
);

export default productsSlice.reducer;
export const { resetGetProducts, resetCreateProduct, resetUpdateProduct } =
  productsSlice.actions;
