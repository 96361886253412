export default function useAuth() {
  const auth = () => {
    const authItemString = localStorage.getItem("auth");
    const authItem = JSON.parse(authItemString);
    return authItem;
  };

  const setAuth = (authItem) => {
    localStorage.setItem("auth", JSON.stringify(authItem));
  };

  const clearAuth = () => {
    localStorage.removeItem("auth");
  };

  return {
    auth,
    setAuth,
    clearAuth,
  };
}
