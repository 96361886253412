const CompareSkeleton = () => {
  return (
    <div className="animate-pulse bg-[var(--components-color)] p-4 rounded-lg border-[.5px] border-solid border-[--gr-3] flex-1">
      <div className="border border-blue-300 border-solid animate-pulse bg-gray-50 flex flex-col rounded-[8px] shadow-lg min-w-[18rem] ">
        <div className="animate-pulse gap-4 p-4">
          {Array.from({ length: 2 }).map((_, index) => (
            <div
              key={index}
              className={`h-10 col-span-2 p-2 mb-2 border border-[--gr-3] border-solid rounded-md bg-[--btn-txt]`}
            />
          ))}
          <div className="grid grid-cols-1 sm:grid-cols-2">
            <div className="h-10 p-2 mb-2 border border-[--gr-3] border-solid rounded-md bg-[--btn-txt]" />
            <div className="h-10 p-2 mb-2 border border-[--gr-3] border-solid rounded-md bg-[--btn-txt]" />
          </div>
          <div className="h-10 px-10 py-2 bg-[--primary] text-[--btn-txt] rounded-md focus:outline-none focus:ring-2 focus:ring-[--gr-5] focus:ring-offset-2 focus:ring-offset-gray-100 hover:bg-[--primary-hover]" />
        </div>
      </div>
    </div>
  );
};

export default CompareSkeleton;
