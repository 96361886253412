import "./App.css";
import { Route, Routes } from "react-router-dom";
import Branches from "./pages/branches";
import Login from "./pages/login";
import BranchDetails from "./pages/branchDetails";
import RequireAuth from "./components/requireAuth";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import NotFound from "./components/notFound/NotFound";
import BranchDetailsNavBar from "./components/branchDetails/nav";
//import Footer from "./components/footer/footer";
//import Recaptcha from "./pages/recaptca";

function App() {
  const { darkMode } = useSelector((state) => state.theme);

  const navNotfound = () => {
    return <BranchDetailsNavBar />;
  };

  return (
    <div
      className={`flex flex-col justify-between overflow-hidden ${
        darkMode ? "bg-black" : "bg-white"
      }`}
    >
      <div
        className={`${
          darkMode ? "d-mode bg-black" : "l-mode bg-slate-100"
        } test min-h-[100dvh] flex flex-col justify-between`}
      >
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<RequireAuth />}>
            <Route path="/" element={<Branches />} />
            <Route path="branches" element={<Branches />} />
            <Route path="info/:id/*" element={<BranchDetails />} />
            <Route path="*" element={<NotFound nav={navNotfound()} />} />
          </Route>
        </Routes>
      </div>
      <div
        className={`${darkMode ? "d-mode bg-black" : "l-mode bg-white"} test`}
      >
        <Toaster />
      </div>
    </div>
  );
}

export default App;
