import Sidebar from "../components/common/sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import MyTable from "../components/common/table";
import { useDispatch, useSelector } from "react-redux";
import { getAllReports, getReportDetails } from "../redux/reports/reportsSlice";
import SidebarSkeleton from "../components/common/sidebarSkeleton";
import ErrorDisplay from "../components/common/error";
import TableSkeleton from "../components/common/tableSkeleton";
import {
  FormatCalendar,
  extractIp,
  formatDate,
  formatToDate,
  lastMonth,
  lastWeek,
  thisMonth,
  thisWeek,
  thisYear,
  today,
  yesterday,
} from "../redux/utils";
import CustomInput from "../components/common/customInput";
import { filterReportTable, isEmpty } from "../utils/filterReportTable";
import DateTimePicker from "../components/common/dateTimePicker";
import { useTranslation } from "react-i18next";
import { arrayToExcel } from "../utils/arrayToExel";
import { arrayToPdf } from "../utils/arrayToPdf";
//icons
import { PiFilePdf } from "react-icons/pi";
import { PiFileXls } from "react-icons/pi";
import {
  getTicketDetail,
  resetGetTicketDetail,
} from "../redux/tickets/ticketSlice";
import TicketPopUp from "../components/reportsPage/reportPopup";
import { customToast } from "../components/branches/branchCard";
import CustomSelect from "../components/common/customSelect";

const ReportDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const topRef = useRef(null);
  const scrollInTable = useRef(null);

  const branchIp = useParams().id;
  const id = useParams().reportId;
  const branchUrl = useParams().id;
  const [selectedRow, setSelectedRow] = useState(id);
  const reportsReducer = useSelector((state) => state.reports);
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [open, setOpen] = useState(false);
  const [ticketData, setTicketData] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [filteredTables, setFilteredTables] = useState(null);
  const [isTableEmpty, setIsTableEmpty] = useState(null);
  const [customDate, setCustomDate] = useState(null);

  const { branches } = useSelector((state) => state.branches);
  const currentBranch =
    branches.length > 0
      ? branches.find((branch) => extractIp(branch.ipAddress) === branchIp)
          ?.name
      : branchIp;

  const translations = {
    endD: t("exel.endD"),
    header: t("exel.header"),
    name: t("exel.name"),
    startD: t("exel.startD"),
    tableName: t("exel.tableName"),
    branchName: t("exel.branchName"),
  };

  const {
    loading: reportsLoading,
    success: reportsSuccess,
    reports,
    error: reportsError,
  } = reportsReducer.allReports;
  const {
    loading: dataLoading,
    success: dataSuccess,
    data,
    error: dataError,
  } = reportsReducer.reportDetails;

  const {
    loading: ticketLoading,
    success: ticketSucc,
    ticket,
  } = useSelector((state) => state.tickets.getTicketDetail);

  useEffect(() => {
    if (data) {
      setFilteredTables(filterReportTable(data, searchQuery));
    }
  }, [data, searchQuery]);

  useEffect(() => {
    if (filteredTables) {
      setIsTableEmpty(isEmpty(filteredTables));
    }
  }, [filteredTables]);

  //const filteredTables = filterReportTable(data, searchQuery);
  //const isTableEmpty = isEmpty(filteredTables);

  useEffect(() => {
    if (!selectedRow) {
      if (reportsSuccess && reports.length > 0) {
        setSelectedRow(reports[0]);
      }
    } else {
      dispatch(
        getReportDetails({
          branchUrl,
          reportId: selectedRow,
        })
      );
      setCustomDate(null);
    }
  }, [dispatch, selectedRow, branchUrl, reportsSuccess, reports]);

  useEffect(() => {
    dispatch(getAllReports({ branchUrl }));
  }, [dispatch, branchUrl]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const submitDateRange = (e, start_date, end_date) => {
    dispatch(
      getReportDetails({
        branchUrl,
        reportId: selectedRow,
        start_date: start_date
          ? start_date
          : startDate
          ? formatDate(startDate)
          : null,
        end_date: end_date ? end_date : endDate ? formatDate(endDate) : null,
      })
    );
    if (!start_date && !end_date) {
      setCustomDate(null);
    }
  };

  useEffect(() => {
    if (dataSuccess) {
      setStartDate(formatToDate(data.startDate));
      setEndDate(formatToDate(data.endDate));
    }
  }, [data, dataSuccess]);

  const search = (query) => {
    setSearchQuery(query);
  };

  // Ticket POPUP
  const showTickDetail = (ticket_id) => {
    dispatch(getTicketDetail({ branchUrl, ticket_id }));
    setOpen(true);
  };

  useEffect(() => {
    if (ticketSucc) {
      setTicketData(ticket);
      dispatch(resetGetTicketDetail());
    }
  }, [ticketSucc, dispatch, ticket]);

  useEffect(() => {
    !open && setTicketData(null);
  }, [open]);

  // REMOVE BODY SCROLLBAR IN POPUP
  useEffect(() => {
    if (open) {
      document.body.classList.add("body_overflow_hidden");
    } else {
      document.body.classList.remove("body_overflow_hidden");
    }

    return () => {
      document.body.classList.remove("body_overflow_hidden");
    };
  }, [open]);

  // ASSIGN LISTNER TO MANAGE SCROLLBAR ACCORDING TO WINDOWS SIZE
  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
    return () => {
      window.removeEventListener("resize", () =>
        setWindowWidth(window.innerWidth)
      );
    };
  }, []);

  // MANAGE SCROLLBAR ACCORDING TO WINDOWS SIZE
  useEffect(() => {
    if (windowWidth > 950) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
    //console.log(windowWidth);
  }, [windowWidth]);

  // SCROLL TO TOP (BODY/TABLE)
  useEffect(() => {
    if (topRef.current) {
      const topElement = topRef.current;
      const offset =
        10 * parseFloat(getComputedStyle(document.documentElement).fontSize); // 10rem offset

      // Scroll to the top element's position
      window.scrollTo({
        top: topElement.getBoundingClientRect().top + window.scrollY - offset,
        behavior: "smooth",
      });
    }

    if (scrollInTable.current) {
      if (filteredTables) {
        scrollInTable.current.scrollTop = 0;
      }
    }
  }, [selectedRow, filteredTables]);

  ///************* BRANCH STATUS AND LICENCE CONTROL *****************///
  //const branchIp = useParams().id;
  const navigate = useNavigate();
  const { branches: allBranches } = useSelector((state) => state.branches);
  const allSummaryData = useSelector((state) => state.reports.reportSummary);
  useEffect(() => {
    const thisBranch = [];
    if (allBranches.length > 0) {
      const pushBranch = allBranches.filter(
        (branch) => extractIp(branch.ipAddress) === String(branchIp)
      );
      thisBranch.push(pushBranch[0]);
    }

    if (allSummaryData) {
      if (thisBranch.length > 0 && Object.keys(allSummaryData).length > 0) {
        const thisBranchIp = thisBranch[0].ipAddress;
        if (!allSummaryData?.[thisBranchIp].loading) {
          if (
            !allSummaryData?.[thisBranchIp].success ||
            thisBranch[0]?.status !== 1
          ) {
            //console.log(false);
            navigate("/");
            customToast(`${t("branches.inActive", { branch: "" })}`);
            //console.log(allSummaryData?.[thisBranchIp]);
            //console.log(thisBranch[0]?.status);
          } else {
            console.log(true);
          }
        }
      }
    }
  }, [
    allBranches,
    dispatch,
    allSummaryData,
    branchIp,
    selectedRow,
    navigate,
    t,
  ]);
  ///************* BRANCH STATUS AND LICENCE CONTROL *****************///

  const handleOptionChange = (selectedOption) => {
    setCustomDate(selectedOption);
    if (selectedOption && callbacks[selectedOption.value]) {
      const callbackResult = callbacks[selectedOption.value];
      setStartDate(callbackResult?.start);
      setEndDate(callbackResult?.end);
      submitDateRange("", callbackResult?.start, callbackResult?.end);
    } else if (selectedOption.value === "Default") {
      dispatch(
        getReportDetails({
          branchUrl,
          reportId: selectedRow,
        })
      );
    }
  };
  const callbacks = {
    Today: today(),
    Yesterday: yesterday(),
    ThisWeek: thisWeek(),
    LastWeek: lastWeek(),
    ThisMonth: thisMonth(),
    LastMonth: lastMonth(),
    ThisYear: thisYear(),
  };

  return (
    <section className="relative full_height-footer" ref={topRef}>
      {ticketData && (
        <TicketPopUp
          open={open}
          setOpen={setOpen}
          ticket={ticketData}
          loading={ticketLoading}
        />
      )}

      <div className="flex 950m:gap-2 full_height-footer">
        <aside>
          {reportsSuccess ? (
            <Sidebar
              title={`${reports.length}${" "}${t("sidebar.reports")}`}
              data={reports}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
            />
          ) : reportsError ? (
            <ErrorDisplay
              error={reportsError}
              onRetry={() => dispatch(getAllReports({ branchUrl }))}
            />
          ) : reportsLoading ? (
            <SidebarSkeleton />
          ) : null}
        </aside>

        <main className="flex-1 overflow-x-scroll sm:overflow-x-hidden">
          <div className="flex justify-center">
            {dataSuccess ? (
              <div
                className="my-2 overflow-x-auto 950m:max-h-[80dvh] 950m:overflow-y-auto"
                ref={scrollInTable}
              >
                <div className="w-full lg:w-[75%] lg:min-w-max">
                  <div className="grid grid-cols-1 sm:grid-cols-2 mb-2 sm:mb-0">
                    <div>
                      <h1 className="block text-base text-[--tx-1] font-bold Poppins">
                        {data.name.replace(
                          /^PTR-|[^a-zA-Z\sıIğĞüÜşŞiİöÖçÇ]/g,
                          ""
                        )}
                      </h1>
                      {searchQuery === "" && (
                        <div>
                          <span className="text-[var(--tx-3)] text-sm Poppins">
                            {FormatCalendar(data.startDate, ".", true, false)} -{" "}
                            {FormatCalendar(data.endDate, ".", true, false)}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="mx-2">
                      <CustomInput
                        type="text"
                        placeholder={t("nav.search")}
                        value={searchQuery}
                        onChange={(e) => search(e.target.value)}
                        className="w-full left-0 mb-2 p-3 rounded text-[--gr-4] text-sm border border-[--gr-2] border-solid cursor-text"
                      />
                    </div>
                  </div>
                  {searchQuery === "" && (
                    <div className="flex flex-wrap p-3 pl-0 mb-2 bg-[--bg-1] rounded-[8px] align-middle">
                      <div className="w-full mb-1 flex justify-end md:justify-start">
                        <CustomSelect
                          value={
                            customDate
                              ? customDate
                              : { label: t("options.customDate") }
                          }
                          isSearchable={false}
                          options={[
                            {
                              value: "Default",
                              label: t("options.customDate"),
                            },
                            { value: "Today", label: t("options.today") },
                            {
                              value: "Yesterday",
                              label: t("options.yesterday"),
                            },
                            {
                              value: "ThisWeek",
                              label: t("options.thisWeek"),
                            },
                            {
                              value: "LastWeek",
                              label: t("options.lastWeek"),
                            },
                            {
                              value: "ThisMonth",
                              label: t("options.thisMonth"),
                            },
                            {
                              value: "LastMonth",
                              label: t("options.lastMonth"),
                            },
                            { value: "ThisYear", label: t("options.thisYear") },
                          ]}
                          onChange={handleOptionChange}
                          className="w-full max-w-40 border-0 border-[--gr-3] border-solid rounded-md bg-[--btn-txt] text-sm"
                          isDisabled={dataLoading}
                        />
                      </div>
                      <div className="grid grid-cols-1 sm:grid-cols-2 items-center w-full sm:w-max sm:min-w-[36rem] lg:min-w-fit gap-2 sm:gap-4 max-h-24 sm:max-h-8 lg:mr-3 mb-3">
                        <DateTimePicker
                          value={startDate ? startDate : ""}
                          onChange={handleStartDateChange}
                          placeholder={t("compare.startD")}
                        />
                        <DateTimePicker
                          value={endDate ? endDate : ""}
                          onChange={handleEndDateChange}
                          placeholder={t("compare.endD")}
                        />
                      </div>
                      <div className="w-full flex flex-1 min-w-max gap-4">
                        <button
                          className="w-full min-w-fit bg-[var(--primary)] hover:bg-[var(--primary-hover)] text-sm p-2 text-slate-300 rounded"
                          onClick={submitDateRange}
                        >
                          {t("reports.create")}
                        </button>
                        <div className="flex gap-2">
                          <button
                            className="w-full bg-green-600 text-3xl p-2 text-[var(--btn-txt)] rounded"
                            onClick={() =>
                              arrayToPdf(data, translations, currentBranch)
                            }
                          >
                            <PiFilePdf />
                          </button>
                          <button
                            className="w-full bg-green-600 text-3xl p-2 text-[var(--btn-txt)] rounded"
                            onClick={() =>
                              arrayToExcel(data, translations, currentBranch)
                            }
                          >
                            <PiFileXls />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {filteredTables && !isTableEmpty ? (
                    filteredTables.map((table, index) => {
                      return (
                        (table.rows.length > 0) | (searchQuery === "") && (
                          <div className="" key={index}>
                            <MyTable
                              table={table}
                              showColumns={true}
                              showTitle={true}
                              setOpen={setOpen}
                              setTicketData={setTicketData}
                              showTickDetail={showTickDetail}
                            />
                          </div>
                        )
                      );
                    })
                  ) : (
                    <ErrorDisplay error="No Results Found" />
                  )}
                </div>
              </div>
            ) : dataLoading || reportsLoading ? (
              <>
                <div className="h-10 bg-[--gr-0] animate-pulse w-2/3 mb-1"></div>
                <div className="h-8 bg-[--gr-0] animate-pulse w-1/2"></div>
                <div className="my-2">
                  <TableSkeleton />
                  <TableSkeleton />
                </div>
              </>
            ) : dataError ? (
              <ErrorDisplay
                error={dataError}
                onRetry={() => submitDateRange("", startDate, endDate)}
              />
            ) : null}
          </div>
        </main>
      </div>
    </section>
  );
};

export default ReportDetails;
