import { useEffect } from "react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import LogoutButton from "../common/logoutBtn";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import NavigationIndicator from "./navIndicator";
import "./nav.css";
import { useParams } from "react-router-dom";
import LanguageSelector from "../lang/langselector";
import { useTranslation } from "react-i18next";
import ThemeToggle from "../common/themeToggle";
import RefreshAllSummaries from "../common/refreshAll";
import CustomInput from "../common/customInput";
import { customMaxInput } from "../../redux/utils";
import { IoSearch } from "react-icons/io5";
//import { MdOutlineRemoveRedEye } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { Inceleme } from "../../data/inceleme.js";

const BranchDetailsNavBar = ({
  showTickDetail,
  searchVal,
  setSearchVal,
  searchOpen,
  setSearchOpen,
}) => {
  const { t } = useTranslation();

  const branchIp = useParams().id;
  const location = useLocation();
  const url =
    location.pathname.split("/")[location.pathname.split("/").length - 1];
  const [open, setOpen] = useState(false);

  const navigation = [
    {
      name: t("nav.reports"),
      to: url !== branchIp ? `reports` : "",
      current: url === "reports" || url === branchIp,
    },
    {
      name: t("nav.compare"),
      to: `compare`,
      current: url === "compare",
    },
    {
      name: t("nav.tickets"),
      to: `activeAdisyon`,
      current: url === "activeAdisyon",
    },
    {
      name: t("nav.price_list"),
      to: `priceList`,
      current: url === "priceList",
    },
    {
      name: t("nav.user"),
      to: `userActions`,
      current: url === "userActions",
    },
    //{
    //  name: t("nav.profile"),
    //  to: `Profil`,
    //  current: url === "Profil",
    //},
    {
      name: t("nav.actions"),
      to: `actionsPage`,
      current: url === "actionsPage",
    },
  ];

  useEffect(() => {
    if (!searchOpen) {
      setSearchVal("");
    }
  }, [searchOpen]);

  useEffect(() => {
    if (searchOpen) {
      document.body.classList.add("body_overflow_hidden");
    } else {
      document.body.classList.remove("body_overflow_hidden");
    }

    return () => {
      document.body.classList.remove("body_overflow_hidden");
    };
  }, [searchOpen]);

  return (
    <>
      <section className="w-full overflow-visible bg-[--nav-bg-1]">
        <nav>
          <div className="px-2 sm:px-0">
            <div className="relative flex h-16 items-center justify-between">
              <div className="relative inset-y-0 left-0 flex items-center lg:hidden">
                <button
                  className="relative inline-flex items-center justify-center rounded-md p-2 text-[--gr-5] hover:bg-[--bg-2] hover:text-white"
                  onClick={() => setOpen(!open)}
                >
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </button>
              </div>
              <div className="flex w-full items-center justify-between sm:items-stretch">
                <div className="flex flex-shrink-0 items-center text-[--tx-5]">
                  <Link
                    to="/"
                    className="text-2xl font-semibold font-[conthrax]"
                  >
                    Liwa
                    <span className="font-semibold text-[--tx-6] font-[conthrax]">
                      Soft
                    </span>
                  </Link>
                </div>
                <div className="hidden sm:ml-6 lg:block">
                  <div className="flex items-center gap-1">
                    {/* SEARCH SECTION */}
                    <SearchTicket
                      showTickDetail={showTickDetail}
                      searchVal={searchVal}
                      setSearchVal={setSearchVal}
                      searchOpen={searchOpen}
                      setSearchOpen={setSearchOpen}
                      placeholder={t("ticketDetails.enter")}
                    />

                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.to}
                        className={`
                            ${
                              item.current
                                ? "bg-[--gr-4] text-white rounded"
                                : "text-[--gr-7] hover:bg-[--gr-4] hover:text-white "
                            } 
                            "rounded-md py-2 px-1 text-sm font-medium"
                          `}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                    <Link>
                      <RefreshAllSummaries />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="flex items-center pr-2 sm:ml-1 sm:pr-0">
                <ThemeToggle />
                <LogoutButton className="hidden lg:block cursor-pointer" />
                <SearchTicket
                  showTickDetail={showTickDetail}
                  searchVal={searchVal}
                  setSearchVal={setSearchVal}
                  searchOpen={searchOpen}
                  setSearchOpen={setSearchOpen}
                  placeholder={t("ticketDetails.enter")}
                  className="block lg:hidden"
                />
                <LanguageSelector className="hidden lg:block" />
              </div>
            </div>
          </div>

          {open && (
            <div
              className="lg:hidden absolute z-50 bg-[--nav-bg-1]"
              onClick={() => setOpen(!open)}
            >
              <div className="px-2 pb-3 pt-2 rounded-sm">
                <ul>
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <Link
                        key={item.name}
                        to={item.to}
                        className={`block mb-[1px] px-3 py-2 rounded-sm ${
                          item.current
                            ? "bg-[--gr-4] text-white rounded"
                            : "text-[--gr-7] hover:bg-[--gr-4] hover:text-white"
                        }
                        `}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                  <li>
                    <LogoutButton />
                  </li>
                  <li>
                    <LanguageSelector />
                  </li>
                </ul>
              </div>
            </div>
          )}
        </nav>
      </section>
      <NavigationIndicator />
    </>
  );
};

const SearchTicket = ({
  showTickDetail,
  placeholder,
  searchVal,
  setSearchVal,
  searchOpen,
  setSearchOpen,
  className,
}) => {
  const handleKeyDown = (e) => {
    //e.preventDefault();
    if (e.key === "Enter") {
      if (e.target.value) showTickDetail();
    } else setSearchVal(customMaxInput(e));
  };
  return (
    <>
      <div className={`relativee  ${className}`}>
        <div
          className="cursor-pointer w-8 h-8 flex items-center justify-center overflow-hidden rounded-full"
          onClick={() => setSearchOpen(!searchOpen)}
        >
          <div className="w-full h-full">
            <Inceleme width={"100%"} height={"100%"} />
          </div>
          {/* <MdOutlineRemoveRedEye className="text-4xl p-1 text-white bg-[--primary] hover:bg-[--primary-hover] rounded-lg cursor-pointer" /> */}
        </div>

        <div
          className={`fixed inset-0 shadow-md flex justify-center pt-72 transition-colors px-[2%] z-[9999] ${className} ${
            searchOpen ? "visible bg-black/20" : "hidden"
          }`}
        >
          <div
            className={`w-full max-w-[35rem] ${
              searchOpen ? "scale-100 opacity-100" : "scale-125 opacity-0"
            }`}
          >
            {searchOpen && (
              <SearchPopUp
                setSearchOpen={setSearchOpen}
                setSearchVal={setSearchVal}
                handleKeyDown={handleKeyDown}
                placeholder={placeholder}
                showTickDetail={showTickDetail}
                searchVal={searchVal}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const SearchPopUp = ({
  setSearchOpen,
  setSearchVal,
  showTickDetail,
  handleKeyDown,
  placeholder,
  searchVal,
}) => {
  return (
    <div
      className={`w-full h-[10rem] bg-[--nav-bg-1] flex flex-col items-center rounded-lg`}
    >
      <div className="w-full items-end p-2">
        <button className="w-full flex justify-end">
          <div
            className="text-3xl p-1 text-white bg-[--primary] hover:bg-[--primary-hover] rounded-full"
            onClick={() => setSearchOpen(false)}
          >
            <IoClose />
          </div>
        </button>
      </div>
      <div className="w-full max-w-[20rem]">
        <div className="pl-8 pt-2 pr-2 flex">
          <CustomInput
            placeholder={placeholder}
            type="number"
            maxLength={9}
            onChange={(e) => setSearchVal(customMaxInput(e))}
            onKeyDown={(e) => handleKeyDown(e)}
            value={searchVal}
            className={"border-r-0 rounded-r-none"}
            autoFocus={true}
          />
          <span
            className="text-xl text-white bg-[--primary] p-2 flex items-center rounded-r-lg border-0 border-l border-white border-solid cursor-pointer"
            onClick={() => showTickDetail()}
          >
            <IoSearch />
          </span>
        </div>
      </div>
    </div>
  );
};
export default BranchDetailsNavBar;
