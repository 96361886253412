import ComparePage from "./comparePage";
import { Routes, Route, useParams } from "react-router-dom";
import BranchDetailsNavBar from "../components/branchDetails/nav";
import ReportDetails from "./reportDetails";
import UserActions from "./userActions";
import NotFound from "../components/notFound/NotFound";
import FiyatListesi from "./fiyatListesi";
import AutomationCommand from "./automationCommand";
import WorkPeriod from "./workPeriod";
import RestartMessagingServer from "./restartMesServer";
import TicketDetails from "./ticketDetails";
import ActionsPage from "./actionsPage";
import TicketDetailsComp from "../components/common/ticketPopup";
import { useState } from "react";
import { getTicketDetail } from "../redux/tickets/ticketSlice";
import { useDispatch } from "react-redux";

const BranchDetails = () => {
  const dispatch = useDispatch();
  const branchUrl = useParams().id;
  const [open, setOpen] = useState(false);
  const [searchVal, setSearchVal] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  const showTickDetail = () => {
    dispatch(getTicketDetail({ branchUrl, ticket_id: Number(searchVal) }));
    setOpen(true);
    setSearchOpen(false);
  };
  return (
    <>
      <BranchDetailsNavBar
        showTickDetail={showTickDetail}
        setSearchVal={setSearchVal}
        searchVal={searchVal}
        searchOpen={searchOpen}
        setSearchOpen={setSearchOpen}
      />
      {open && (
        <TicketDetailsComp
          open={open}
          setOpen={setOpen}
          searchOpen={searchOpen}
        />
      )}

      <Routes>
        <Route path="/" element={<ReportDetails />} />
        <Route path="reports/:reportId/*" element={<ReportDetails />} />
        <Route path="reports" element={<ReportDetails />} />
        <Route path="compare" element={<ComparePage />} />
        <Route path="activeAdisyon" element={<TicketDetails />} />
        <Route path="priceList" element={<FiyatListesi />} />
        <Route path="userActions" element={<UserActions />} />
        <Route path="workPeriod" element={<WorkPeriod />} />
        <Route path="automationCommand" element={<AutomationCommand />} />
        <Route
          path="restartMessagingServer"
          element={<RestartMessagingServer />}
        />
        <Route path="actionsPage" element={<ActionsPage />} />
        {/* 404 Route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default BranchDetails;
