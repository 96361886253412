import Sidebar from "../components/common/sidebar";
import React, {
  useEffect,
  useState,
  useRef,
  createRef,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import SidebarSkeleton from "../components/common/sidebarSkeleton";
import ErrorDisplay from "../components/common/error";
import TableSkeleton from "../components/common/tableSkeleton";
import CustomInput from "../components/common/customInput";
import {
  getProducts,
  resetGetProducts,
  updateProduct,
  resetUpdateProduct,
} from "../redux/priceList/productsSlice";
import AddModal from "../components/addProducts/addModal";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { customMaxInput, extractIp } from "../redux/utils";
import { customToast } from "../components/branches/branchCard";

const ProductPrice = () => {
  const { t } = useTranslation();

  const branchUrl = useParams().id;
  const dispatch = useDispatch();
  const inputRefs = useRef({});
  const topRef = useRef(null);
  const scrollInTable = useRef(null);

  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = useState(false);
  const productsReducer = useSelector((state) => state.products);
  const [prices, setPrices] = useState([{ label: "Normal", value: "Normal" }]);
  const [price, setPrice] = useState(prices[0]);
  const [editedPrices, setEditedPrices] = useState({});
  const [inData, setInData] = useState(null);
  const [valData, setValData] = useState(null);
  const [totalProduct, setTotalProduct] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [sideBarData, setSideBarData] = useState(null);

  const {
    loading: productsLoading,
    success: productsSuccess,
    products,
    error: productsError,
  } = productsReducer.getProducts;

  const {
    loading: editLoading,
    success: editSuccess,
    error: editError,
  } = productsReducer.updateProduct;

  const handlePriceChange = (selectedPrice) => {
    setPrice(selectedPrice);
  };

  const getGroupCodes = (data) => {
    const result = [];
    data &&
      data.forEach((product) => {
        const groupCode = product.groupCode
          ? product.groupCode
          : "Uncategorized";
        if (!result.includes(groupCode)) {
          result.push(groupCode);
        }
      });
    //console.log(result);
    return result.sort((a, b) =>
      a.localeCompare(b, "tr", { sensitivity: "base" })
    );
  };

  const formatGroupCodeToCategories = (data) => {
    if (!data) {
      return [];
    }
    const groupCodes = getGroupCodes(data);

    return groupCodes.map((code) => {
      return { label: code, value: code?.toLowerCase() };
    });
  };

  const formatMenus = (data) => {
    return (
      data &&
      data.map((menu) => {
        return { label: menu, value: menu?.toLowerCase() };
      })
    );
  };

  const formatProductData = (data) => {
    const result = {};
    data &&
      data.forEach((product) => {
        const groupCode = product.groupCode
          ? product.groupCode
          : "Uncategorized";
        if (result[groupCode] === undefined) {
          result[groupCode] = [];
        }
        result[groupCode].push(product);
      });
    return result;
  };

  const formatTotalProduct = (data) => {
    const result = {};
    data &&
      data.forEach((product) => {
        const groupCode = product.groupCode
          ? product.groupCode
          : "Uncategorized";
        if (result[groupCode] === undefined) {
          result[groupCode] = 0;
        }
        result[groupCode] = result[groupCode] + 1;
      });
    return result;
  };

  const handleKeyPress = (
    e,
    index,
    portionIndex,
    totalItems,
    totalPortions
  ) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const isLastPortion = portionIndex === totalPortions - 1;
      const isLastItem = index === totalItems - 1;
      const nextInput =
        inputRefs.current[isLastPortion ? (isLastItem ? 0 : index + 1) : index][
          isLastPortion ? 0 : portionIndex + 1
        ];
      if (nextInput) {
        nextInput.current.focus();
      }
    }
  };

  const handleSearch = (e) => {
    const val = e.target.value.toLowerCase();
    const selectedData = formatProductData(products.products);
    const outData = selectedData[selectedRow].filter((data) =>
      data.name.toLowerCase().includes(val)
    );
    setInData(outData);
    setValData(val);
  };

  useEffect(() => {
    dispatch(getProducts({ branchUrl }));
    setSelectedRow(null);
  }, [branchUrl, dispatch]);

  useEffect(() => {
    if (selectedRow && productsSuccess) {
      const selectedData = formatProductData(products.products);
      setInData(selectedData[selectedRow]);
      setTotalProduct(formatTotalProduct(products.products));
    }

    if (productsSuccess) {
      if (!selectedRow) {
        setSelectedRow(getGroupCodes(products.products)[0]);
      }
      setSideBarData(getGroupCodes(products.products));
    }
  }, [productsSuccess, selectedRow, products.products]);

  useEffect(() => {
    if (productsSuccess) {
      const arr = [
        {
          label: "Normal",
          value: "Normal",
        },
      ];
      products.priceTags.forEach((price) => {
        arr.push({
          label: typeof price === "object" ? price.name : price,
          value: typeof price === "object" ? price.priceTag : price,
        });
      });
      setPrices(arr);
      //console.log(products);
    }
  }, [productsSuccess, selectedRow, products.products, products.priceTags]);

  const extractPrice = (prices, priceTag) => {
    if (!prices) {
      return "";
    }
    for (let i = 0; i < prices.length; i++) {
      if (priceTag === "Normal" && prices[i].priceTag === null) {
        return prices[i].price;
      } else if (prices[i].priceTag === priceTag) {
        return prices[i].price;
      }
    }
    return "";
  };

  const getPortions = (item) => {
    if (!item || !item.portions) {
      return [];
    }
    return item.portions.map((portion) => {
      return {
        id: portion.id,
        label: portion.name,
        value: portion.name,
        price: portion.prices
          ? extractPrice(portion.prices, price.value)
          : portion.price,
      };
    });
  };

  const getTotalItems = useCallback((data) => {
    let total = 0;
    data.map((item) => {
      total += item.portions.length;
      return total;
    });
    return total;
  }, []);

  const prepareData = useCallback(() => {
    const result = [];
    for (const key in editedPrices) {
      const item = editedPrices[key];
      const portionKeys = [];
      for (const key in item) {
        if (key !== "name" && key !== "groupCode" && key !== "oldPrice") {
          portionKeys.push(key);
        }
      }
      portionKeys.forEach((portionKey) => {
        const portion = item[portionKey];
        const priceKeys = [];
        for (const key in portion) {
          if (key !== "name") {
            priceKeys.push(key);
          }
        }
        result.push({
          productName: item.name,
          portion: portion.name.label,
          prices: priceKeys.map((priceKey) => {
            return {
              priceTag: priceKey === "Normal" ? null : priceKey,
              name: prices.filter((price) => price.value === priceKey)[0]
                ?.label,
              price: parseFloat(portion[priceKey]),
            };
          }),
        });
      });
    }

    return result;
  }, [editedPrices]);

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log(prepareData());
    //return;
    dispatch(updateProduct({ branchUrl, editedPrices: prepareData() }));
  };

  const resetStates = useCallback(() => {
    setEditedPrices({});
    setOpen(false);
  }, []);

  useEffect(() => {
    if (editLoading) {
      toast.dismiss();
      toast.loading(t("nav.updatePriceP"), { position: "top-right" });
    } else if (editSuccess) {
      toast.dismiss();
      toast.success(t("nav.updatePriceM"), { position: "top-right" });
      resetStates();
      dispatch(resetUpdateProduct());
    } else if (editError) {
      toast.dismiss();
      toast.error(editError, { position: "top-right" });
    }
    //toast.dismiss();
  }, [
    editLoading,
    editSuccess,
    editError,
    dispatch,
    branchUrl,
    resetStates,
    t,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetUpdateProduct());
      dispatch(resetGetProducts());
    };
  }, [dispatch]);

  // SCROLL TO TOP (BODY/TABLE)
  useEffect(() => {
    if (topRef.current) {
      const topElement = topRef.current;
      const offset =
        20 * parseFloat(getComputedStyle(document.documentElement).fontSize); // 10rem offset

      // Scroll to the top element's position
      window.scrollTo({
        top: topElement.getBoundingClientRect().top + window.scrollY - offset,
        behavior: "smooth",
      });
    }
    if (scrollInTable.current) {
      if (productsSuccess) {
        scrollInTable.current.scrollTop = 0;
      }
    }
  }, [selectedRow, productsSuccess]);

  /// WINDOWS WIDTH ILE ILGILI
  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));

    return () => {
      window.removeEventListener("resize", () =>
        setWindowWidth(window.innerWidth)
      );
    };
  }, []);

  // MANAGE SCROLLBAR ACCORDING TO WINDOWS SIZE
  useEffect(() => {
    if (windowWidth > 950) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
    //console.log(windowWidth);
  }, [windowWidth]);

  const sum = (obj) => {
    if (obj) {
      return Object.values(obj).reduce((a, b) => a + b, 0);
    }
  };

  const handleSearchFromSide = (e) => {
    const val = e.target.value.toLowerCase();
    const selectedData = formatProductData(products.products);
    const objectKeys = Object.keys(selectedData);

    const outPut = [];
    objectKeys.map((key) => {
      const outData = selectedData[key].filter((data) =>
        data.name.toLowerCase().includes(val)
      );
      return outData.length > 0 && outPut.push(key);
    });
    setSideBarData(outPut);
    console.log(outPut);
  };

  ///************* BRANCH STATUS AND LICENCE CONTROL *****************///
  const branchIp = useParams().id;
  const navigate = useNavigate();
  const { branches: allBranches } = useSelector((state) => state.branches);
  const allSummaryData = useSelector((state) => state.reports.reportSummary);
  useEffect(() => {
    const thisBranch = [];
    if (allBranches.length > 0) {
      const pushBranch = allBranches.filter(
        (branch) => extractIp(branch.ipAddress) === String(branchIp)
      );
      thisBranch.push(pushBranch[0]);
    }

    if (!allSummaryData) return;
    if (thisBranch.length > 0 && Object.keys(allSummaryData).length > 0) {
      const thisBranchIp = thisBranch[0].ipAddress;
      if (!allSummaryData?.[thisBranchIp].loading) {
        if (
          !allSummaryData?.[thisBranchIp].success ||
          thisBranch[0]?.status !== 1
        ) {
          console.log(false);
          navigate("/");
          customToast(`${t("branches.inActive", { branch: "" })}`);
          //console.log(allSummaryData?.[thisBranchIp]);
          //console.log(thisBranch[0]?.status);
        } else {
          console.log(true);
        }
      }
    }
  }, [allBranches, allSummaryData, branchIp, selectedRow, navigate, t]);
  ///************* BRANCH STATUS AND LICENCE CONTROL *****************///
  return (
    <section className="relative pb-3" ref={topRef}>
      {productsError ? (
        <ErrorDisplay
          error={productsError}
          onRetry={() => dispatch(getProducts({ branchUrl }))}
        />
      ) : (
        <div className="flex 950m:gap-2">
          <aside>
            {productsSuccess && sideBarData ? (
              <Sidebar
                title={`${getGroupCodes(products.products).length}
                ${" "}
                ${t("sidebar.cat")}
                ${" "}
                ${sum(totalProduct)}
                ${t("sidebar.prods")}
                `}
                data={sideBarData}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                totalProduct={totalProduct}
                productSearch={handleSearchFromSide}
              />
            ) : productsLoading ? (
              <SidebarSkeleton />
            ) : null}
          </aside>

          <main className="flex-1 overflow-x-auto">
            <div>
              {productsLoading ? (
                <TableSkeleton />
              ) : productsSuccess ? (
                <>
                  <div
                    className="flex flex-col 950m:max-h-[80dvh] 950m:overflow-y-auto pb-8"
                    ref={scrollInTable}
                  >
                    <div className="max-w-[44rem] min-w-[auto] md:self-center md:min-w-[44rem]">
                      {/* PRICE TAGS */}
                      <div className="flex flex-wrap items-center justify-between">
                        <div className="py-3 flex items-center">
                          <div className="hidden sm:block text-[--tx-1] mr-2">
                            {t("nav.pTags")}:
                          </div>
                          <div className="flex gap-2">
                            {prices.map((option, index) => (
                              <button
                                key={`${option.value}-${index}`}
                                className={`px-4 py-1 rounded-lg text-[--btn-txt] ${
                                  price.value === option.value
                                    ? "bg-green-600 opacity-100"
                                    : "bg-[--primary] opacity-50"
                                }`}
                                onClick={() => handlePriceChange(option)}
                              >
                                {option.value}
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>

                      {/* SEARCH AND BUTTON */}
                      <div className="flex items-end justify-end mb-2 gap-2">
                        <div className="max-w-[36rem] w-full">
                          <CustomInput
                            className={"mb-0"}
                            placeholder={t("nav.search")}
                            value={valData}
                            onChange={handleSearch}
                          />
                        </div>
                        <button
                          type="button"
                          onClick={() => setOpen(true)}
                          className="text-white bg-[--primary] hover:bg-[--primary-hover] font-medium rounded-lg text-sm py-2.5 flex items-center justify-center min-w-32"
                        >
                          <div className="flex">
                            <svg
                              className="w-5 h-5"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 18 21"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            <p>{t("priceList.add")}</p>
                          </div>
                        </button>
                      </div>
                      {/* TABLE */}
                      <div className="border border-solid border-[--gr-3] p-2 rounded-md overflow-x-auto relative">
                        <form onSubmit={handleSubmit}>
                          <table className="w-full min-w-max sm:min-w-[650px] text-sm text-left rtl:text-right text-[--gr-5]">
                            <thead>
                              <tr className="bg-[--gr-1] border-b">
                                {[
                                  t("priceList.name"),
                                  t("priceList.porsiyon"),
                                  t("priceList.price"),
                                  t("priceList.editP"),
                                ].map((item, headerIndex) => (
                                  <th
                                    scope="col"
                                    key={`header-${headerIndex}`}
                                    className={`font-bold p-2 sm:px-6 whitespace-nowrap ${
                                      headerIndex === 3 &&
                                      "text-end w-16 sm:w-auto"
                                    }`}
                                  >
                                    {item}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {inData &&
                                formatProductData(products.products)[
                                  selectedRow
                                ] &&
                                inData.map((item, index) => {
                                  const totalItems = getTotalItems(
                                    formatProductData(products.products)[
                                      selectedRow
                                    ]
                                  );

                                  inputRefs.current[index] = [];

                                  const portions = getPortions(item);
                                  return (
                                    <React.Fragment key={item.id}>
                                      <tr
                                        key={`${item.id}-${index}`}
                                        className={`${
                                          index % 2 === 0
                                            ? "bg-[--btn-txt]"
                                            : "bg-[--gr-0]"
                                        }`}
                                      >
                                        <th
                                          scope="row"
                                          rowSpan={portions.length + 1}
                                          key={`${item.id}-${index}-1`}
                                          className="px-2 sm:px-6 py-2 font-medium text-[--gr-9] whitespace-nowrap"
                                        >
                                          {item.name}
                                        </th>
                                      </tr>
                                      {portions.map((portion, portionIndex) => {
                                        inputRefs.current[index][portionIndex] =
                                          createRef();

                                        return (
                                          <tr
                                            key={`${index}-${portionIndex}`}
                                            className={`${
                                              index % 2 === 0
                                                ? "bg-[--btn-txt]"
                                                : "bg-[--gr-0]"
                                            }`}
                                          >
                                            <th
                                              scope="row"
                                              key={`${index}-${portionIndex}-1`}
                                              className="px-2 sm:px-6 py-2 font-medium text-[--gr-9] whitespace-nowrap"
                                            >
                                              {portion.label || "Normal"}
                                            </th>
                                            <th
                                              scope="row"
                                              key={`${index}-${portionIndex}-2`}
                                              className="px-2 sm:px-6 py-2 font-medium text-[--gr-9] whitespace-nowrap"
                                            >
                                              {portion.price || ""}
                                            </th>
                                            <th
                                              scope="row"
                                              key={`${index}-${portionIndex}-3`}
                                              className="px-2 sm:px-6 py-2 font-medium text-[--gr-9] whitespace-nowrap text-end"
                                            >
                                              <CustomInput
                                                className={"max-w-32"}
                                                maxLength={9}
                                                type="number"
                                                reference={
                                                  inputRefs.current[index][
                                                    portionIndex
                                                  ]
                                                }
                                                value={
                                                  editedPrices[item.id]?.[
                                                    portion.id
                                                  ]?.[price.value] || ""
                                                }
                                                onChange={(e) => {
                                                  setEditedPrices(
                                                    (prevPrices) => {
                                                      const newPrices = {
                                                        ...prevPrices,
                                                      };
                                                      const portionKey =
                                                        portion.id;
                                                      newPrices[item.id] = {
                                                        ...(prevPrices[
                                                          item.id
                                                        ] || {
                                                          name: item.name,
                                                          groupCode:
                                                            item.groupCode,
                                                          oldPrice:
                                                            portion.price ||
                                                            null,
                                                        }),
                                                        [portionKey]: {
                                                          ...((prevPrices[
                                                            item.id
                                                          ] &&
                                                            prevPrices[item.id][
                                                              portionKey
                                                            ]) || {
                                                            name:
                                                              portion ||
                                                              "Normal",
                                                          }),
                                                          [price.value]:
                                                            customMaxInput(e),
                                                          //e.target.value,
                                                        },
                                                      };
                                                      return newPrices;
                                                    }
                                                  );
                                                }}
                                                onKeyDown={(e) =>
                                                  handleKeyPress(
                                                    e,
                                                    index,
                                                    portionIndex,
                                                    totalItems,
                                                    portions.length
                                                  )
                                                }
                                              />
                                            </th>
                                          </tr>
                                        );
                                      })}
                                    </React.Fragment>
                                  );
                                })}
                            </tbody>
                          </table>
                          <div className="p-2 flex items-center justify-end fixed right-0 xl:right-[10%] top-[35dvh]">
                            <button
                              type="submit"
                              disabled={
                                Object.keys(editedPrices).length === 0 ||
                                editLoading
                              }
                              className="text-white bg-green-600 hover:bg-green-800 cursor-pointer font-medium rounded-lg text-sm px-5 py-2.5 text-center 
                          inline-flex items-center me-2"
                            >
                              {t("priceList.save")}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              <AddModal
                open={open}
                onClose={() => setOpen(false)}
                categories={formatGroupCodeToCategories(products.products)}
                menus={formatMenus(products.menus)}
                products={products.products}
              />
            </div>
          </main>
        </div>
      )}
    </section>
  );
};

export default ProductPrice;
