import { useSelector } from "react-redux";
import Select from "react-select";
import { components } from "react-select";

const CompareSelect = ({ value, options, onChange, className, isDisabled }) => {
  const { reportSummary: summary } = useSelector((state) => state.reports);

  const customOption = ({ data, ...props }) => (
    <components.Option
      {...props}
      className={`${
        !summary?.[data.ipAddress]?.success && "pointer-events-none"
      }`}
    >
      <div className={`flex justify-between gap-2`}>
        <span>{data.label}</span>
        {summary?.[data.ipAddress]?.loading ? (
          <span className="text-yellow-500">Loading</span>
        ) : summary?.[data.ipAddress]?.success ? (
          <span className="text-green-500">Online</span>
        ) : (
          <span className="text-red-500">Offline</span>
        )}
      </div>
    </components.Option>
  );

  return (
    <Select
      value={value}
      onChange={onChange}
      options={options}
      className={`${className} text-[--tx-7]`}
      isDisabled={isDisabled}
      components={{ Option: customOption }}
      styles={{
        control: (provided, state) => ({
          ...provided,
          boxShadow: "none",
          border: "--gr-3",
          backgroundColor: "--btn-txt",
          color: "--gr-7",
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor:
            state.label === value.label ? "var(--gr-4)" : "var(--btn-txt)",
          color: "var(--gr-7)",
        }),
        singleValue: (provided, state) => ({
          ...provided,
          color: "var(--gr-7)",
        }),
        menu: (provided, state) => ({
          ...provided,
          backgroundColor: "--btn-txt",
        }),
        menuList: (provided, state) => ({
          ...provided,
          maxHeight: "16rem",
          borderBottomLeftRadius: ".3rem",
          borderBottomRightRadius: ".3rem",
          paddingBottom: "0",
          //scrollbarWidth: "none", // Remove scrollbar for Firefox
          //"&::-webkit-scrollbar": {
          //  display: "none", // Hide scrollbar for Webkit browsers (Chrome, Safari)
          //},
        }),
        input: (provided, state) => ({
          ...provided,
          color: "var(--tx-2)", // Change this to the desired text color
        }),
      }}
    />
  );
};

export default CompareSelect;
