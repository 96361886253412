import { useState } from "react";
import CreatableSelect from "react-select/creatable";

const CategorySelect = ({
  isDisabled,
  value,
  onChange,
  categories,
  setCategories,
  className,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleCreate = (inputValue) => {
    const newOption = { label: inputValue, value: inputValue.toLowerCase() };
    setIsLoading(true);
    setCategories((prev) => [...prev, newOption]);
    onChange(newOption);
    setIsLoading(false);
  };

  return (
    <CreatableSelect
      isClearable
      isDisabled={isLoading || isDisabled}
      isLoading={isLoading}
      onChange={(newValue) => onChange(newValue)}
      onCreateOption={handleCreate}
      options={categories}
      value={value || ""}
      className={`${className} text-[--tx-7]`}
      styles={{
        control: (provided, state) => ({
          ...provided,
          boxShadow: "none",
          border: "--gr-3",
          backgroundColor: "--btn-txt",
          color: "--gr-7",
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor:
            state.label === value.label ? "var(--gr-4)" : "var(--btn-txt)",
          color: "var(--gr-7)",
        }),
        singleValue: (provided, state) => ({
          ...provided,
          color: "var(--gr-7)",
        }),
        menu: (provided, state) => ({
          ...provided,
          backgroundColor: "--btn-txt",
        }),
        menuList: (provided, state) => ({
          ...provided,
          maxHeight: "16rem",
          borderBottomLeftRadius: ".3rem",
          borderBottomRightRadius: ".3rem",
          paddingBottom: "0",
          //scrollbarWidth: "none", // Remove scrollbar for Firefox
          //"&::-webkit-scrollbar": {
          //  display: "none", // Hide scrollbar for Webkit browsers (Chrome, Safari)
          //},
        }),
        input: (provided, state) => ({
          ...provided,
          color: "var(--tx-2)", // Change this to the desired text color
        }),
      }}
    />
  );
};

export default CategorySelect;
