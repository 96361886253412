//Generate a single PDF
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "../vfs_fonts";
import { FormatCalendar } from "../redux/utils";

// Register fonts
pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  SegoUi: {
    normal: "segoeuiRegular.ttf",
    bold: "segoeuiBold.ttf",
  },
};

export async function arrayToPdf(
  data,
  translations,
  branchName,
  merge = false
) {
  // Clone the tables variable to ensure it's mutable
  const tables = JSON.parse(JSON.stringify(data.tables));
  const formattedName = data.name
    .replace(/^PTR-|[^a-zA-Z\sıIğĞüÜşŞiİöÖçÇ]/g, "")
    .trim();
  const filename = `${branchName}-${formattedName}`;
  const mainHeader = {
    name: formattedName,
    startDate: FormatCalendar(data.startDate, ".", true, false),
    endDate: FormatCalendar(data.endDate, ".", true, false),
    header: data.header,
  };

  // Adjust the number of columns and cells  else causes an error
  isRawAndColumnLength(tables);

  //console.log(tables);
  //console.log(tables);

  const documentDefinition = {
    content: [
      [
        branchName && {
          text: `${translations.branchName}: ${branchName}`,
          style: "mainHeader",
        },
        {
          text: `${translations.name}: ${mainHeader.name}`,
          style: "mainHeader",
        },
        {
          text: `${translations.startD}: ${mainHeader.startDate}`,
          style: "mainHeader",
        },
        {
          text: `${translations.endD}: ${mainHeader.endDate}`,
          style: "mainHeader",
        },
        { text: "", margin: [0, 10, 0, 0] }, // Add space between header and tables
      ],
      ...tables.map((table) => [
        { text: table.name, style: "tableTitle" },
        {
          table: {
            headerRows: 1,
            //widths: table.columns.map(() => "*"),
            widths:
              table.columns.length < 6
                ? table.columns.map(() => "*")
                : calculateWidths(table.columns, table.rows),
            body: [
              table.columns.map((column) => ({
                text: column.header || "", // Handle null or undefined header
                style: "tableHeader",
              })),
              ...(table.rows || []).map((row) =>
                row.cells.map((cell) => ({
                  text: cell || "",
                  style: "tableRow",
                }))
              ),
            ],
          },
          layout: {
            hLineWidth: () => 0.5, // Horizontal line width
            vLineWidth: () => 0.5, // Vertical line width
            hLineColor: () => "#9F9F9F", // Horizontal line color
            vLineColor: () => "#9F9F9F", // Vertical line color
            paddingLeft: () => 1, // Padding left for each cell
            paddingRight: () => 1, // Padding right for each cell
            paddingTop: () => 5, // Padding top for each cell
            paddingBottom: () => 5, // Padding bottom for each cell
          },
        },
        { text: "", margin: [0, 5, 0, 0] }, // Add space between tables
      ]),
    ],
    styles: {
      mainHeader: {
        fontSize: 12,
        /* bold: true, */ margin: [0, 0, 0, 5],
        font: "SegoUi",
      },
      tableTitle: {
        fontSize: 16,
        /* bold: true, */ margin: [0, 0, 0, 0], // Reduce margin between table name and rows
        font: "SegoUi",
      },
      tableHeader: {
        fontSize: 10,
        /* bold: true, */
        alignment: "left",
        fillColor: "#FAF7F7", // Remove background color
        color: "#393636", // Change text color to black
        border: [true, true, true, false], // Add borders to left, top, and right
        margin: [0, 0, 0, 0], // Adjust margins
        font: "SegoUi",
      },
      tableRow: {
        fontSize: 10,
        alignment: "left",
        border: [true, false, true, false], // Add borders to left and right
        margin: [2, 0, 0, 0], // Adjust margins
        font: "SegoUi",
      },
      tableRow_2: {
        fontSize: 10,
        alignment: "right",
        border: [true, false, true, false], // Add borders to left and right
        margin: [2, 0, 0, 0], // Adjust margins
        font: "SegoUi",
      },
    },
    defaultStyle: {
      font: "SegoUi",
    },
  };
  cellToRight(documentDefinition);

  if (merge) {
    return documentDefinition;
  } else {
    const pdfDoc = pdfMake.createPdf(documentDefinition);
    pdfDoc.download(`${filename}.pdf`);
  }
}

export const isRawAndColumnLength = (tables) => {
  tables.forEach((table, index) => {
    const { columns, rows } = table;
    const columnCount = columns.length;
    const rowCount = rows.length ? rows.length : 0;
    let cellCount = columnCount;

    // add cells object(row) to the rows if there is no row inside rows
    if (rowCount === 0) {
      rows.push({ cells: [""] });
      //console.log("row number", index, table.name);
    }

    // take the minimun row length
    rows.map((row) => {
      if (row.cells.length < cellCount) {
        cellCount = row.cells.length;
      }
      return row;
    });

    // Ensure the number of columns matches the number of cells in each row
    if (columnCount < cellCount) {
      // Add missing columns
      const missingColumns = cellCount - columnCount;
      for (let i = 0; i < missingColumns; i++) {
        columns.push({ header: "" });
      }
    } else if (columnCount > cellCount) {
      // Add missing cells to each row to match the number of columns
      const missingCells = columnCount - cellCount;
      rows.map((row) => {
        for (let i = 0; i < missingCells; i++) {
          if (row.cells.length < columnCount) {
            row.cells.push("");
          }
        }
        return row;
      });
    }
  });
};

const cellToRight = (documentDefinition) => {
  documentDefinition.content.map((elemnt) => {
    elemnt.map((element) => {
      element.table !== undefined &&
        element.table.body.map((row) => {
          const cell = row[row.length - 1];
          if (cell.style === "tableRow") {
            cell.style = "tableRow_2";
            //console.log(cell);
            return cell;
          }
          return element;
        });
      return element;
    });
    return elemnt;
  });
};

// Calculate widths based on content
export const calculateWidths = (columns, rows) => {
  const maxLengths = columns.map((col, index) => {
    let maxLength = col.header ? col.header.length : 0;
    rows.forEach((row) => {
      if (row.cells[index] && row.cells[index].length > maxLength) {
        maxLength = row.cells[index].length;
      }
    });
    if (maxLength * 6 > 120) {
      return 100;
    } else {
      return maxLength * 6;
    }
  });

  const totalLength = maxLengths.reduce((sum, len) => sum + len, 0);
  return totalLength < 500 ? columns.map(() => "*") : maxLengths;
};
