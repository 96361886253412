import "../common/customTable.css";

const TagsTable = ({ tags, className }) => {
  return (
    <div className={`overflow-x-auto ${className}`}>
      <table className="w-full text-xs text-center text-[--gr-5]">
        <tbody>
          {tags.map((tag, index) => {
            return (
              <tr
                key={index}
                className="odd:bg-[--btn-txtt] even:bg-[--gr--0] flex justify-between"
              >
                <td key={0} className="flex-col flex items-start justify-start">
                  {tag.tagName}
                </td>
                <td key={1} className="font-normal text-green-400">
                  {tag.tag}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TagsTable;
