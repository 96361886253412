import React from "react";

function viewCustomButton({ onClick, text }) {
  return (
    <button
      className="items-start p-2  mx-auto  bg-[--btn-txt] hover:bg-[--gr-1] border-solid border-[--gr-3] rounded-md"
      onClick={onClick}
    >
      {text ? text : "View report"}
    </button>
  );
}

export default viewCustomButton;
