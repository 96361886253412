import { useEffect, useState } from "react";
//import { FaChevronLeft } from "react-icons/fa";
//import { FaChevronRight } from "react-icons/fa";
import CustomInput from "./customInput";
import { useTranslation } from "react-i18next";
import { FaCaretSquareRight } from "react-icons/fa";

const Sidebar = ({
  data,
  selectedRow,
  setSelectedRow,
  totalProduct,
  title,
  productSearch,
}) => {
  const { t } = useTranslation();
  const [parentId, setParentId] = useState(null);
  const [isHidden, setIsHidden] = useState(true);
  const [inData, setInData] = useState(null);
  const [inputVal, setinputVal] = useState(null);

  const handleSearch = (e) => {
    const val = e.target.value.toLowerCase();
    setinputVal(val);

    if (productSearch) {
      productSearch(e);
      return;
    }
    const res = data.filter((row) => {
      const entity = row.id
        ? row.id
        : row.name
        ? row.name
        : row.Name
        ? row.Name
        : row;
      return String(entity).toLowerCase().includes(val);
    });

    setInData(res);
  };

  const areEqual = (entity, row) => {
    return (
      entity ===
      (row.id ? row.id : row.name ? row.name : row.Name ? row.Name : row)
    );
  };

  const checkIfChar = (row) => {
    if (typeof row === "object") {
      const toChar = "Object";
      return { toChar };
    }
    const trimmedItem = row.replace(/^PTR-/g, ""); // to trim the "Ptr-"
    const noSpace = trimmedItem.trim(); // trim any whitespace beginning and end
    const toChar = noSpace.replace(/[^a-zA-Z\sıIğĞüÜşŞiİöÖçÇ]/g, ""); //replace any non alphabetical character

    const isLetter = /[a-zA-Z\sıIğĞüÜşŞiİöÖçÇ]/.test(noSpace.charAt(0));
    const isNumber = /[0-9]/.test(noSpace.charAt(0));

    // Split the string into an array of characters and access the character at index i
    const theIcon = [...noSpace][0];
    //trimmedItem = trimmedItem.charAt(0).toUpperCase() + trimmedItem.slice(1).toLowerCase()
    return { toChar, isLetter, theIcon, isNumber };
  };

  const getName = (row) => {
    const name = row.Name ? row.Name : row.name ? row.name : row;
    if (checkIfChar(name).isLetter || checkIfChar(name).isNumber) {
      return "📄";
    }

    return checkIfChar(name).theIcon;
  };

  const checkSideText = (row) => {
    //console.log(row, "roeee");
    if (row?.name?.toString().includes("\n") || row?.Name?.includes("\n")) {
      //console.log(row?.name?.split("\n"));
      if (row?.Name) {
        return row.Name.split("\n").map((txt, index) => (
          <p key={index}>{txt}</p>
        ));
      } else if (row?.name) {
        return row.name
          .split("\n")
          .map((txt, index) => <p key={index}>{txt}</p>);
      }
    } else {
      return (
        <p>
          {row.Name
            ? row.Name.charAt(0).toUpperCase() + row.Name.slice(1).toLowerCase()
            : row.name
            ? row.name.charAt(0).toUpperCase() + row.name.slice(1).toLowerCase()
            : checkIfChar(row).toChar}
        </p>
      );
    }
  };

  useEffect(() => {
    setInData(data);
  }, [data]);

  //console.log(data);
  return (
    inData && (
      <div>
        <span
          className={`950m:hidden fixed pl-2 pr-1 py-4 top-[30rem] bg-[var(--bg-1)] border-0 border-y border-r border-solid text-[var(--tx-1)] cursor-pointer z-[1000] transition-all text-[1.5rem] rounded-r-lg ${
            isHidden
              ? "left-[0] border-green-500"
              : "left-[18rem] border-red-500"
          }`}
          onClick={() => setIsHidden((prev) => !prev)}
        >
          {/* 📊 */}
          {isHidden && (
            <FaCaretSquareRight className="text-green-500 text-3xl" />
          )}
          {!isHidden && (
            <FaCaretSquareRight className="text-red-500 text-3xl rotate-180 " />
          )}
        </span>
        <div
          className={`fixed top-0 950m:static w-72 z-40 bg-[var(--bg-1)] transition-all border-[.5px] border-solid border-[--gr-3] overflow-x-hidden ${
            isHidden ? " -left-72" : " left-0"
          }`}
        >
          <div
            className={`bg-[--btn-txt] h-full flex flex-col gap-[.1rem] relative`}
          >
            {title && (
              <div className="border-0 border-b border-[--gr-2] border-solid rounded flex items-center justify-center py-4">
                <h1 className="Poppins font-normal text-base text-[var(--tx-1)]">
                  {title}
                </h1>
              </div>
            )}
            <div className="pl-8 pt-2 pr-2">
              <CustomInput
                placeholder={t("nav.search")}
                onChange={handleSearch}
                value={inputVal}
              />
            </div>

            <div className="h-[100dvh] 950m:h-full">
              <div className="h-[86dvh] 620m:h-[90dvh] overflow-y-auto 950m:h-[71dvh] pb-12">
                {inData.map((row, index) => {
                  return (
                    <div
                      key={index}
                      className={`min-h-12 flex items-center p-2 pl-8 cursor-pointer text-[var(--tx-1)] 
                  hover:bg-[--gr-10] hover:text-[--primary] ${
                    areEqual(selectedRow, row) &&
                    "bg-[--gr-10] text-[--primary]"
                  }`}
                      onClick={() => {
                        setSelectedRow(
                          row.id
                            ? row.id
                            : row.name
                            ? row.name
                            : row.Name
                            ? row.Name
                            : row
                        );
                        setIsHidden((prev) => !prev);
                      }}
                      onMouseEnter={() =>
                        setParentId(
                          row.id
                            ? row.id
                            : row.name
                            ? row.name
                            : row.Name
                            ? row.Name
                            : row
                        )
                      }
                      onMouseLeave={() => setParentId(null)}
                    >
                      {row?.logo ? (
                        <div
                          className={`mr-2 border-[1.5px] border-solid w-8 h-full flex justify-center items-center rounded-lg
                      ${
                        areEqual(parentId, row)
                          ? "text-[--primary]"
                          : areEqual(selectedRow, row)
                          ? "text-[--primary]"
                          : "text-[#E5E7EB]"
                      }
                    `}
                          dangerouslySetInnerHTML={{ __html: row.logo }}
                        ></div>
                      ) : (
                        <div className="mr-2 h-full max-h-12">
                          <p
                            className={`border-[1.5px] border-solid w-8 h-full flex justify-center items-center rounded-lg
                      ${
                        areEqual(parentId, row)
                          ? "text-[--primary]"
                          : areEqual(selectedRow, row)
                          ? "text-[--primary]"
                          : "text-[#E5E7EB]"
                      }
                    `}
                          >
                            <span
                              className={`text-sm ${
                                areEqual(parentId, row)
                                  ? "text-[--primary]"
                                  : areEqual(selectedRow, row)
                                  ? "text-[--primary]"
                                  : "text-[#9CA3AF]"
                              }`}
                            >
                              {getName(row)}
                            </span>
                          </p>
                        </div>
                      )}

                      <div
                        className={`relative flex flex-col justify-center ${
                          row?.id && "min-h-10"
                        }`}
                      >
                        {checkSideText(row)}
                        {row?.id && (
                          <span className="text-xs absolute -bottom-2 left-0 text-green-500 font-bold">
                            {row.id}
                          </span>
                        )}
                      </div>
                      {row.total && (
                        <div className="flex flex-1 justify-end">
                          <span className="text-[--primary] text-xs w-[3.7rem] text-end">
                            {row.total}
                          </span>
                        </div>
                      )}

                      {totalProduct && (
                        <div className="flex flex-1 justify-end">
                          <span className="text-[--primary] text-xs w-[3.7rem] text-end">
                            {
                              totalProduct[
                                row.Name ? row.Name : row.name ? row.name : row
                              ]
                            }
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Sidebar;
