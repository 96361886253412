const TableSkeleton = ({ num }) => {
  return (
    <div className="border border-[--gr--3] border-solid animate-pulse flex flex-col rounded-[8px] shadow-lg mb-2">
      <div className="animate-pulse">
        {Array.from({ length: num || 6 }).map((_, index) => (
          <div
            key={index}
            className={`h-14  rounded col-span-2 ${
              index % 2 === 0 ? "bg-[--gr-1]" : "bg-[--btn-txt]"
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default TableSkeleton;
