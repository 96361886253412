import { useEffect, useState } from "react";
import TicketPopUp from "../reportsPage/reportPopup";
import { resetGetTicketDetail } from "../../redux/tickets/ticketSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

const TicketDetailsComp = ({ open, setOpen, searchOpen }) => {
  const dispatch = useDispatch();

  const { loading, success, ticket, error } = useSelector(
    (state) => state.tickets.getTicketDetail
  );

  const [ticketData, setTicketData] = useState(null);

  useEffect(() => {
    if (success) {
      setTicketData(ticket);
      dispatch(resetGetTicketDetail());
    }
  }, [success, dispatch, ticket]);

  useEffect(() => {
    !open && setTicketData(null);
  }, [open]);

  useEffect(() => {
    if (error) {
      setOpen(false);
      toast.error("Error geting ticket");
      console.log(error);
    }
  }, [error]);

  // REMOVE BODY SCROLLBAR IN POPUP
  useEffect(() => {
    if (open) {
      document.body.classList.add("body_overflow_hidden");
    } else {
      document.body.classList.remove("body_overflow_hidden");
    }

    return () => {
      document.body.classList.remove("body_overflow_hidden");
    };
  }, [open]);

  return (
    <>
      <TicketPopUp
        open={open}
        setOpen={setOpen}
        ticket={ticketData}
        loading={loading}
      />
    </>
  );
};

export default TicketDetailsComp;
