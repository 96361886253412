
const BranchSkeleton = () => (
  <div className=" bg-[--bg-1] flex flex-col rounded-[8px] shadow-2xl min-w-[18rem] animate-pulse">
    <div className="h-[160px] md:h-[250px]">
      <div className="bg-[--bg-2] h-full w-full object-cover rounded-tl-xl rounded-tr-xl sm:rounded-bl-xl sm:rounded-br-xl mb-4 md:mb-0" />
    </div>
    <div className="p-5 flex flex-col flex-wrap justify-between">
      <div>
        <div>
          <p className="h-[18px] w-[100%] bg-[--bg-2] mb-2 rounded"></p>
          <p className="h-[18px] w-[70%] bg-[--bg-2] mb-2 rounded"></p>
        </div>
        <div className="mt-3">
          <p className="h-[12px] w-full sm:w-[80%] lg:w-[80%] bg-[--bg-2] mb-2 rounded"></p>
          <p className="h-[12px] w-full sm:w-[80%] lg:w-[80%] bg-[--bg-2] mb-2 rounded"></p>
          <p className="h-[12px] w-full sm:w-[80%] lg:w-[80%] bg-[--bg-2] mb-2 rounded"></p>
          <p className="h-[12px] w-[60%] sm:w-[60%] lg:w-[60%] bg-[--bg-2] mb-2 rounded"></p>
        </div>
      </div>
      <div className="flex items-center justify-between mt-3">
        <div>
          <p className="h-[12px] w-[50px] bg-[--bg-2] rounded"></p>
        </div>
      </div>
    </div>
  </div>
);

export default BranchSkeleton;
