import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosPrivate } from "../api";
import { formatToUrl } from "../utils";

const api = axiosPrivate();
const port = process.env.REACT_APP_BACKEND_PORT;

const initialState = {
  getUsers: {
    users: [],
    loading: false,
    error: null,
    success: false,
  },
  editUser: {
    loading: false,
    error: null,
    success: false,
  },
  addUser: {
    loading: false,
    error: null,
    success: false,
  },
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    resetEditUser: (state) => {
      state.editUser = initialState.editUser;
    },
    resetAddUser: (state) => {
      state.addUser = initialState.addUser;
    },
    resetGetUsers: (state) => {
      state.getUsers = initialState.getUsers;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.getUsers = {
          loading: true,
          success: false,
          users: [],
          error: false,
        };
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.getUsers = {
          loading: false,
          users: action.payload,
          success: true,
          error: false,
        };
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.getUsers = {
          loading: false,
          success: false,
          users: [],
          error: action.error.message || "An error occurred",
        };
      })
      .addCase(addUser.pending, (state) => {
        state.addUser = {
          loading: true,
          success: false,
          error: null,
        };
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.addUser = {
          loading: false,
          success: true,
          error: null,
        };
        state.getUsers.users.users.push({
          ...action.payload.user,
          id:
            state.getUsers.users.users[state.getUsers.users.users.length - 1]
              .id + 1,
          isEditable: true,
        });
      })
      .addCase(addUser.rejected, (state, action) => {
        state.addUser = {
          loading: false,
          success: false,
          error: action.error?.message || "An error occurred",
        };
      })
      .addCase(editUser.pending, (state) => {
        state.editUser = {
          loading: true,
          success: false,
          error: false,
        };
      })
      .addCase(editUser.fulfilled, (state, action) => {
        state.editUser = {
          loading: false,
          success: true,
          error: false,
        };

        const editedUser = action.payload.user;
        const index = state.getUsers.users.users.findIndex(
          (user) => user.id === editedUser.id
        );
        const oldUser = state.getUsers.users.users[index];
        state.getUsers.users.users[index] = {
          ...oldUser,
          name: editedUser.name,
          pinCode: editedUser.pinCode,
          userRoleId: editedUser.userRoleId,
        };
      })
      .addCase(editUser.rejected, (state, action) => {
        state.editUser = {
          loading: false,
          success: false,
          error: action.error?.message || "An error occurred",
        };
      });
  },
});

export const getUsers = createAsyncThunk(
  "user/getUsers",
  async ({ branchUrl }) => {
    const formattedUrl = formatToUrl(branchUrl);
    //const response = await api.get(`${formattedUrl}/get_users_roles`);
    const response = await api.get(`${port}/users/getUsers`, {
      params: {
        path: formattedUrl,
      },
    });
    return response.data;
  }
);

export const editUser = createAsyncThunk(
  "user/editUser",
  async ({ branchUrl, id, userName, pinCode, roleName, roleId }) => {
    const formattedUrl = formatToUrl(branchUrl);

    //const response = await api.post(`${formattedUrl}/edit_user`, {
    //  id,
    //  userName,
    //  pinCode,
    //  roleName,
    //});

    const response = await api.post(`${port}/users/editUser`, {
      path: formattedUrl,
      id,
      userName,
      pinCode,
      roleName,
    });

    return {
      data: response.data,
      user: {
        id,
        name: userName,
        pinCode,
        userRoleId: roleId,
      },
    };
  }
);

export const addUser = createAsyncThunk(
  "user/addUser",
  async ({ branchUrl, userName, pinCode, roleName, roleId }) => {
    const formattedUrl = formatToUrl(branchUrl);

    //const response = await api.post(`${formattedUrl}/add_user`, {
    //  userName,
    //  pinCode,
    //  roleName,
    //});

    const response = await api.post(`${port}/users/addUser`, {
      path: formattedUrl,
      userName,
      pinCode,
      roleName,
    });

    return {
      data: response.data,
      user: {
        name: userName,
        pinCode,
        userRoleId: roleId,
      },
    };
  }
);

export default userSlice.reducer;
export const { resetAddUser, resetEditUser, resetGetUsers } = userSlice.actions;
