import AutomationCommand from "./automationCommand";
import RestartMessagingServer from "./restartMesServer";
import WorkPeriod from "./workPeriod";

const ActionsPage = () => {
  return (
    <section className="full_height-footer">
      <div className="lg:flex max-w-[38rem] lg:max-w-none grid-cols-1 w-full gap-4">
        <div className="flex-1">
          <AutomationCommand />
        </div>
        <div className="flex-1">
          <WorkPeriod />
        </div>
        <div className="flex-1">
          <RestartMessagingServer />
        </div>
      </div>
    </section>
  );
};

export default ActionsPage;
