import { useDispatch, useSelector } from "react-redux";
import ErrorDisplay from "../components/common/error";
import TableSkeleton from "../components/common/tableSkeleton";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  getOrderTags,
  resetUpdateOrderTagsPrice,
  updateOrderTagsPrice,
} from "../redux/priceList/orderTagsSlice";
import { useNavigate, useParams } from "react-router-dom";
import OrdersTagsTable from "../components/ordersTags/tagsTable";
import { useTranslation } from "react-i18next";
import toastOptions from "../config/toastOptions";
import toast from "react-hot-toast";
import Sidebar from "../components/common/sidebar";
import { extractIp } from "../redux/utils";
import { customToast } from "../components/branches/branchCard";

const OrderTags = () => {
  const { t } = useTranslation();

  const branchUrl = useParams().id;
  const dispatch = useDispatch();
  const topRef = useRef(null);
  const scrollInTable = useRef(null);

  const [editedPrices, setEditedPrices] = useState({});
  const [loadingToastId, setLoadingToastId] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [totalProduct, setTotalProduct] = useState(null);
  const [sideBarData, setSideBarData] = useState(null);
  const ToastOptions = useMemo(() => toastOptions, []);
  const inputRefs = useRef({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const orderTagsReducer = useSelector((state) => state.orderTags);
  const { loading, success, orderTags, error } = orderTagsReducer.getOrderTags;

  const {
    loading: editLoading,
    success: editSuccess,
    error: editError,
  } = orderTagsReducer.updateOrderTagsPrice;

  useEffect(() => {
    dispatch(getOrderTags(branchUrl));
  }, [branchUrl, dispatch]);

  const formatData = (data) => {
    return Object.values(data).map((item) => ({
      id: item.id,
      oldPrice: item.oldPrice,
      newPrice: parseFloat(item.newPrice),
    }));
  };

  const handleKeyPress = useCallback(
    (e, tagIndex, index, totalTags) => {
      if (e.key === "Enter") {
        e.preventDefault();
        const isLastTag = index === totalTags - 1;
        const isLastItem = tagIndex === orderTags.length - 1;
        const nextInput =
          inputRefs.current[
            isLastTag ? (isLastItem ? 0 : tagIndex + 1) : tagIndex
          ][isLastTag ? 0 : index + 1];
        if (nextInput) {
          nextInput.current.focus();
        }
      }
    },
    [orderTags.length]
  );

  const handlePriceChange = (e, orderTag) => {
    const newPrice = e.target.value;
    setEditedPrices((prevPrices) => ({
      ...prevPrices,
      [orderTag.id]: {
        id: orderTag.id,
        oldPrice: orderTag.price,
        newPrice: newPrice,
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateOrderTagsPrice({
        branchUrl,
        editedPrices: formatData(editedPrices),
      })
    );
  };

  const resetStates = useCallback(() => {
    setEditedPrices({});
    setLoadingToastId(null);
  }, []);

  useEffect(() => {
    if (editLoading && loadingToastId === null) {
      setLoadingToastId(
        toast.loading(t("nav.updatePriceP"), { position: "top-right" })
      );
    } else if (editSuccess) {
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
      toast.success(t("nav.updatePriceM"), ToastOptions);
      resetStates();
      dispatch(resetUpdateOrderTagsPrice());
    } else if (editError) {
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
      toast.error(editError, ToastOptions);
    }
  }, [
    ToastOptions,
    dispatch,
    editError,
    editLoading,
    editSuccess,
    loadingToastId,
    resetStates,
    t,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetUpdateOrderTagsPrice());
      resetStates();
    };
  }, [dispatch, resetStates]);

  useEffect(() => {
    if (success) {
      let result = {};
      orderTags.map((prod) => {
        return (result[prod.name] = prod.orderTags.length);
      });
      setTotalProduct(result);
      !selectedRow && setSelectedRow(orderTags[0].id);
    }
  }, [success]);

  const filterTags = (tags) => {
    return orderTags.filter((tag) => tag.id === selectedRow);
  };

  // SCROLL TO TOP (BODY/TABLE)
  useEffect(() => {
    if (topRef.current) {
      const topElement = topRef.current;
      const offset =
        20 * parseFloat(getComputedStyle(document.documentElement).fontSize); // 10rem offset

      // Scroll to the top element's position
      window.scrollTo({
        top: topElement.getBoundingClientRect().top + window.scrollY - offset,
        behavior: "smooth",
      });
    }
    if (scrollInTable.current) {
      if (success) {
        scrollInTable.current.scrollTop = 0;
      }
    }
  }, [selectedRow, success]);

  /// WINDOWS WIDTH ILE ILGILI
  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));

    return () => {
      window.removeEventListener("resize", () =>
        setWindowWidth(window.innerWidth)
      );
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 950) {
      document.body.classList.add("body_overflow_hidden");
    } else {
      document.body.classList.remove("body_overflow_hidden");
    }
    return () => {
      document.body.classList.remove("body_overflow_hidden");
    };
    //console.log(windowWidth);
  }, [windowWidth]);

  ///************* BRANCH STATUS AND LICENCE CONTROL *****************///
  const branchIp = useParams().id;
  const navigate = useNavigate();
  const { branches: allBranches } = useSelector((state) => state.branches);
  const allSummaryData = useSelector((state) => state.reports.reportSummary);
  useEffect(() => {
    const thisBranch = [];
    if (allBranches.length > 0) {
      const pushBranch = allBranches.filter(
        (branch) => extractIp(branch.ipAddress) === String(branchIp)
      );
      thisBranch.push(pushBranch[0]);
    }

    if (!allSummaryData) return;
    if (thisBranch.length > 0 && Object.keys(allSummaryData).length > 0) {
      const thisBranchIp = thisBranch[0].ipAddress;
      if (!allSummaryData?.[thisBranchIp].loading) {
        if (
          !allSummaryData?.[thisBranchIp].success ||
          thisBranch[0]?.status !== 1
        ) {
          console.log(false);
          navigate("/");
          customToast(`${t("branches.inActive", { branch: "" })}`);
          //console.log(allSummaryData?.[thisBranchIp]);
          //console.log(thisBranch[0]?.status);
        } else {
          console.log(true);
        }
      }
    }
  }, [allBranches, allSummaryData, branchIp, selectedRow, navigate, t]);
  ///************* BRANCH STATUS AND LICENCE CONTROL *****************///

  useEffect(() => {
    if (success) {
      const data = [];
      orderTags.map((tag) => {
        return data.push({ Name: tag.name, name: tag.id });
      });
      setSideBarData(data);
    }
  }, [orderTags, success]);

  return (
    <section className="relative pb-3 px-0" ref={topRef}>
      {error ? (
        <ErrorDisplay
          error={error}
          onRetry={() => dispatch(getOrderTags(branchUrl))}
        />
      ) : loading ? (
        <TableSkeleton />
      ) : success && selectedRow && sideBarData ? (
        <section className="flex">
          <aside>
            <Sidebar
              title={`${orderTags.length}${" "} ${t("sidebar.cat")}`}
              data={sideBarData}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              totalProduct={totalProduct}
            />
          </aside>
          <main className="flex-1 overflow-x-auto">
            <div
              className="flex flex-col 950m:max-h-[80dvh] 950m:overflow-y-auto pb-8"
              ref={scrollInTable}
            >
              <div className="max-w-[44rem] min-w-[auto] md:self-center md:min-w-[44rem]">
                <div className="p-3 rounded-lg border-[.5px] border-solid border-[--gr-3] flex-1 overflow-x-auto">
                  <h1 className="block text-xl text-[var(--tx-1)] font-bold Poppins">
                    {t("nav.tags")}
                  </h1>
                  <form className="min-w-max" onSubmit={handleSubmit}>
                    {filterTags(orderTags).map((tag, index) => {
                      inputRefs.current[index] = [];
                      return (
                        <div key={tag.id} className="my-2">
                          <OrdersTagsTable
                            tag={tag}
                            tagIndex={index}
                            inputRefs={inputRefs}
                            editedPrices={editedPrices}
                            onPriceChange={handlePriceChange}
                            handleKeyPress={handleKeyPress}
                          />
                        </div>
                      );
                    })}
                    <div className="p-2 flex items-center justify-end fixed right-0 xl:right-[10%] top-[35dvh]">
                      <button
                        type="submit"
                        disabled={
                          Object.keys(editedPrices).length === 0 || editLoading
                        }
                        className="text-white bg-green-600 hover:bg-green-700 font-medium cursor-pointer rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2"
                      >
                        {t("priceList.save")}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </main>
        </section>
      ) : null}
    </section>
  );
};

export default OrderTags;
