//import { FaFacebook } from "react-icons/fa";
//import { FaTelegram } from "react-icons/fa";
//import { IoLogoWhatsapp } from "react-icons/io";
//import { FaYoutube } from "react-icons/fa";
import { BsTelephonePlus } from "react-icons/bs";
import { MdMarkEmailRead } from "react-icons/md";

//import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();

  return (
    <>
      <section className="bg-[--nav-bg-2] py-4 px-[4%] w-full text-sm text-[var(--gr-7)] font-light fixed bottom-0">
        {/* <div className="w-full flex justify-center gap-[5%]">
        <Link to="https://liwasoft.com">{t("footer.home")}</Link>
        <Link to={"https://liwasoft.com"}>{t("footer.about")}</Link>
        <Link to={"https://liwasoft.com"}>{t("footer.contact")}</Link>
        <Link to={"https://liwasoft.com"}>{t("footer.faq")}</Link>
      </div> */}

        {/*  <div className="w-full gap-[4%] flex justify-center">
        <div className="text-3xl text-[var(--gr-7)]"><FaFacebook /></div>
        <div className="text-3xl text-[var(--gr-7)]"><FaTelegram /></div>
        <div className="text-3xl text-[var(--gr-7)]"><IoLogoWhatsapp /></div>
        <div className="text-3xl text-[var(--gr-7)]"><FaYoutube /></div>
      </div> */}

        <div className="w-full flex flex-wrap gap-2 justify-between">
          <div className="flex items-center">
            <div className="flex items-center flex-wrap text-sm">
              {t("footer.copy", { year: currentYear })}
            </div>
          </div>

          <div className="flex flex-wrap gap-4">
            <div className="flex gap-2">
              <a href="tel:0850 840 78 07" className="flex items-center gap-1">
                <BsTelephonePlus /> <p> 0850 840 78 07</p>
              </a>
            </div>
            <div className="flex gap-2">
              <a
                href="mailto:support@liwasoft.com"
                className="flex items-center gap-1"
              >
                <MdMarkEmailRead /> <p> support@liwasoft.com</p>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
