import { useParams } from "react-router-dom";
import "./customTable.css";

const MyTable = ({
  table,
  showColumns = false,
  showTitle = true,
  className,
  showTickDetail,
}) => {
  const url = useParams()["*"];

  const checkForClick = (cell) => {
    if (url !== "compare" && (cell.startsWith("🧾") || cell.startsWith("#"))) {
      const match = cell.match(/\d+$/);

      if (match) {
        const number = parseInt(match[0], 10);
        return (
          <span
            className="p-1 bg-[--primary] rounded-lg text-white cursor-pointer"
            onClick={() => showTickDetail(number)}
          >
            {cell.replace(/¤/g, "")}
          </span>
        );
      }
    } else {
      return cell.replace(/¤/g, "");
    }
  };

  return (
    <div
      className={`mb-2 bg-[--components-color] p-3 rounded-lg border-[.5px] border-solid border-[--gr-3] text-base text-[--tx-2] font-semibold overflow-x-auto w-full ${className}`}
    >
      <table className="w-full text-sm text-[--gr-5]">
        <thead className="border-b">
          {showTitle && (
            <tr className="bg-[--gr-1]">
              <th
                scope="col"
                className="text-sm font-normal px-6 py-1 text-left sm:text-center"
                colSpan={table.columns.length}
              >
                {table.name}
              </th>
            </tr>
          )}
          {showColumns &&
            table.columns.length > 0 &&
            table.columns.some((el) => el.header) && (
              <tr className="bg-[--gr--0]">
                {table.columns.map((column, index) => (
                  <th
                    key={index}
                    scope="col"
                    className={`px-6 py-4 font-bold text-left`}
                  >
                    {column.header}
                  </th>
                ))}
              </tr>
            )}
        </thead>
        <tbody>
          {table.rows.map((item, index) => {
            return (
              <tr key={index} className="odd:bg-[--btn-txt] even:bg-[--gr--0]">
                {item.cells.map((rowItem, index) => (
                  <th
                    key={index}
                    scope="row"
                    className={`px-6 py-2 font-medium text-[--gr-9] whitespace-nowrap text-left ${
                      index === item.cells.length - 1 && "text-right"
                    }`}
                  >
                    {checkForClick(rowItem)}
                  </th>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MyTable;
