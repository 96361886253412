import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoMdAdd } from "react-icons/io";
import { FaTrashCan } from "react-icons/fa6";
import { customMaxInput } from "../../redux/utils";

const PorsiyonsForm = ({
  porsiyons,
  setPorsiyons,
  price,
  setPrice,
  name,
  setName,
  isDisabled,
}) => {
  const { t } = useTranslation();
  //const [porsiyons, setPorsiyons] = useState([]);
  const [duplicatePorsiyonName, setDuplicatePorsiyonName] = useState(false);

  const formatPrice = (val) => {
    if (!val) return "";
    let formattedVal = val.replace(/[^\d.]/g, "");

    if (formattedVal.includes("..")) {
      formattedVal = formattedVal.replace(/\.\.+/g, ".");
    }
    return formattedVal;
  };

  const handleAddProduct = (e) => {
    e.preventDefault();

    if (duplicatePorsiyonName) return;

    if (name.trim() && price.trim()) {
      setPorsiyons([...porsiyons, { name, price }]);
      name ? setName("") : setName("Normal");
      setPrice("");
    }
  };

  const handleSetName = (e) => {
    const val = e.target.value;
    const pattern = /^[A-Za-z0-9\s,]+$/;

    if (pattern.test(val) || val === "" || true) {
      const isDuplicate = porsiyons.some(
        (pors) => pors.name.toLowerCase() === val.toLowerCase()
      );
      setDuplicatePorsiyonName(isDuplicate);

      if (!isDuplicate) {
        setName(val);
      }
    }
  };

  const handleChangeProduct = (e) => {
    const i = e.target.id;
    const val = e.target.value;

    const editedPorsiyons = [...porsiyons];
    if (e.target.dataset.type === "only_text") {
      const pattern = /^[A-Za-z0-9\s]+$/;

      if (pattern.test(val) || val === "" || true) {
        const isDuplicate = porsiyons.some(
          (pors) => pors.name.toLowerCase() === val.toLowerCase()
        );
        setDuplicatePorsiyonName(isDuplicate);

        if (!isDuplicate) {
          editedPorsiyons[i] = { name: val, price: editedPorsiyons[i].price };
        }
      }
    } else if (e.target.dataset.type === "number") {
      let formattedNum = val.replace(/[^\d.]/g, "");

      if (formattedNum.includes("..")) {
        formattedNum = formattedNum.replace(/\.\.+/g, ".");
      }
      editedPorsiyons[i] = {
        name: editedPorsiyons[i].name,
        price: formattedNum,
      };
    }
    console.log(editedPorsiyons);
    setPorsiyons(editedPorsiyons);
  };

  const handleDeleteProduct = (index) => {
    const updatedProducts = [...porsiyons];
    updatedProducts.splice(index, 1);
    setPorsiyons(updatedProducts);
  };

  return (
    <div className="text-[--tx-1] w-full flex flex-col gap-2">
      <div className="overflow-y-auto max-h-48">
        <span className="text-sm font-medium text-[--tx-7]">
          {" "}
          {t("prodPop.porsiyon")}
        </span>
        <div className="flex flex-col gap-2">
          {porsiyons.map((product, index) => (
            <div key={index} className="flex gap-2 text-[--tx-4] min-h-11">
              <input
                type="text"
                placeholder={t("prodPop.enterPorsiyon")}
                value={product.name}
                className="pl-1 w-full p-2 border border-[--gr-3] border-solid rounded-md bg-[--btn-txt] text-[--gr-5]"
                id={index}
                onChange={handleChangeProduct}
                data-type="only_text"
              />
              <input
                type="text"
                placeholder={t("prodPop.enterPrice")}
                value={product.price}
                className="pl-1 w-full p-2 border border-[--gr-3] border-solid rounded-md bg-[--btn-txt] text-[--gr-5]"
                onChange={handleChangeProduct}
                id={index}
                data-type="number"
                maxLength={11}
              />
              <button
                onClick={() => handleDeleteProduct(index)}
                className="text-white bg-[--primary] hover:bg-[--primary-hover] font-medium rounded-lg px-3 text-center text-sm"
              >
                <FaTrashCan />
              </button>
            </div>
          ))}
        </div>
      </div>

      <div>
        <form action="#" onSubmit={handleAddProduct}>
          <div className="flex gap-2 text-[--tx-4] min-h-11 pr-[.2rem]">
            <input
              type="text"
              placeholder={t("prodPop.enterPorsiyon")}
              value={name}
              onChange={handleSetName}
              className="pl-1 w-full p-2 border border-[--gr-3] border-solid rounded-md bg-[--btn-txt] text-[--gr-5]"
              required
            />
            <input
              type="text"
              placeholder={t("prodPop.enterPrice")}
              value={price ? formatPrice(price) : ""}
              onChange={(e) => setPrice(customMaxInput(e))}
              className="pl-1 w-full p-2 border border-[--gr-3] border-solid rounded-md bg-[--btn-txt] text-[--gr-5]"
              required
              maxLength={11}
            />
            <button
              /* onClick={handleAddProduct} */
              type="submit"
              className="text-white bg-[--primary] hover:bg-[--primary-hover] font-medium rounded-lg px-2 text-center text-2xl"
              disabled={isDisabled}
            >
              <IoMdAdd />
            </button>
          </div>
          <div className="h-5">
            {duplicatePorsiyonName && (
              <p className="text-xs text-red-600"> {t("prodPop.porsMsg")} </p>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default PorsiyonsForm;
