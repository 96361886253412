import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { formatToUrl } from "../utils";
import { axiosPrivate } from "../api";

const api = axiosPrivate();
const port = process.env.REACT_APP_BACKEND_PORT;

const initialState = {
  getOrderTags: {
    loading: false,
    success: false,
    orderTags: [],
    error: null,
  },
  updateOrderTagsPrice: {
    loading: false,
    success: false,
    error: null,
  },
};

const orderTagsSlice = createSlice({
  name: "orderTags",
  initialState: initialState,
  reducers: {
    resetGetOrderTags: (state) => {
      state.getOrderTags = initialState.getOrderTags;
    },
    resetUpdateOrderTagsPrice: (state) => {
      state.updateOrderTagsPrice = initialState.updateOrderTagsPrice;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrderTags.pending, (state) => {
        state.getOrderTags = {
          loading: true,
          success: false,
          orderTags: [],
          error: null,
        };
      })
      .addCase(getOrderTags.fulfilled, (state, action) => {
        state.getOrderTags = {
          loading: false,
          orderTags: action.payload,
          success: true,
          error: null,
        };
      })
      .addCase(getOrderTags.rejected, (state, action) => {
        state.getOrderTags = {
          loading: false,
          success: false,
          orderTags: [],
          error: action.error.message,
        };
      })
      .addCase(updateOrderTagsPrice.pending, (state) => {
        state.updateOrderTagsPrice = {
          loading: true,
          success: false,
          error: null,
        };
      })
      .addCase(updateOrderTagsPrice.fulfilled, (state, action) => {
        state.updateOrderTagsPrice = {
          loading: false,
          success: true,
          error: null,
        };

        const editedPrices = action.payload.editedPrices;

        state.getOrderTags.orderTags = state.getOrderTags.orderTags.map(
          (tag) => ({
            ...tag,
            orderTags: tag.orderTags.map((orderTag) => {
              const editedPrice = editedPrices.find(
                (price) => price.id === orderTag.id
              );
              if (editedPrice) {
                return { ...orderTag, price: editedPrice.newPrice };
              }
              return orderTag;
            }),
          })
        );
      })
      .addCase(updateOrderTagsPrice.rejected, (state, action) => {
        state.updateOrderTagsPrice = {
          loading: false,
          success: false,
          error: action.error.message,
        };
      });
  },
});

export const getOrderTags = createAsyncThunk(
  "orderTags/getOrderTags",
  async (branchUrl) => {
    const formattedUrl = formatToUrl(branchUrl);
    //const response = await api.get(`${formattedUrl}/get_order_tags`);
    const response = await api.get(`${port}/prices/getOrderTags`, {
      params: {
        path: formattedUrl,
      },
    });
    return response.data;
  }
);

export const updateOrderTagsPrice = createAsyncThunk(
  "orderTags/updateOrderTagsPrice",
  async ({ branchUrl, editedPrices }) => {
    const formattedUrl = formatToUrl(branchUrl);

    let errorCount = 0;
    try {
      const results = await Promise.all(
        editedPrices.map(async (entity) => {
          try {
            //const res = await api.post(
            //  `${formattedUrl}/update_order_tag_price`,
            //  entity
            //);
            const res = await api.post(`${port}/prices/updateOrderTags`, {
              path: formattedUrl,
              entity,
            });
            return res.data;
          } catch (error) {
            errorCount++;
            if (errorCount >= 0.75 * formattedUrl.length) {
              throw new Error("Error updating product");
            }
            throw error;
          }
        })
      );

      return { data: results, editedPrices };
    } catch (error) {
      throw new Error("Error updating product");
    }
  }
);

export default orderTagsSlice.reducer;
export const { resetGetOrderTags, resetUpdateOrderTagsPrice } =
  orderTagsSlice.actions;
