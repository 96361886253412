import { useEffect, useState } from "react";
import CompareForm from "../components/compare/compareForm";
import { arrayToPdf } from "../utils/arrayToPdf";
import { arrayToExcel } from "../utils/arrayToExel";
import { arrayToPdfMerged, formatName } from "../utils/mergedPdf";
import { arrayToExcelMerged } from "../utils/mergedExel";
import { useTranslation } from "react-i18next";
//icons
import { PiFileXls } from "react-icons/pi";
import { PiFilePdf } from "react-icons/pi";
import { IoClose } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { extractIp } from "../redux/utils";
import { customToast } from "../components/branches/branchCard";

const ComparePage = () => {
  const { t } = useTranslation();
  const [dataLeft, setDataLeft] = useState(null);
  const [dataRight, setDataRight] = useState(null);
  const [leftBranch, setLeftBranch] = useState(null);
  const [rightBranch, setRightBranch] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [fileSelected, setFileSelected] = useState(null);
  const translations = {
    endD: t("exel.endD"),
    header: t("exel.header"),
    name: t("exel.name"),
    startD: t("exel.startD"),
    tableName: t("exel.tableName"),
    branchName: t("exel.branchName"),
  };

  const setStates = (bool, file) => {
    setIsOpen(bool);
    setFileSelected(file);
  };

  const callFileChanger = (fileNeeded, data, branch) => {
    if (fileNeeded === "Pdf") {
      arrayToPdf(data, translations, branch);
    } else if (fileNeeded === "Excel") {
      arrayToExcel(data, translations, branch);
    }
  };

  const callFileChangerMerge = async (
    fileNeeded,
    dataLeft,
    dataRight,
    branchLeft,
    branchRight
  ) => {
    if (fileNeeded === "Pdf") {
      arrayToPdfMerged([dataLeft, dataRight], translations, [
        branchLeft,
        branchRight,
      ]);
    } else if (fileNeeded === "Excel") {
      arrayToExcelMerged(dataLeft, dataRight, translations, {
        branchLeft,
        branchRight,
      });
    }
  };

  ///************* BRANCH STATUS AND LICENCE CONTROL *****************///
  const branchIp = useParams().id;
  const navigate = useNavigate();
  const { branches: allBranches } = useSelector((state) => state.branches);
  const allSummaryData = useSelector((state) => state.reports.reportSummary);
  useEffect(() => {
    const thisBranch = [];
    if (allBranches.length > 0) {
      const pushBranch = allBranches.filter(
        (branch) => extractIp(branch.ipAddress) === String(branchIp)
      );
      thisBranch.push(pushBranch[0]);
    }
    if (!allSummaryData) return;
    if (thisBranch.length > 0 && Object.keys(allSummaryData).length > 0) {
      const thisBranchIp = thisBranch[0].ipAddress;
      if (!allSummaryData?.[thisBranchIp].loading) {
        if (
          !allSummaryData?.[thisBranchIp].success ||
          thisBranch[0]?.status !== 1
        ) {
          console.log(false);
          navigate("/");
          customToast(`${t("branches.inActive", { branch: "" })}`);
          //console.log(allSummaryData?.[thisBranchIp]);
          //console.log(thisBranch[0]?.status);
        } else {
          console.log(true);
        }
      }
    }
  }, [allBranches, allSummaryData, branchIp, dataLeft, dataRight, navigate, t]);
  ///************* BRANCH STATUS AND LICENCE CONTROL *****************///

  return (
    <section className="full_height-footer">
      {dataLeft || dataRight ? (
        <div className="">
          <div className="flex justify-end">
            <div className="flex w-max gap-2 p-1 mb-2 rounded-lg border-[.5px] border-solid border-[--gr-3] text-[var(--btn-txt)] text-2xl">
              <button
                className="bg-green-600 p-1 rounded"
                onClick={() => setStates(true, "Pdf")}
              >
                <PiFilePdf />
              </button>
              <button
                className="bg-green-600 p-1 rounded"
                onClick={() => setStates(true, "Excel")}
              >
                <PiFileXls />
              </button>
            </div>
          </div>

          <div
            className={`fixed inset-0 flex justify-center items-center transition-colors z-[100] ${
              isOpen ? "visible bg-black/20" : "invisible"
            }`}
            onClick={() => setIsOpen(false)}
          >
            <div
              className={`bg-[--btn-txt] w-4/5 md:w-1/2 min-w-80 rounded-xl shadow p-6 transition-all ${
                isOpen ? "scale-100 opacity-100" : "scale-125 opacity-0"
              }`}
              style={{ maxHeight: "80vh", overflowY: "auto" }}
            >
              <div className="w-full h-max self-center mb-2 flex flex-col gap-2 text-[--tx-1]">
                <div className="flex justify-end">
                  <IoClose
                    onClick={() => setIsOpen(false)}
                    className="rounded-full p-1 text-4xl cursor-pointer hover:bg-[--gr-0] hover:text-[--gr-6]"
                  />
                </div>
                <div className="text-xl text-bold mb-4 flex justify-center">
                  <p>
                    {fileSelected} {t("exel.options")}
                  </p>
                </div>

                {dataLeft && (
                  <div>
                    <button
                      className="w-full min-h-[4.5rem] bg-[var(--primary)] hover:bg-[var(--primary-hover)] text-lg text-slate-200 p-2 rounded"
                      onClick={() =>
                        callFileChanger(fileSelected, dataLeft, leftBranch)
                      }
                      disabled={!dataLeft}
                    >
                      <p>{leftBranch}</p>
                      <p>{formatName(dataLeft)}</p>
                    </button>
                  </div>
                )}

                {dataRight && (
                  <div>
                    <button
                      className="w-full min-h-[4.5rem] bg-[var(--primary)] hover:bg-[var(--primary-hover)] text-lg text-slate-200 p-2 rounded"
                      onClick={() =>
                        callFileChanger(fileSelected, dataRight, rightBranch)
                      }
                      disabled={!dataRight}
                    >
                      <p>{rightBranch}</p>
                      <p>{formatName(dataRight)}</p>
                    </button>
                  </div>
                )}

                {dataRight && dataLeft && (
                  <div>
                    <button
                      className="w-full min-h-[4.5rem] bg-[var(--primary)] hover:bg-[var(--primary-hover)] text-lg text-slate-200 p-2 rounded"
                      onClick={() =>
                        callFileChangerMerge(
                          fileSelected,
                          dataLeft,
                          dataRight,
                          leftBranch,
                          rightBranch
                        )
                      }
                      disabled={!dataLeft || !dataRight}
                    >
                      {t("exel.merged")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <main className="grid font-poppins gap-3 text-[--tx-6] grid-cols-1 md:grid-cols-2">
        {[1, 2].map((entity) => (
          <CompareForm
            key={entity}
            index={entity}
            setDataLeft={setDataLeft}
            setDataRight={setDataRight}
            setLeftBranch={setLeftBranch}
            setRightBranch={setRightBranch}
          />
        ))}
      </main>
    </section>
  );
};

export default ComparePage;
