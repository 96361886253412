import { useEffect, useState, useCallback, useMemo } from "react";
import { IoClose } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { editUser, resetEditUser } from "../../redux/users/usersSlice";
import toast from "react-hot-toast";
import toastOptions from "../../config/toastOptions";
import CustomSelect from "../common/customSelect";
import CustomInput from "../common/customInput";
import { useTranslation } from "react-i18next";

const EditUser = ({ open, onClose, roles, user, users }) => {
  const { t } = useTranslation();

  const branchUrl = useParams().id;
  const [role, setRole] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [loadingToastId, setLoadingToastId] = useState(null);
  const [duplicateName, setDuplicateName] = useState(false);
  const [duplicatePin, setDuplicatePin] = useState(false);
  const ToastOptions = useMemo(() => toastOptions, []);
  const dispatch = useDispatch();

  const usersReducer = useSelector((state) => state.users);
  const { loading, success, error } = usersReducer.editUser;

  const resetState = useCallback(() => {
    setCurrentUser({});
    setRole("");
    setLoadingToastId(null);
    setDuplicateName(false);
    setDuplicatePin(false);
  }, []);

  const checkUser = (name) => {
    return users.some(
      (user) =>
        user.name.toLowerCase() === name.toLowerCase() &&
        user.id !== currentUser.id
    );
  };

  const checkPin = (pin) => {
    return users.some(
      (user) => user.pinCode === pin && user.id !== currentUser.id
    );
  };

  const findRoleName = useCallback(
    (roleId) => {
      const role = roles.find((role) => role.id === roleId);
      return role ? { label: role.name, value: roleId } : null;
    },
    [roles]
  );

  useEffect(() => {
    if (user) {
      setCurrentUser(user);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      setRole(user.userRoleId);
    }
  }, [user]);

  const formatRoles = () => {
    const rls = [];
    roles.map((role) =>
      rls.push({
        label: role.name,
        value: role.id,
      })
    );
    return rls;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !currentUser.name ||
      !currentUser.pinCode ||
      !role ||
      duplicateName ||
      duplicatePin
    ) {
      return;
    }

    dispatch(
      editUser({
        branchUrl,
        id: currentUser.id,
        userName: currentUser.name,
        pinCode: currentUser.pinCode,
        roleName: findRoleName(currentUser.userRoleId).label,
        roleId: currentUser.userRoleId,
      })
    );
  };

  useEffect(() => {
    if (loading && loadingToastId === null) {
      setLoadingToastId(
        toast.loading(t("nav.updateUserM"), { position: "top-right" })
      );
    } else if (success) {
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
      toast.success(t("nav.updateUserM"), ToastOptions);
      onClose();
      resetState();
      dispatch(resetEditUser());
    } else if (error) {
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
      toast.error(error, ToastOptions);
      dispatch(resetEditUser());
    }
  }, [
    ToastOptions,
    dispatch,
    error,
    loading,
    loadingToastId,
    onClose,
    resetState,
    success,
    t,
  ]);

  useEffect(() => {
    return () => {
      resetState();
      dispatch(resetEditUser());
    };
  }, [dispatch, resetState]);

  const setPinVal = (e) => {
    if (e.target.value.length > 20) return;
    setCurrentUser((prevUser) => ({
      ...prevUser,
      pinCode: e.target.value,
    }));
    setDuplicatePin(checkPin(e.target.value));
  };

  return (
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors p-[2%] ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        className={`bg-[--btn-txt] w-full max-w-[35rem] rounded-xl shadow p-6 transition-all border border-solid border-[--gr-4] ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        {!loading && (
          <span className="flex justify-end text-2xl text-[--gr-6]">
            <IoClose
              onClick={onClose}
              className="rounded-full p-1 text-4xl cursor-pointer"
            />
          </span>
        )}

        <div className="text-[--gr-5] font-bold text-lg text-center">
          {t("userMgmnt.edit")}
        </div>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-medium text-[--gr-4]"
            >
              {t("userMgmnt.name")}:
            </label>
            <CustomInput
              type="text"
              id="name"
              value={currentUser.name ? currentUser.name : ""}
              onChange={(e) => {
                setCurrentUser((prevUser) => ({
                  ...prevUser,
                  name: e.target.value,
                }));
                setDuplicateName(checkUser(e.target.value));
              }}
              className="border-solid border-[0.5px] border-[--gr-4] text-[#605F5F] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 
                block w-full p-2.5"
              placeholder={t("userMgmnt.name")}
              required
              disabled={loading}
            />
            {duplicateName && (
              <p className="text-xs text-red-600">{t("addUser.nameMsg")}</p>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-[--gr-4]"
            >
              {t("userMgmnt.pass")}:
            </label>
            <CustomInput
              type="number"
              id="password"
              value={currentUser.pinCode ? currentUser.pinCode : ""}
              onChange={(e) => {
                setPinVal(e);
              }}
              className="border-solid border-[0.5px] border-[--gr-4] text-[#605F5F] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 
                block w-full p-2.5"
              placeholder={t("userMgmnt.pass")}
              required
              disabled={loading}
            />
            {duplicatePin && (
              <p className="text-xs text-red-600">{t("addUser.pinMsg")}</p>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="category"
              className="block mb-2 text-sm font-medium text-[--gr-4]"
            >
              {t("userMgmnt.role")}:
            </label>
            <CustomSelect
              id="category"
              value={findRoleName(role) ? findRoleName(role) : ""}
              onChange={(selectedOption) => {
                setRole(selectedOption.value);
                setCurrentUser((prevUser) => ({
                  ...prevUser,
                  userRoleId: selectedOption.value,
                }));
              }}
              options={formatRoles()}
              required
              isDisabled={loading}
            />
          </div>

          <div className="flex gap-4">
            <button
              className="w-full px-4 py-3 rounded-lg bg-[--gr-1] text-[#ffffff] hover:opacity-80 transition"
              type="button"
              onClick={onClose}
              disabled={loading}
            >
              {t("addUser.cancel")}
            </button>
            <button
              type="submit"
              disabled={loading}
              className="w-full px-4 py-3 rounded-lg bg-[--primary] text-[#ffffff] hover:opacity-80 transition"
            >
              {loading ? (
                <div role="status" className="flex items-center justify-center">
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 text-[var(--tx-4)] animate-spin fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
              ) : (
                t("addUser.save")
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditUser;
