import { useState, useMemo, useEffect, useCallback } from "react";
//import AddPorsiyonForm from "./porsiyonForm";
//import CustomSelect from "../common/customSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  createProduct,
  resetCreateProduct,
} from "../../redux/priceList/productsSlice";
import { useParams } from "react-router-dom";
import toastOptions from "../../config/toastOptions";
import toast from "react-hot-toast";
import CustomInput from "../common/customInput";
import CategorySelect from "./categorySelect";
import { useTranslation } from "react-i18next";
import { IoClose } from "react-icons/io5";
import PorsiyonsForm from "./porsiyonsForm";

const AddModal = ({ open, onClose, categories, menus, products }) => {
  const { t } = useTranslation();

  const branchUrl = useParams().id;
  const [category, setCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [checkedMenus, setCheckedMenus] = useState([]);
  //const [menu, setMenu] = useState([]);
  const [productName, setProductName] = useState(null);
  const [porsiyons, setPorsiyons] = useState([]);
  const [name, setName] = useState("Normal");
  const [price, setPrice] = useState("");
  const [loadingToastId, setLoadingToastId] = useState(null);
  const ToastOptions = useMemo(() => toastOptions, []);
  const [duplicateProductName, setduplicateNameError] = useState(false);
  const [confirmProduct, setConfirmProduct] = useState(false);

  const dispatch = useDispatch();
  const { loading, success, error } = useSelector(
    (state) => state.products.createProduct
  );

  const checkProduct = (name) => {
    return products.some(
      (product) => product.name.toLowerCase() === name.toLowerCase()
    );
  };

  const handleMenuCheck = (e) => {
    const label = e.target.id;
    if (e.target.checked) {
      setCheckedMenus([...checkedMenus, label]);
    } else {
      setCheckedMenus(checkedMenus.filter((menu) => menu !== label));
    }
  };

  const handleCheckSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const nullPorsVal = porsiyons.some(
      (product) => !product.name || !product.price
    );

    if (!branchUrl) {
      console.log("no branch url");
      //toast.error("Branch not found", {position: "top-right"})
      return;
    }

    if (checkedMenus.length === 0) {
      console.log("no checked menus");
      return;
    }

    if (!category) {
      console.log("no category");
      return;
    }

    if (!productName) {
      console.log("no prod name");
      return;
    }

    if ((name && !price) || (price && !name)) {
      console.log("No product name or price");
      return;
    }

    if (porsiyons.length === 0) {
      if (!name || !price) {
        console.log("No product name and price");
        return;
      }
      console.log("no posiyon len");
    }

    if (duplicateProductName) {
      console.log("duplicate p name");
      return;
    }

    if (nullPorsVal) {
      console.log("null pors value");
      return;
    }

    name && price && setPorsiyons((prev) => [...prev, { name, price }]);
    setName("");
    setPrice("");
    setConfirmProduct(true);
  };

  const handleSubmit = () => {
    onClose();

    dispatch(
      createProduct({
        branchUrl,
        product: {
          name: productName,
          groupCode: category.label,
          portions: porsiyons.length > 0 ? porsiyons : [{ name, price }],
          menu: checkedMenus,
        },
      })
    );
  };

  const resetStates = useCallback(() => {
    setCheckedMenus([]);
    setCategory("");
    setProductName(null);
    setPorsiyons([]);
    setLoadingToastId(null);
    setduplicateNameError(false);
  }, []);

  useEffect(() => {
    if (loading && loadingToastId === null) {
      setLoadingToastId(
        toast.loading(t("nav.addP"), { position: "top-right" })
      );
    } else if (success) {
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
      toast.success(t("nav.addM"), ToastOptions);
      onClose();
      dispatch(resetCreateProduct());
      resetStates();
    } else if (error) {
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
      toast.error(error, ToastOptions);
      dispatch(resetCreateProduct());
    }
  }, [
    ToastOptions,
    loadingToastId,
    dispatch,
    branchUrl,
    resetStates,
    loading,
    success,
    error,
    onClose,
    t,
  ]);

  useEffect(() => {
    setCategoryOptions(categories);
  }, [categories]);

  useEffect(() => {
    return () => {
      resetStates();
      dispatch(resetCreateProduct());
    };
  }, [dispatch, resetStates]);

  useEffect(() => {
    if (open || confirmProduct) {
      document.body.classList.add("body_overflow_hidden");
    } else {
      document.body.classList.remove("body_overflow_hidden");
    }

    return () => {
      document.body.classList.remove("body_overflow_hidden");
    };
  }, [open, confirmProduct]);

  return (
    <>
      {confirmProduct && (
        <ConfirmProduct
          confirmProduct={confirmProduct}
          setConfirmProduct={setConfirmProduct}
          handleSubmit={handleSubmit}
          texts={{
            add: t("prodPop.confirm"),
            cancel: t("prodPop.cancel"),
            save: t("prodPop.save"),
            menu: t("prodPop.menu"),
            cat: t("prodPop.kategory"),
            prodName: t("prodPop.pName"),
            pors: t("prodPop.porsiyon"),
            name: t("priceList.name"),
            price: t("priceList.price"),
          }}
          menus={checkedMenus}
          cat={category}
          prod={productName}
          pors={porsiyons}
        />
      )}
      <div
        className={`
          fixed inset-0 shadow-md flex justify-center items-center transition-colors px-[2%]
          ${open && !confirmProduct ? "visible bg-black/20" : "hidden"}
        `}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className={`
            bg-[--btn-txt] w-full max-w-[35rem] rounded-xl shadow p-6 transition-all border border-solid border-[--gr-4]
            ${
              open && !confirmProduct
                ? "scale-100 opacity-100"
                : "scale-125 opacity-0"
            }
          `}
        >
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClose(e);
            }}
            type="button"
            disabled={loading}
            className="w-full flex justify-end"
          >
            <IoClose className="text-4xl p-1 text-[--gr-4] bg-[--btn-txt] hover:bg-[--gr-0] hover:text-[--gr-6] rounded-full" />
          </button>
          <div>
            <div className="text-[--gr-5] font-bold text-lg text-center">
              {t("prodPop.add")}
            </div>

            <div className="mb-6  border border-[--gr-3] text-[--gr-5] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
              <div className="mb-4 text-[--tx-7]">
                <label
                  htmlFor="menu"
                  className="block mb-2 text-sm font-medium"
                >
                  {t("prodPop.menu")}:
                </label>
                {/* <CustomSelect
                  isDisabled={loading}
                  value={menu || ""}
                  onChange={setMenu}
                  options={menus}
                /> */}
                <div className="flex gap-2 pt-3 w-full flex-wrap" id="menu">
                  {menus &&
                    menus.map((menu) => (
                      <div className="flex gap-1" key={menu.value}>
                        <input
                          type="checkbox"
                          id={menu.value}
                          key={menu.label}
                          onChange={handleMenuCheck}
                          checked={checkedMenus.includes(menu.value)}
                          className="w-[1.1rem] h-[1.1rem]"
                        />
                        <label htmlFor={menu.value}>{menu.label}</label>
                      </div>
                    ))}
                </div>
              </div>
              <div className="mb-4 text-[--tx-7]">
                <label
                  htmlFor="category"
                  className="block mb-2 text-sm font-medium"
                >
                  {t("prodPop.kategory")}:
                  <span className="text-xs">{t("prodPop.catMessage")}</span>
                </label>
                <CategorySelect
                  isDisabled={loading}
                  value={category || ""}
                  onChange={setCategory}
                  categories={categoryOptions}
                  setCategories={setCategoryOptions}
                />
              </div>

              <div className="mb-4 text-[--tx-7]">
                <label
                  htmlFor="productName"
                  className="block mb-2 text-sm font-medium"
                >
                  {t("prodPop.pName")}
                </label>
                <CustomInput
                  type="text"
                  id="productName"
                  value={productName || ""}
                  onChange={(e) => {
                    setProductName(e.target.value);
                    setduplicateNameError(checkProduct(e.target.value));
                  }}
                  placeholder={t("prodPop.enterPName")}
                  required
                  disabled={loading}
                />
                {duplicateProductName && (
                  <p className="text-xs text-red-500">
                    {" "}
                    {t("prodPop.prodMsg")}
                  </p>
                )}
              </div>
              <PorsiyonsForm
                porsiyons={porsiyons}
                setPorsiyons={setPorsiyons}
                isDisabled={loading}
                price={price}
                setPrice={setPrice}
                name={name}
                setName={setName}
              />
            </div>
            <div className="flex gap-4">
              <button
                className="w-full px-4 py-3 rounded-lg bg-[--gr-1] text-[#ffffff] hover:opacity-80 transition"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onClose(e);
                }}
                disabled={loading}
              >
                {t("prodPop.cancel")}
              </button>
              <button
                type="button"
                disabled={loading}
                onClick={handleCheckSubmit}
                className="w-full px-4 py-3 rounded-lg bg-[--primary] text-[#ffffff] hover:opacity-80 transition"
              >
                {t("prodPop.save")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ConfirmProduct = ({
  cat,
  prod,
  pors,
  menus,
  texts,
  handleSubmit,
  confirmProduct,
  setConfirmProduct,
}) => {
  const submitProduct = () => {
    setConfirmProduct(false);
    handleSubmit();
  };

  return (
    <div
      className={`fixed inset-0 shadow-md flex justify-center items-center transition-colors px-[2%]
        ${confirmProduct ? "visible bg-black/20" : "hidden"}`}
    >
      <div
        className={`bg-[--btn-txt] w-full max-w-[35rem] rounded-xl shadow p-6 transition-all border border-solid border-[--gr-4] ${
          confirmProduct ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <div>
          <div className="text-[--gr-5] font-bold text-lg text-center">
            {" "}
            <p>{texts.add}</p>
          </div>
          <div className="mb-6  border border-[--gr-3] text-[--gr-5] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
            <div className="mb-2 text-[--tx-7]">
              <label htmlFor="menu" className="block mb-2 text-sm font-medium">
                <div id="menu" className="text-base font-normal">
                  <span className="font-bold">{texts.menu}:</span>
                  {menus.map((menu, i) => (
                    <span key={i} className="ml-1 text-green-500">
                      {menu.charAt(0).toUpperCase() + menu.slice(1)}
                      {i !== menus.length - 1 && ","}
                    </span>
                  ))}
                </div>
              </label>
            </div>

            <div className="mb-2 text-[--tx-7]">
              <label
                htmlFor="category"
                className="block mb-2 text-sm font-medium"
              >
                <div id="category" className="text-base font-normal">
                  <span className="font-bold">{texts.cat}:</span>
                  <span className="ml-2 text-green-500">{cat.label}</span>
                </div>
              </label>
            </div>

            <div className="mb-2 text-[--tx-7]">
              <label
                htmlFor="product"
                className="block mb-2 text-sm font-medium"
              >
                <div id="product" className="text-base font-normal">
                  <span className="font-bold">{texts.prodName}:</span>
                  <span className="ml-2 text-green-500">{prod}</span>
                </div>
              </label>
            </div>

            <div className="mb-2 text-[--tx-7]">
              <label
                htmlFor="porsiyon"
                className="block mb-2 text-sm font-normal"
              >
                <div id="porsiyon" className="max-w-maxx">
                  <p className="font-bold text-base">{texts.pors}:</p>
                  <table>
                    <tbody>
                      {pors.map((pors, i) => (
                        <tr key={i}>
                          <td className="pr-4 text-green-500">
                            {/* {texts.name}:  */}
                            {pors.name} :
                          </td>
                          <td className="pr-4 text-green-500">
                            {/* {texts.price}:  */}
                            {pors.price}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </label>
            </div>
          </div>

          <div className="flex gap-4">
            <button
              className="w-full px-4 py-3 rounded-lg bg-[--gr-1] text-[#ffffff] hover:opacity-80 transition"
              type="button"
              onClick={() => setConfirmProduct(false)}
            >
              {texts.cancel}
            </button>
            <button
              className="w-full px-4 py-3 rounded-lg bg-[--primary] text-[#ffffff] hover:opacity-80 transition"
              type="button"
              onClick={submitProduct}
            >
              {texts.save}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddModal;
