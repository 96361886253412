import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api";

const port = process.env.REACT_APP_BACKEND_PORT;

const initialState = {
  login: {
    state: {
      loading: false,
      success: false,
      error: null,
    },
    auth: null,
  },
  logout: {
    loading: false,
    success: false,
    error: null,
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    resetLoginState: (state) => {
      state.login.state = { loading: false, success: false, error: null };
      state.login.auth = null;
    },
    resetLogoutState: (state) => {
      state.logout = { loading: false, success: false, error: null };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.login.state = {
          loading: true,
          success: false,
          error: null,
        };
      })
      .addCase(login.fulfilled, (state, action) => {
        state.login = {
          state: { loading: false, success: true, error: null },
          auth: action.payload,
        };
      })
      .addCase(login.rejected, (state, action) => {
        const msg =
          action.error.response?.status === 400
            ? "Missing Username or Password"
            : action.error.response?.status === 401
            ? "Unauthorized"
            : action.error.code === 403
            ? "Invalid crendetials"
            : action.error.code === 404
            ? "Bayiler Giriş Yapamaz"
            : action.error.message || action.payload || "something went wrong";
        state.login.state = {
          loading: false,
          success: false,
          error: msg,
        };
      })
      .addCase(logout.pending, (state) => {
        state.logout = { loading: true, success: false, error: null };
      })
      .addCase(logout.fulfilled, (state) => {
        state.logout = { loading: false, success: true, error: null };
      })
      .addCase(logout.rejected, (state) => {
        state.logout = {
          loading: false,
          success: false,
          error: "Logout failed",
        };
      });
  },
});

export const login = createAsyncThunk("auth/login", async (credentials) => {
  try {
    const response = await api.get(
      `https://liwapos.com/samba.mobil/Json/JsonSambaPatronLogin?email=${credentials.email}&password=${credentials.password}`
    );
    //https://liwapos.com/samba.mobil/Json/JsonSambaPatronLogin?email=demo@liwasoft.com&password=demo@liwasoft.com

    if (response.status !== 200 || response.data.length === 0) {
      throw new Error(response.data || "Login failed");
    }
    if (response.data[0].usersType === 0) {
      throw new Error("Bayiler Giriş Yapamaz");
    }

    const res = await api.post(`${port}/token/generate_token`, {
      email: credentials.email,
      password: credentials.password,
    });

    return {
      ...response.data[0],
      token: res.data.token,
    };
  } catch (err) {
    console.log(err);
    throw new Error(err.message);
  }
});

export const logout = createAsyncThunk("auth/logout", async () => {
  // const response = await api.get("auth/logout", {
  //   headers: { "Content-Type": "application/json" },
  //   withCredentials: true,
  // });
  // if (response.status !== 204) {
  //   throw new Error(response.data.message || "Logout failed");
  // }
  await new Promise((resolve) => setTimeout(resolve, 1000));
  return null;
});

export default authSlice.reducer;
export const { resetLoginState, resetLogoutState } = authSlice.actions;
