import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import {
  resetRestartMsgServerState,
  restartMessagingServer,
} from "../redux/otherActions/restartMsgServerSlice";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { extractIp } from "../redux/utils";
import { customToast } from "../components/branches/branchCard";

const RestartMessagingServer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const branchUrl = useParams().id;
  const { loading, success, error } = useSelector(
    (state) => state.restartMsgServer.restartMesgServer
  );
  const [isOpen, setIsOpen] = useState(false);

  const handleMsgServer = () => {
    setIsOpen(false);
    dispatch(restartMessagingServer({ branchUrl }));
  };

  useEffect(() => {
    if (success) {
      toast.success("Restart Messaging Server Success.", {
        position: "top-right",
      });
      dispatch(resetRestartMsgServerState());
    } else if (error) {
      toast.error(`${error}`, { position: "top-right" });
      dispatch(resetRestartMsgServerState());
    }
  }, [dispatch, success, error]);

  ///************* BRANCH STATUS AND LICENCE CONTROL *****************///
  const branchIp = useParams().id;
  const navigate = useNavigate();
  const { branches: allBranches } = useSelector((state) => state.branches);
  const allSummaryData = useSelector((state) => state.reports.reportSummary);
  useEffect(() => {
    const thisBranch = [];
    if (allBranches.length > 0) {
      const pushBranch = allBranches.filter(
        (branch) => extractIp(branch.ipAddress) === String(branchIp)
      );
      thisBranch.push(pushBranch[0]);
    }

    if (!allSummaryData) return;
    if (thisBranch.length > 0 && Object.keys(allSummaryData).length > 0) {
      const thisBranchIp = thisBranch[0].ipAddress;
      if (!allSummaryData?.[thisBranchIp].loading) {
        if (
          !allSummaryData?.[thisBranchIp].success ||
          thisBranch[0]?.status !== 1
        ) {
          console.log(false);
          navigate("/");
          customToast(`${t("branches.inActive", { branch: "" })}`);
          //console.log(allSummaryData?.[thisBranchIp]);
          //console.log(thisBranch[0]?.status);
        } else {
          console.log(true);
        }
      }
    }
  }, [allBranches, allSummaryData, branchIp, navigate, t]);
  ///************* BRANCH STATUS AND LICENCE CONTROL *****************///

  return (
    <div className={`full_height-footer_ mb-4`}>
      <>
        <div
          className={`fixed inset-0 flex justify-center items-center transition-colors p-[2%] ${
            isOpen ? "visible bg-black/20" : "invisible"
          }`}
        >
          <div
            className={`bg-[--btn-txt] w-full max-w-[40rem] rounded-xl shadow p-6 transition-all border border-[--tx-5] border-solid ${
              isOpen ? "scale-100 opacity-100" : "scale-125 opacity-0"
            }`}
            style={{ maxHeight: "80vh" }}
          >
            <span className="flex justify-end text-2xl text-[--gr-6]">
              <IoClose
                className="rounded-full p-1 text-4xl cursor-pointer hover:bg-[--gr-0] hover:text-[--gr-6]"
                onClick={() => setIsOpen(false)}
              />
            </span>

            <div className="text-[--gr-5] font-semibold text-2xl text-center">
              <p>{t("actions.restart")}</p>
            </div>

            <div className="text-[--tx-1] text-lg text-center mt-3">
              <div className="mb-4">
                <p>{t("actions.serverCon")}</p>
              </div>

              <div className="mb-4 text-lg text-red-500">
                <p>{t("actions.serverMsg")}</p>
              </div>

              <div className="flex gap-4">
                <button
                  className="w-full px-4 py-3 rounded-lg bg-[--gr-1] text-[#ffffff] hover:opacity-80 transition"
                  type="button"
                  onClick={() => setIsOpen(false)}
                  disabled={loading}
                >
                  {t("actions.close")}
                </button>
                <button
                  disabled={loading}
                  className="w-full px-4 py-3 rounded-lg bg-[--primary] text-[#ffffff] hover:opacity-80 transition"
                  onClick={handleMsgServer}
                >
                  {t("actions.yes")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
      <div>
        <div className="w-full text-left">
          <div className="text-slate-300 bg-[--gr-4] ">
            <div>
              <p className="px-6 py-1 font-medium text-base sm:text-lg text-left">
                {t("nav.server")}
              </p>
            </div>
          </div>

          <div className="gap-1 sm:gap-2 flex flex-col mt-2 w-ful text-sm sm:text-base text-white">
            <div
              className={`px-6 py-4 font-medium cursor-pointer bg-[--primary] rounded-sm`}
              onClick={() => setIsOpen(true)}
            >
              {t("actions.restart")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestartMessagingServer;
