export const filterTicketTags = (data, searchQuery) => {
  if (!searchQuery.trim()) return data;

  const normalizedQuery = searchQuery.toLowerCase().trim();

  return data?.filter((item) => {
    const tagMatch = item.tag.toLowerCase().includes(normalizedQuery);

    const tagNameMatch = item.tagName.toLowerCase().includes(normalizedQuery);

    return tagMatch || tagNameMatch;
  });
};

export const filterTicketOrders = (data, searchQue) => {
  if (!searchQue.trim()) return data;

  const normalizedQuery = searchQue.toLowerCase().trim();

  return data?.filter((item) => {
    const nameMatch = item.name.toLowerCase().includes(normalizedQuery);

    const tagMatch = item.tags.some((tag) =>
      tag.tag.toLowerCase().includes(normalizedQuery)
    );

    return nameMatch || tagMatch;
  });
};
