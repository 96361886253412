const NotFoundSvg = () => {
  return ( 
    <>
    <svg width="100%" height="379" viewBox="0 0 631 379" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M317.524 379C488.625 379 627.33 368.857 627.33 356.346C627.33 343.834 488.625 333.692 317.524 333.692C146.423 333.692 7.71875 343.834 7.71875 356.346C7.71875 368.857 146.423 379 317.524 379Z" fill="#F2F2F2" /> {/* F2F2F2 */}
        <path d="M305.65 108.343C255.175 108.343 218.934 144.113 218.934 227.576C218.934 322.167 255.175 347.206 305.65 347.206C356.126 347.206 394.526 319.782 394.526 227.576C394.526 129.01 356.126 108.343 305.65 108.343ZM305.982 315.808C271.021 315.808 252.757 293.153 252.757 227.637C252.757 169.828 271.769 139.343 306.73 139.343C341.691 139.343 360.703 159.368 360.703 227.637C360.703 291.501 340.943 315.808 305.982 315.808Z" fill="#AFB3FF"/>
        <path d="M188.708 281.23H167.839V213.349C167.839 209.744 166.542 206.286 164.234 203.736C161.926 201.187 158.795 199.755 155.531 199.755H150.641C149.025 199.755 147.424 200.106 145.931 200.789C144.438 201.473 143.081 202.474 141.938 203.736C140.796 204.999 139.889 206.497 139.27 208.147C138.652 209.796 138.334 211.564 138.334 213.349V281.23H79.4676C78.2566 281.23 77.0661 280.885 76.0117 280.227C74.9573 279.569 74.0748 278.621 73.4497 277.475C72.8246 276.33 72.4783 275.025 72.4442 273.688C72.4101 272.351 72.6895 271.027 73.2552 269.844L135.558 139.595C136.335 137.971 136.806 136.19 136.942 134.359C137.079 132.529 136.878 130.687 136.352 128.945C135.826 127.202 134.986 125.596 133.883 124.222C132.779 122.848 131.435 121.735 129.932 120.951L126.254 119.033C123.398 117.543 120.133 117.323 117.134 118.418C114.136 119.514 111.633 121.84 110.144 124.917L34.1574 281.927C32.8635 284.601 32.1865 287.586 32.1865 290.617C32.1865 293.038 32.6182 295.435 33.4569 297.672C34.2956 299.908 35.5249 301.94 37.0746 303.652C38.6243 305.364 40.4641 306.722 42.4888 307.648C44.5136 308.574 46.6838 309.051 48.8754 309.051H138.334V351.975C138.334 354.115 138.715 356.234 139.457 358.211C140.198 360.188 141.285 361.984 142.655 363.497C144.024 365.01 145.651 366.211 147.441 367.03C149.23 367.849 151.149 368.27 153.086 368.27C155.024 368.27 156.942 367.849 158.732 367.03C160.522 366.211 162.148 365.01 163.518 363.497C164.888 361.984 165.974 360.188 166.716 358.211C167.457 356.234 167.839 354.115 167.839 351.975V309.051H188.708C192.049 309.051 195.252 307.586 197.614 304.977C199.975 302.368 201.302 298.83 201.302 295.141C201.302 291.451 199.975 287.913 197.614 285.305C195.252 282.696 192.049 281.23 188.708 281.23Z" fill="#656ED3"/>
        <path d="M575.157 281.23H554.287V213.349C554.287 209.744 552.99 206.286 550.682 203.736C548.374 201.187 545.244 199.755 541.98 199.755H537.089C535.473 199.755 533.873 200.106 532.379 200.789C530.886 201.473 529.529 202.474 528.387 203.736C527.244 204.999 526.337 206.497 525.719 208.147C525.1 209.796 524.782 211.564 524.782 213.349V281.23H465.916C464.705 281.23 463.514 280.885 462.46 280.227C461.406 279.569 460.523 278.621 459.898 277.475C459.273 276.33 458.926 275.025 458.892 273.688C458.858 272.351 459.138 271.027 459.703 269.844L522.007 139.595C522.783 137.971 523.254 136.19 523.391 134.359C523.527 132.529 523.326 130.687 522.8 128.945C522.275 127.202 521.435 125.596 520.331 124.222C519.228 122.848 517.883 121.735 516.38 120.951L512.702 119.033C509.846 117.543 506.581 117.323 503.583 118.418C500.584 119.514 498.081 121.84 496.593 124.917L420.606 281.927C419.312 284.601 418.635 287.586 418.635 290.617C418.635 295.506 420.393 300.195 423.523 303.652C426.653 307.109 430.897 309.051 435.324 309.051H524.782V351.975C524.782 356.297 526.336 360.441 529.103 363.497C531.869 366.553 535.622 368.27 539.534 368.27C543.447 368.27 547.199 366.553 549.966 363.497C552.733 360.441 554.287 356.297 554.287 351.975V309.051H575.157C578.497 309.051 581.7 307.586 584.062 304.977C586.423 302.368 587.75 298.83 587.75 295.141C587.75 291.451 586.423 287.913 584.062 285.305C581.7 282.696 578.497 281.23 575.157 281.23Z" fill="#656ED3"/>
        <path d="M194.466 273.28H173.597V205.399C173.597 203.614 173.278 201.846 172.66 200.197C172.041 198.547 171.135 197.049 169.992 195.786C168.849 194.524 167.492 193.523 165.999 192.84C164.506 192.156 162.905 191.805 161.289 191.805H156.399C153.135 191.805 150.004 193.237 147.696 195.786C145.388 198.336 144.091 201.794 144.091 205.399V273.28H85.2254C84.0144 273.28 82.8239 272.935 81.7695 272.277C80.7151 271.619 79.8326 270.671 79.2075 269.525C78.5825 268.38 78.2361 267.075 78.202 265.738C78.1679 264.401 78.4473 263.077 79.013 261.894L141.316 131.645C142.093 130.021 142.564 128.24 142.7 126.41C142.837 124.579 142.636 122.737 142.11 120.995C141.584 119.252 140.744 117.646 139.641 116.272C138.537 114.898 137.193 113.786 135.69 113.001L132.012 111.083C129.156 109.593 125.891 109.373 122.892 110.469C119.894 111.564 117.391 113.89 115.902 116.967L39.9153 273.977C38.6213 276.651 37.9443 279.636 37.9443 282.668C37.9443 287.556 39.7026 292.245 42.8324 295.702C45.9622 299.159 50.207 301.101 54.6332 301.101H144.091V344.025C144.091 346.165 144.473 348.284 145.214 350.261C145.956 352.238 147.042 354.034 148.412 355.547C149.782 357.061 151.409 358.261 153.198 359.08C154.988 359.899 156.907 360.32 158.844 360.32C162.757 360.32 166.509 358.603 169.276 355.547C172.042 352.492 173.597 348.347 173.597 344.025V301.101H194.466C196.12 301.101 197.758 300.742 199.286 300.042C200.814 299.343 202.202 298.319 203.371 297.027C204.541 295.735 205.468 294.202 206.101 292.514C206.734 290.827 207.06 289.018 207.06 287.191C207.06 285.364 206.734 283.555 206.101 281.868C205.468 280.18 204.541 278.646 203.371 277.355C202.202 276.063 200.814 275.038 199.286 274.339C197.758 273.64 196.12 273.28 194.466 273.28Z" stroke="#3F3D56" strokeMiterlimit="10"/>
        <path d="M585.951 273.28H565.081V205.399C565.081 201.794 563.784 198.336 561.476 195.786C559.168 193.237 556.038 191.805 552.773 191.805H547.883C546.267 191.805 544.667 192.156 543.173 192.84C541.68 193.523 540.323 194.524 539.181 195.786C538.038 197.049 537.131 198.547 536.513 200.197C535.894 201.846 535.576 203.614 535.576 205.399V273.28H476.71C475.499 273.28 474.308 272.935 473.254 272.277C472.2 271.619 471.317 270.671 470.692 269.525C470.067 268.38 469.72 267.075 469.686 265.738C469.652 264.401 469.932 263.077 470.497 261.894L532.8 131.645C533.577 130.021 534.048 128.24 534.185 126.41C534.321 124.579 534.12 122.737 533.594 120.995C533.069 119.252 532.228 117.646 531.125 116.272C530.021 114.898 528.677 113.786 527.174 113.001L523.496 111.083C520.64 109.593 517.375 109.373 514.377 110.469C511.378 111.564 508.875 113.89 507.386 116.967L431.4 273.977C430.106 276.651 429.429 279.636 429.429 282.668C429.429 287.556 431.187 292.245 434.317 295.702C437.447 299.159 441.691 301.101 446.118 301.101H535.576V344.025C535.576 348.347 537.13 352.492 539.897 355.547C542.663 358.603 546.416 360.32 550.328 360.32C554.241 360.32 557.993 358.603 560.76 355.547C563.527 352.492 565.081 348.347 565.081 344.025V301.101H585.951C587.604 301.101 589.242 300.742 590.77 300.042C592.298 299.343 593.686 298.319 594.856 297.027C596.025 295.735 596.953 294.202 597.586 292.514C598.219 290.827 598.544 289.018 598.544 287.191C598.544 283.502 597.217 279.963 594.856 277.355C592.494 274.746 589.291 273.28 585.951 273.28Z" stroke="#3F3D56" strokeMiterlimit="10"/>
        <path d="M317.165 101.188C266.689 101.188 230.448 136.958 230.448 220.421C230.448 315.012 266.689 340.051 317.165 340.051C367.641 340.051 406.041 312.627 406.041 220.421C406.041 121.855 367.641 101.188 317.165 101.188ZM317.497 308.653C282.536 308.653 264.271 285.998 264.271 220.482C264.271 162.673 283.284 132.188 318.245 132.188C353.205 132.188 372.218 152.213 372.218 220.482C372.218 284.346 352.457 308.653 317.497 308.653Z" stroke="#3F3D56" strokeMiterlimit="10"/>
        <path d="M338.432 119.486C337.976 121.558 336.008 123.217 333.829 124.376C329.247 126.813 323.595 127.541 318.112 127.785C316.714 127.879 315.311 127.85 313.916 127.699C311.214 127.41 308.679 126.123 306.722 124.046C306.582 123.945 306.467 123.807 306.389 123.644C306.311 123.482 306.271 123.3 306.274 123.116C306.276 122.931 306.321 122.751 306.404 122.591C306.487 122.431 306.605 122.297 306.747 122.201C309.666 118.66 314.536 116.488 319.443 114.925C323.163 113.739 329.651 110.669 333.708 111.083C337.639 111.485 339.009 116.87 338.432 119.486Z" fill="#3F3D56"/>
        <path d="M591.34 272.169C593.119 272.883 595.024 273.131 596.905 272.894C598.786 272.656 600.591 271.939 602.178 270.8C603.765 269.66 605.091 268.129 606.051 266.327C607.011 264.525 607.579 262.501 607.711 260.415C607.842 258.329 607.532 256.238 606.807 254.306C606.082 252.375 604.96 250.656 603.531 249.285C602.101 247.914 600.403 246.929 598.57 246.406C596.737 245.884 594.819 245.839 592.968 246.275L593.274 253.224L590.297 247.269C587.376 248.785 585.119 251.52 584.023 254.873C583.633 256.048 583.397 257.277 583.32 258.525C583.132 261.453 583.813 264.369 585.26 266.831C586.708 269.293 588.842 271.167 591.34 272.169Z" fill="#57B894"/>
        <path d="M606.068 352.767C605.018 346.857 609.571 341.618 614.068 338.212C618.565 334.807 623.82 331.461 625.355 325.681C627.56 317.375 620.991 309.768 615.878 303.209C612.085 298.34 608.827 292.993 606.174 287.278C605.11 284.986 604.132 282.591 603.853 280.034C603.449 276.352 604.52 272.689 605.599 269.177C609.193 257.477 613.036 245.876 617.127 234.373" stroke="#3F3D56" strokeMiterlimit="10"/>
        <path d="M591.843 270.379C593.622 271.094 595.527 271.342 597.408 271.105C599.289 270.867 601.094 270.15 602.681 269.011C604.268 267.871 605.594 266.34 606.554 264.538C607.514 262.736 608.082 260.712 608.213 258.626C608.345 256.54 608.035 254.449 607.31 252.517C606.585 250.586 605.463 248.867 604.034 247.496C602.604 246.125 600.906 245.14 599.073 244.617C597.24 244.095 595.322 244.05 593.471 244.486L593.777 251.435L590.8 245.479C587.879 246.996 585.622 249.731 584.526 253.084C584.136 254.259 583.9 255.488 583.823 256.736C583.635 259.664 584.316 262.58 585.763 265.042C587.211 267.504 589.345 269.378 591.843 270.379Z" stroke="#3F3D56" strokeMiterlimit="10"/>
        <path d="M604.575 231.973C605.273 229.845 606.445 227.946 607.982 226.449C609.52 224.953 611.374 223.909 613.373 223.412L614.379 230.018L616.245 223.08C618.836 223.122 621.347 224.073 623.417 225.795C625.486 227.516 627.007 229.92 627.759 232.659C628.511 235.397 628.456 238.329 627.602 241.031C626.747 243.733 625.137 246.064 623.004 247.69C620.872 249.315 618.327 250.149 615.737 250.073C613.147 249.996 610.646 249.012 608.595 247.264C606.544 245.515 605.049 243.091 604.327 240.343C603.604 237.594 603.691 234.663 604.575 231.973Z" fill="#57B894"/>
        <path d="M605.246 229.588C605.944 227.46 607.116 225.56 608.653 224.064C610.191 222.568 612.044 221.523 614.044 221.026L615.05 227.633L616.916 220.694C619.507 220.737 622.018 221.687 624.088 223.409C626.157 225.131 627.678 227.535 628.43 230.273C629.182 233.012 629.127 235.944 628.272 238.645C627.418 241.347 625.808 243.679 623.675 245.304C621.543 246.929 618.998 247.764 616.408 247.687C613.817 247.611 611.316 246.627 609.266 244.878C607.215 243.129 605.72 240.705 604.998 237.957C604.275 235.209 604.362 232.278 605.246 229.588Z" stroke="#3F3D56" strokeMiterlimit="10"/>
        <path d="M613.732 303.343C616.554 304.474 619.653 304.418 622.44 303.187C625.227 301.955 627.506 299.634 628.842 296.666C630.179 293.698 630.481 290.29 629.689 287.092C628.897 283.894 627.068 281.13 624.55 279.326L623.116 284.658L622.1 277.974C622.089 277.969 622.079 277.964 622.068 277.961C620.559 277.355 618.957 277.084 617.353 277.162C615.749 277.241 614.174 277.667 612.719 278.418C611.265 279.169 609.958 280.229 608.874 281.538C607.791 282.847 606.951 284.378 606.404 286.046C605.856 287.713 605.611 289.483 605.683 291.255C605.755 293.027 606.143 294.765 606.823 296.372C607.504 297.978 608.464 299.421 609.65 300.617C610.835 301.813 612.222 302.74 613.732 303.343Z" fill="#57B894"/>
        <path d="M614.314 300.927C617.136 302.058 620.235 302.002 623.022 300.77C625.809 299.539 628.088 297.218 629.424 294.25C630.761 291.281 631.063 287.874 630.271 284.676C629.479 281.478 627.65 278.714 625.132 276.91L623.698 282.242L622.682 275.558C622.672 275.552 622.661 275.548 622.65 275.545C621.141 274.939 619.539 274.667 617.935 274.746C616.331 274.824 614.756 275.251 613.301 276.002C611.847 276.753 610.54 277.813 609.456 279.122C608.373 280.43 607.533 281.962 606.986 283.629C606.438 285.297 606.193 287.067 606.265 288.838C606.337 290.61 606.725 292.349 607.405 293.955C608.086 295.562 609.046 297.004 610.232 298.201C611.417 299.397 612.804 300.323 614.314 300.927Z" stroke="#3F3D56" strokeMiterlimit="10"/>
        <path d="M601.007 325.765C601.492 328.814 602.912 331.586 605.028 333.616C607.144 335.646 609.827 336.811 612.628 336.916C615.429 337.02 618.178 336.057 620.413 334.189C622.647 332.32 624.233 329.66 624.902 326.654C625.572 323.647 625.286 320.478 624.091 317.678C622.897 314.877 620.867 312.616 618.342 311.272C615.816 309.928 612.95 309.585 610.223 310.298C607.495 311.012 605.073 312.74 603.363 315.192L608.808 324.246L601.367 319.441C600.79 321.486 600.667 323.655 601.007 325.765Z" fill="#57B894"/>
        <path d="M601.511 323.976C601.996 327.025 603.416 329.797 605.532 331.827C607.647 333.857 610.331 335.022 613.132 335.126C615.933 335.231 618.682 334.268 620.917 332.399C623.151 330.531 624.736 327.871 625.406 324.864C626.076 321.858 625.789 318.689 624.595 315.889C623.4 313.088 621.37 310.826 618.845 309.483C616.32 308.139 613.454 307.796 610.727 308.509C607.999 309.223 605.577 310.951 603.866 313.403L609.312 322.457L601.871 317.652C601.294 319.697 601.171 321.866 601.511 323.976Z" stroke="#3F3D56" strokeMiterlimit="10"/>
        <path d="M5.28973 305.058C6.45865 305.527 7.71029 305.69 8.946 305.534C10.1817 305.378 11.3677 304.907 12.4105 304.159C13.4534 303.41 14.3245 302.404 14.9553 301.22C15.586 300.036 15.9592 298.706 16.0453 297.336C16.1315 295.965 15.9283 294.591 15.4517 293.322C14.9752 292.053 14.2383 290.924 13.2992 290.023C12.36 289.122 11.2443 288.475 10.04 288.132C8.83569 287.788 7.57565 287.759 6.35922 288.045L6.56024 292.611L4.60457 288.698C2.6852 289.694 1.20241 291.491 0.482041 293.695C0.226208 294.466 0.07068 295.274 0.020098 296.094C-0.103344 298.018 0.344308 299.934 1.29522 301.551C2.24612 303.169 3.64846 304.4 5.28973 305.058Z" fill="#57B894"/>
        <path d="M14.967 358.014C14.2767 354.131 17.2681 350.688 20.2229 348.451C23.1778 346.213 26.6303 344.015 27.6385 340.218C29.0875 334.76 24.7715 329.763 21.4122 325.453C18.92 322.254 16.7797 318.741 15.0362 314.986C14.2783 313.511 13.762 311.9 13.5112 310.227C13.2463 307.808 13.9499 305.401 14.6587 303.093C17.0201 295.407 19.5448 287.784 22.2328 280.227" stroke="#3F3D56" strokeMiterlimit="10"/>
        <path d="M5.62079 303.882C6.78971 304.352 8.04135 304.515 9.27706 304.359C10.5128 304.203 11.6988 303.732 12.7416 302.983C13.7844 302.234 14.6555 301.228 15.2863 300.044C15.9171 298.86 16.2902 297.531 16.3764 296.16C16.4625 294.789 16.2593 293.416 15.7828 292.147C15.3062 290.878 14.5693 289.748 13.6302 288.848C12.6911 287.947 11.5754 287.299 10.3711 286.956C9.16674 286.613 7.90671 286.583 6.69028 286.87L6.8913 291.436L4.93563 287.523C3.01626 288.519 1.53346 290.316 0.813095 292.519C0.557265 293.291 0.40174 294.099 0.351155 294.919C0.227705 296.842 0.675354 298.758 1.62626 300.376C2.57717 301.993 3.97952 303.224 5.62079 303.882Z" stroke="#3F3D56" strokeMiterlimit="10"/>
        <path d="M13.9855 278.65C14.4442 277.252 15.2141 276.004 16.2243 275.021C17.2345 274.038 18.4524 273.351 19.7658 273.025L20.4271 277.365L21.653 272.807C23.3551 272.834 25.0052 273.459 26.365 274.59C27.7247 275.722 28.7238 277.301 29.2181 279.1C29.7123 280.899 29.676 282.826 29.1145 284.601C28.553 286.376 27.4953 287.908 26.0941 288.976C24.6929 290.044 23.0207 290.592 21.319 290.542C19.6173 290.491 17.9741 289.845 16.6267 288.696C15.2792 287.547 14.2972 285.954 13.8225 284.149C13.3477 282.343 13.4048 280.417 13.9855 278.65Z" fill="#57B894"/>
        <path d="M14.4259 277.081C14.8846 275.683 15.6546 274.435 16.6647 273.452C17.6749 272.469 18.8928 271.783 20.2062 271.456L20.8675 275.797L22.0934 271.238C23.7955 271.266 25.4456 271.891 26.8054 273.022C28.1652 274.153 29.1643 275.733 29.6585 277.532C30.1527 279.331 30.1164 281.257 29.5549 283.032C28.9934 284.808 27.9357 286.34 26.5345 287.407C25.1333 288.475 23.4612 289.024 21.7595 288.973C20.0577 288.923 18.4145 288.277 17.0671 287.127C15.7196 285.978 14.7376 284.386 14.2629 282.58C13.7882 280.775 13.8453 278.849 14.4259 277.081Z" stroke="#3F3D56" strokeMiterlimit="10"/>
        <path d="M20.0019 325.541C21.8557 326.283 23.8921 326.247 25.723 325.438C27.5539 324.629 29.0512 323.104 29.9296 321.153C30.8079 319.203 31.0059 316.964 30.4858 314.863C29.9657 312.762 28.7638 310.946 27.1093 309.761L26.1673 313.264L25.4993 308.872C25.4927 308.869 25.4859 308.866 25.4789 308.864C24.4873 308.466 23.4344 308.287 22.3804 308.338C21.3263 308.39 20.2917 308.67 19.3358 309.163C18.3798 309.657 17.5213 310.353 16.8091 311.213C16.097 312.073 15.5453 313.08 15.1855 314.175C14.8257 315.271 14.6648 316.434 14.7122 317.598C14.7595 318.762 15.0141 319.905 15.4614 320.96C15.9087 322.016 16.54 322.964 17.319 323.75C18.0981 324.535 19.0098 325.144 20.0019 325.541Z" fill="#57B894"/>
        <path d="M20.3847 323.952C22.2385 324.695 24.2749 324.658 26.1058 323.849C27.9367 323.04 29.434 321.515 30.3124 319.565C31.1907 317.615 31.3887 315.376 30.8686 313.275C30.3485 311.174 29.1466 309.358 27.4921 308.172L26.5501 311.676L25.8822 307.284C25.8756 307.28 25.8687 307.278 25.8617 307.275C24.8704 306.878 23.8179 306.7 22.7644 306.753C21.7108 306.805 20.6769 307.085 19.7216 307.579C17.7923 308.575 16.3004 310.377 15.5741 312.589C14.8478 314.8 14.9466 317.24 15.8488 319.371C16.751 321.502 18.3826 323.15 20.3847 323.952Z" stroke="#3F3D56" strokeMiterlimit="10"/>
        <path d="M11.6419 340.272C11.9607 342.275 12.8933 344.097 14.2835 345.43C15.6737 346.764 17.4369 347.53 19.2773 347.598C21.1177 347.667 22.9235 347.034 24.3918 345.806C25.86 344.579 26.9015 342.831 27.3416 340.856C27.7816 338.881 27.5934 336.799 26.8086 334.959C26.0237 333.119 24.6899 331.633 23.0309 330.75C21.3719 329.867 19.4886 329.641 17.6967 330.11C15.9049 330.579 14.3135 331.715 13.1895 333.326L16.7676 339.274L11.8786 336.117C11.4995 337.461 11.4183 338.886 11.6419 340.272Z" fill="#57B894"/>
        <path d="M11.972 339.095C12.2907 341.099 13.2234 342.92 14.6136 344.254C16.0038 345.588 17.7669 346.353 19.6074 346.422C21.4478 346.49 23.2536 345.858 24.7218 344.63C26.1901 343.402 27.2316 341.654 27.6716 339.679C28.1117 337.704 27.9235 335.622 27.1386 333.782C26.3538 331.942 25.02 330.456 23.361 329.573C21.702 328.691 19.8186 328.465 18.0268 328.934C16.2349 329.403 14.6436 330.538 13.5196 332.149L17.0976 338.098L12.2086 334.94C11.8296 336.284 11.7484 337.709 11.972 339.095Z" stroke="#3F3D56" strokeMiterlimit="10"/>
        <path d="M282.464 221.287C287.978 221.287 292.448 214.897 292.448 207.015C292.448 199.133 287.978 192.744 282.464 192.744C276.95 192.744 272.479 199.133 272.479 207.015C272.479 214.897 276.95 221.287 282.464 221.287Z" fill="#2F2E41"/>
        <path d="M336.79 221.611C342.304 221.611 346.774 215.222 346.774 207.34C346.774 199.458 342.304 193.068 336.79 193.068C331.276 193.068 326.806 199.458 326.806 207.34C326.806 215.222 331.276 221.611 336.79 221.611Z" fill="#2F2E41"/>
        <path d="M329.155 249.506C329.155 255.954 321.003 261.183 310.949 261.183C300.894 261.183 292.742 255.954 292.742 249.506C292.742 243.921 298.856 239.25 307.026 238.108C308.326 237.919 309.637 237.826 310.949 237.829C313.147 237.822 315.339 238.083 317.485 238.608C324.31 240.288 329.155 244.537 329.155 249.506Z" fill="#2F2E41"/>
        <path d="M284.813 218.044C290.328 218.044 294.798 211.654 294.798 203.772C294.798 195.89 290.328 189.5 284.813 189.5C279.299 189.5 274.829 195.89 274.829 203.772C274.829 211.654 279.299 218.044 284.813 218.044Z" stroke="#3F3D56" strokeMiterlimit="10"/>
        <path d="M339.139 218.368C344.653 218.368 349.123 211.978 349.123 204.096C349.123 196.214 344.653 189.824 339.139 189.824C333.624 189.824 329.154 196.214 329.154 204.096C329.154 211.978 333.624 218.368 339.139 218.368Z" stroke="#3F3D56" strokeMiterlimit="10"/>
        <path d="M313.297 257.941C323.353 257.941 331.504 252.713 331.504 246.264C331.504 239.815 323.353 234.587 313.297 234.587C303.242 234.587 295.091 239.815 295.091 246.264C295.091 252.713 303.242 257.941 313.297 257.941Z" stroke="#3F3D56" strokeMiterlimit="10"/>
        <path d="M317.702 239.581C317.707 240.397 317.43 241.184 316.927 241.781C316.689 242.068 316.401 242.297 316.079 242.454C315.758 242.611 315.411 242.693 315.059 242.695H309.186C308.462 242.667 307.778 242.324 307.283 241.74C306.788 241.157 306.522 240.38 306.543 239.581C306.54 238.948 306.708 238.329 307.025 237.803C308.324 237.601 309.636 237.501 310.948 237.505C313.149 237.497 315.343 237.776 317.485 238.335C317.63 238.731 317.704 239.154 317.702 239.581Z" fill="white"/>
        <path d="M284.814 203.124C286.435 203.124 287.75 201.672 287.75 199.88C287.75 198.089 286.435 196.637 284.814 196.637C283.192 196.637 281.877 198.089 281.877 199.88C281.877 201.672 283.192 203.124 284.814 203.124Z" fill="white"/>
        <path d="M339.433 203.124C341.054 203.124 342.369 201.672 342.369 199.88C342.369 198.089 341.054 196.637 339.433 196.637C337.811 196.637 336.496 198.089 336.496 199.88C336.496 201.672 337.811 203.124 339.433 203.124Z" fill="white"/>
        <path opacity="0.3" d="M342.369 233.614C343.991 233.614 345.306 232.161 345.306 230.37C345.306 228.579 343.991 227.127 342.369 227.127C340.747 227.127 339.433 228.579 339.433 230.37C339.433 232.161 340.747 233.614 342.369 233.614Z" fill="#E7D040"/>
        <path opacity="0.3" d="M270.718 233.614C272.34 233.614 273.654 232.161 273.654 230.37C273.654 228.579 272.34 227.127 270.718 227.127C269.096 227.127 267.781 228.579 267.781 230.37C267.781 232.161 269.096 233.614 270.718 233.614Z" fill="#E7D040"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="631" height="379" fill="white" />
        </clipPath>
      </defs>
    </svg>
    </>
   );
}
 
export default NotFoundSvg;