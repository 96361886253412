import { useCallback, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { getAllBranches } from "../../redux/branches/branchSlice";
import Select, { components } from "react-select";
import { extractIp } from "../../redux/utils";
import { getReportSummary } from "../../redux/reports/reportsSlice";
import RefreshAllSummaries from "../common/refreshAll";
import { useTranslation } from "react-i18next";

const NavigationIndicator = () => {
  const { t } = useTranslation();
  const branchIp = useParams().id;
  const auth = useAuth().auth();
  const dispatch = useDispatch();
  const { success, branches } = useSelector((state) => state.branches);
  const { reportSummary } = useSelector((state) => state.reports);
  const [currentBranch, setCurrentBranch] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [routesAfterBranch, setRoutesAfterBranch] = useState([]);

  const CustomNames = {
    reports: t("nav.reports"),
    compare: t("nav.compare"),
    activeAdisyon: t("nav.tickets"),
    priceList: t("nav.price_list"),
    userActions: t("nav.user"),
    Profile: t("nav.profile"),
    workPeriod: t("nav.workPeriod"),
    automationCommand: t("nav.command"),
    restartMessagingServer: t("nav.server"),
    actionsPage: t("nav.actions"),
  };

  const translations = {
    conn: t("nav.connecting"),
  };

  const formatBranches = (data) => {
    return data.map((branch) => {
      return {
        label: branch.name,
        value: extractIp(branch.ipAddress),
        ...branch,
      };
    });
  };

  const findBranch = useCallback(() => {
    for (let i = 0; i < branches.length; i++) {
      if (extractIp(branches[i].ipAddress) === branchIp) {
        return {
          label: branches[i].name,
          value: extractIp(branches[i].ipAddress),
        };
      }
    }
  }, [branchIp, branches]);

  useEffect(() => {
    dispatch(getAllBranches(auth.user_id));
  }, [auth.user_id, dispatch]);

  useEffect(() => {
    if (success) {
      if (branchIp) {
        setCurrentBranch(findBranch());
      } else {
        setCurrentBranch({
          label: branches[0].name,
          value: extractIp(branches[0].ipAddress),
        });
      }
    }
  }, [success, branchIp, findBranch, branches]);

  const handleBranchChange = (selected) => {
    const { pathname } = location;
    const newUrl = pathname.replace(`/${branchIp}`, `/${selected.value}`);

    const formattedUrl = newUrl.includes("/reports")
      ? `/info/${selected.value}/reports`
      : newUrl;

    setCurrentBranch(selected);
    navigate(formattedUrl, { replace: true });
  };

  useEffect(() => {
    const routeSegments = location.pathname
      .split(`/${branchIp}`)[1]
      ?.split("/")
      .filter(Boolean)
      .map((route) => decodeURIComponent(route));
    setRoutesAfterBranch(routeSegments || []);
  }, [location.pathname, branchIp]);

  useEffect(() => {
    if (success && branches.length > 0) {
      branches.forEach((branch) => {
        dispatch(getReportSummary({ branchUrl: branch.ipAddress }));
      });
    }
  }, [success, branches, dispatch]);

  /* useEffect(() => {
    if (reportSummary) {
      branches.map((branch) => {
        if (reportSummary?[branch.ipAdress]?.success) {

        }
      })
    }
  }) */

  return (
    <section className="overflow-visible flex items-center gap-2">
      <RefreshAllSummaries />

      <div className="py-2 text-sm text-[var(--tx-3)] font-normal w-max">
        {success && (
          <div className="flex items-center">
            {routesAfterBranch.length > 0 ? (
              CustomNames[routesAfterBranch[0]] && (
                <Selector
                  value={currentBranch}
                  options={formatBranches(branches)}
                  onChange={(selected) => handleBranchChange(selected)}
                  branches={branches}
                  summary={reportSummary}
                  translations={translations}
                />
              )
            ) : (
              <Selector
                value={currentBranch}
                options={formatBranches(branches)}
                onChange={(selected) => handleBranchChange(selected)}
                branches={branches}
                summary={reportSummary}
                translations={translations}
              />
            )}
            {routesAfterBranch.length > 0 ? (
              routesAfterBranch.map((route, index) => {
                return (
                  <span key={index + 10} className="text-[--gr-9] font-bold">
                    {"> "}
                    {index === routesAfterBranch.length - 1 ? (
                      <span
                        key={index}
                        className="text-[--gr-9] font-bold cursor-pointer"
                      >
                        {routesAfterBranch[index - 1] === "reports"
                          ? route.replace(/^PTR-/g, "")
                          : CustomNames[route]}
                      </span>
                    ) : (
                      <Link
                        key={index}
                        className="text-[--gr-9] font-bold cursor-pointer hover:underline"
                        to={route}
                      >
                        {CustomNames[route]}
                      </Link>
                    )}
                  </span>
                );
              })
            ) : (
              <span key={0} className="text-[--gr-9] font-bold">
                &gt;{" "}
                <span
                  key={1}
                  className="text-[--gr-9] font-bold cursor-pointer"
                >
                  {t("nav.reports")}
                </span>
              </span>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

const Selector = ({
  value,
  options,
  onChange,
  summary,
  className,
  isDisabled,
  translations,
}) => {
  const menuPortalTargetRef = useRef(null);

  useEffect(() => {
    if (menuPortalTargetRef.current) {
      menuPortalTargetRef.current.style.zIndex = 9999;
    }
  }, []);

  const customOption = ({ data, ...props }) => (
    <components.Option
      {...props}
      className={`${
        !summary?.[data.ipAddress]?.success && "pointer-events-none"
      }`}
    >
      <div className={`flex justify-between gap-2 px-[12px] py-[8px]`}>
        <span>{data.label}</span>
        {summary?.[data.ipAddress]?.loading ? (
          <span className="text-yellow-500">{translations.conn}</span>
        ) : summary?.[data.ipAddress]?.success ? (
          <span className="text-green-500">Online</span>
        ) : (
          <span className="text-red-500">Offline</span>
        )}
      </div>
    </components.Option>
  );

  return (
    <>
      <div ref={menuPortalTargetRef}></div>
      <Select
        value={value}
        onChange={onChange}
        options={options} //WHY THE ONLINE OFFLINE IS ONLY WORKING ONLY IF I SET THIS TO branhes ?
        components={{ Option: customOption }}
        className={`${className} text-[--tx-7]`}
        styles={{
          control: (provided, state) => ({
            ...provided,
            boxShadow: "none",
            border: "var(--gr-3)",
            backgroundColor: "var(--btn-txt)",
            color: "var(--gr-7)",
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor:
              state?.label === value?.label ? "var(--gr-4)" : "var(--btn-txt)",
            color: "var(--gr-7)",
            padding: 0,
            cursor: "pointer",
          }),
          singleValue: (provided, state) => ({
            ...provided,
            color: "var(--gr-7)",
          }),
          menuList: (provided) => ({
            ...provided,
            background: "var(--btn-txt)",
            minWidth: "max-content",
            width: "100%",
          }),
          menu: (provided) => ({
            ...provided,
            width: "max-content",
            background: "var(--btn-txt)",
            zIndex: "10000",
          }),
        }}
        /* menuIsOpen={true} */
        isDisabled={isDisabled}
        menuPortalTarget={menuPortalTargetRef.current} // Pass the menu portal target
        isSearchable={false}
      />
    </>
  );
};

export default NavigationIndicator;
