const SummarySkeleton = () => {
  return (
    <div className="w-full bg-[--gr-0] animate-pulse">
      {[1].map((cell) => (
        <div key={cell} className="grid grid-cols-2 gap-4">
          <div className="h-[30px] w-full font-medium text-[--gr-9] whitespace-nowrap text-left bg-[--gr-1] rounded-sm" />
          <div className="h-[30px] w-full font-medium text-[--gr-9] whitespace-nowrap text-left bg-[--gr-1] rounded-sm" />
        </div>
      ))}
    </div>
  );
};

export default SummarySkeleton;
