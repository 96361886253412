import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getWorkPeriodActions,
  resetStartEndWorkPeriod,
  startEndWorkPeriod,
} from "../redux/otherActions/workPeriodSlice";
import TableSkeleton from "../components/common/tableSkeleton";
import ErrorDisplay from "../components/common/error";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { extractIp } from "../redux/utils";
import { customToast } from "../components/branches/branchCard";

const WorkPeriod = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const branchUrl = useParams().id;
  const {
    loading: getLoading,
    success: getSuccess,
    error: getError,
    data,
  } = useSelector((state) => state.workPeriod.getWorkPeriod);
  const { success, error } = useSelector(
    (state) => state.workPeriod.startWorkPeriod
  );
  const [workStat, setWorkStat] = useState("");

  useEffect(() => {
    if (success) {
      toast.success(t("actions.workMsg", { workStat: workStat }), {
        position: "top-right",
      });
      dispatch(resetStartEndWorkPeriod());
    } else if (error) {
      toast.error(`${error}`, { position: "top-right" });
      dispatch(resetStartEndWorkPeriod());
    }
  }, [success, error, dispatch, workStat, t]);

  useEffect(() => {
    if (data?.[branchUrl] === undefined || !data?.[branchUrl]) {
      dispatch(getWorkPeriodActions({ branchUrl }));
    }
  }, [dispatch, data, branchUrl]);

  const handleWorkPeriod = (truthVal) => {
    //console.log(truthVal)
    if (data[branchUrl].unPaidTickets.length > 0) {
      toast.error(t("actions.!workMsg"), { position: "top-right" });
      return;
    }
    truthVal === true
      ? setWorkStat(t("actions.started"))
      : setWorkStat(t("actions.ended"));
    dispatch(startEndWorkPeriod({ branchUrl, truthVal }));
  };

  ///************* BRANCH STATUS AND LICENCE CONTROL *****************///
  const branchIp = useParams().id;
  const navigate = useNavigate();
  const { branches: allBranches } = useSelector((state) => state.branches);
  const allSummaryData = useSelector((state) => state.reports.reportSummary);
  useEffect(() => {
    const thisBranch = [];
    if (allBranches.length > 0) {
      const pushBranch = allBranches.filter(
        (branch) => extractIp(branch.ipAddress) === String(branchIp)
      );
      thisBranch.push(pushBranch[0]);
    }

    if (!allSummaryData) return;
    if (thisBranch.length > 0 && Object.keys(allSummaryData).length > 0) {
      const thisBranchIp = thisBranch[0].ipAddress;
      if (!allSummaryData?.[thisBranchIp].loading) {
        if (
          !allSummaryData?.[thisBranchIp].success ||
          thisBranch[0]?.status !== 1
        ) {
          console.log(false);
          navigate("/");
          customToast(`${t("branches.inActive", { branch: "" })}`);
          //console.log(allSummaryData?.[thisBranchIp]);
          //console.log(thisBranch[0]?.status);
        } else {
          console.log(true);
        }
      }
    }
  }, [allBranches, allSummaryData, branchIp, navigate, t]);
  ///************* BRANCH STATUS AND LICENCE CONTROL *****************///

  return (
    <div className={`full_height-footer_ mb-6 sm:mb-4 ${className}`}>
      {getLoading ? (
        <TableSkeleton />
      ) : getSuccess && data && data[branchUrl] !== undefined ? (
        <div>
          <div className="w-full text-left text-[--gr-5]">
            <div className="text-slate-300">
              <div>
                <p className="px-6 py-1 font-medium text-lg max-sm:text-base bg-[--gr-4]">
                  {t("nav.workPeriod")}
                </p>
              </div>
            </div>

            <div className="gap-1 sm:gap-2 flex flex-col mt-2 w-ful max-sm:text-sm text-base text-white">
              <div
                className={`px-6 py-4 font-medium whitespace-nowrap cursor-pointer bg-[--primary] rounded-sm ${
                  data[branchUrl].workPeriodStart === true
                    ? "pointer-events-none"
                    : ""
                }`}
                onClick={() => handleWorkPeriod(true)}
              >
                {t("actions.start")}
              </div>
              <div
                className={`px-6 py-4 font-medium whitespace-nowrap cursor-pointer bg-[--primary] rounded-sm ${
                  data[branchUrl].workPeriodStart === false
                    ? "pointer-events-none"
                    : ""
                }`}
                onClick={() => handleWorkPeriod(false)}
              >
                {t("actions.end")}
              </div>
            </div>
          </div>
        </div>
      ) : (
        getError && (
          <ErrorDisplay
            error={getError?.message}
            text={"couldn't fetch the commands"}
          />
        )
      )}
    </div>
  );
};

export default WorkPeriod;
