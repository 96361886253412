import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../hooks/useAuth";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { logout, resetLogoutState } from "../../redux/auth/authSlice";
import { useTranslation } from "react-i18next";

const LogoutButton = ({ className }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clearAuth = useAuth().clearAuth;

  const [loadingToastId, setLoadingToastId] = useState(null);
  const authReducer = useSelector((state) => state.auth);
  const { loading, error, success } = authReducer.logout;

  useEffect(() => {
    if (loading && loadingToastId === null) {
      const toastId = toast.loading(t("nav.logoutM"), {
        position: "top-right",
      });
      setLoadingToastId(toastId);
    } else if (!loading && loadingToastId !== null) {
      //console.log(loading, loadingToastId, "line 34")
      toast.dismiss(loadingToastId);
      setLoadingToastId(null);
    }
  }, [loading, loadingToastId, t]);

  useEffect(() => {
    if (success) {
      clearAuth();
      navigate("/login");
      dispatch(resetLogoutState());
    } else if (error) {
      toast.error(error, { position: "top-right", duration: 2000 });
      dispatch(resetLogoutState());
    }
  }, [success, error, navigate, clearAuth, dispatch]);

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className={className}>
      <div onClick={handleLogout}>
        <p
          className={`text-[--gr-7] hover:bg-[--gr-4] hover:text-white px-3 py-2`}
        >
          {t("nav.logout")}{" "}
        </p>
      </div>
    </div>
  );
};

export default LogoutButton;
