import "../common/customTable.css";
import CustomInput from "../common/customInput";
import { createRef } from "react";

const OrdersTagsTable = ({
  tag,
  tagIndex,
  inputRefs,
  editedPrices,
  onPriceChange,
  handleKeyPress,
}) => {
  return (
    <div className="border-[.5px] border-solid border-[--gr-3] overflow-x-auto">
      <table className="w-full text-sm text-left rtl:text-right text-[--gr-5]">
        <thead>
          <tr>
            <th
              className="text-[--tx-1] bg-[--gr-1] font-bold px-6 py-2 whitespace-nowrap"
              colSpan="3"
            >
              <div className="block">{tag.name}</div>
              <span className="text-xs text-[--tx-6] hidden">{`Tag Id: ${tag.id}`}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {tag.orderTags &&
            tag.orderTags.map((orderTag, index) => {
              inputRefs.current[tagIndex][index] = createRef();
              return (
                <tr
                  key={orderTag.id}
                  className="odd:bg-[--btn-txt] even:bg-[--gr--0] border-b"
                >
                  <th
                    key={0}
                    scope="row"
                    className="px-6 py-2 font-medium text-[--gr-9] whitespace-nowrap  flex-1"
                  >
                    {orderTag.name}
                  </th>
                  <th
                    scope="row"
                    key={1}
                    className="px-6 py-4 font-semibold text-[--gr-9] whitespace-nowrap  flex-1"
                  >
                    {orderTag.price}
                  </th>
                  <th
                    scope="row"
                    key={3}
                    className="px-6 py-2 font-medium text-[--gr-9] whitespace-nowrap text-end"
                  >
                    <CustomInput
                      className=" max-w-40 "
                      type="number"
                      reference={inputRefs.current[tagIndex][index]}
                      value={editedPrices[orderTag.id]?.newPrice || ""}
                      onChange={(e) => onPriceChange(e, orderTag)}
                      onKeyDown={(e) =>
                        handleKeyPress(e, tagIndex, index, tag.orderTags.length)
                      }
                    />
                  </th>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default OrdersTagsTable;
