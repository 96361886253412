import React, { useState } from "react";
import LanguageSelector from "../lang/langselector";
import ThemeToggle from "../common/themeToggle";
import { useTranslation } from "react-i18next";

// Icons
import { IoClose } from "react-icons/io5";
import { BsTelephoneFill } from "react-icons/bs";
import { FaWhatsapp } from "react-icons/fa6";
import { TfiEmail } from "react-icons/tfi";
import { MdForum } from "react-icons/md";
import { TbBrandYoutube } from "react-icons/tb";
import { LuInstagram } from "react-icons/lu";

const LoginNav = ({ bgcolor }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const text = [
    {
      li1: t("nav.li3"),
      txt1: t("nav.info1.txt1"),
      txt2: t("nav.info1.txt2"),
      tx3: t("nav.info1.txt3"),
    },
    {
      li1: t("nav.li2"),
      txt1: t("nav.info2.txt1"),
      txt2: t("nav.info1.txt2"),
      tx3: t("nav.info1.txt3"),
    },
  ];

  const handleActions = (num) => {
    setMenuOpen(false);
    setIsOpen(num);
  };

  return (
    <section
      className={`${bgcolor ? bgcolor : "bg-[var(--nav-bg-1)] relative"}`}
    >
      <InfoBox isOpen={isOpen} setIsOpen={setIsOpen} text={text} />
      <div className="w-full flex justify-between h-16 items-center">
        <div className="flex h-full align-middle gap-4">
          <MenuIcon menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
          <h1 className="text-2xl font-[conthrax] text-[--tx-5] flex items-center">
            Liwa<span className="span font-[conthrax] text-[--tx-6]">Soft</span>
          </h1>

          <ul
            className={`${
              !menuOpen && "invisible sm:visible"
            } transition-all rounded-sm text-[--tx-5] text-sm md:text-base gap-2 sm:gap-4 fixed top-[4.2rem] left-0 flex-col p-2 flex sm:flex-row sm:static sm:items-center`}
          >
            <li
              className={`cursor-pointer ${isOpen === 3 && "text-[--primary]"}`}
              onClick={() => handleActions(3)}
            >
              {t("nav.li1")}
            </li>
            <li
              className={`cursor-pointer ${isOpen === 2 && "text-[--primary]"}`}
              onClick={() => handleActions(2)}
            >
              {t("nav.li2")}
            </li>
            <li
              className={`cursor-pointer ${isOpen === 1 && "text-[--primary]"}`}
              onClick={() => handleActions(1)}
            >
              {t("nav.li3")}
            </li>
          </ul>
        </div>
        <div className="flex gap-4 items-center">
          <ThemeToggle />
          <LanguageSelector />
        </div>
      </div>
    </section>
  );
};

const InfoBox = ({ isOpen, setIsOpen, text }) => {
  return (
    <>
      <div
        className={`fixed inset-0 flex justify-center items-center transition-colors px-[2%] z-50 ${
          isOpen === 1 ? "visible bg-black/20" : "invisible"
        }`}
      >
        <div
          className={`bg-[--btn-txt] w-full max-w-[45rem] rounded-xl shadow px-6 py-2 pb-6 transition-all max-h-[80dvh] border border-solid border-gray-700 ${
            isOpen ? "scale-100 opacity-100" : "scale-125 opacity-0"
          }`}
        >
          <span className="flex justify-end text-2xl text-[--gr-6]">
            <IoClose
              onClick={() => setIsOpen(0)}
              className="rounded-full p-1 text-4xl cursor-pointer hover:bg-[--gr-0] hover:text-[--gr-6]"
            />
          </span>

          <div className="text-[--gr-5] font-bold text-xl text-center mb-6">
            {text[0].li1}
          </div>

          <div>
            <p className="text-[--gr-5]">{text[0].txt1}</p>
            <p className="text-[--gr-5]">{text[0].txt2}</p>
            <p className="text-[--primary] mt-2">
              <a
                href="https://youtu.be/J8X1Ayj1qa4?si=4e7SfVNK2WeDTV0m"
                target="_blank"
                rel="noopener noreferrer"
              >
                {text[0].tx3}
              </a>
            </p>
          </div>
        </div>
      </div>

      <div
        className={`fixed inset-0 flex justify-center items-center transition-colors p-[2%] z-50 ${
          isOpen === 2 ? "visible bg-black/20" : "invisible"
        }`}
      >
        <div
          className={`bg-[--btn-txt] w-full max-w-[35rem] rounded-xl shadow p-4 pb-6 transition-all max-h-[80dvh] border border-solid border-gray-700 ${
            isOpen ? "scale-100 opacity-100" : "scale-125 opacity-0"
          }`}
        >
          <span className="flex justify-end text-2xl text-[--gr-6]">
            <IoClose
              onClick={() => setIsOpen(0)}
              className="rounded-full p-1 text-4xl cursor-pointer hover:bg-[--gr-0] hover:text-[--gr-6]"
            />
          </span>

          <div className="text-[--gr-5] font-bold text-lg text-center">
            {text[1].li1}
          </div>

          <div className="w-full flex flex-col items-center mt-4">
            <div className="max-w-max flex flex-col gap-2">
              <a href="tel:90 850 840 78 07" rel="noopener noreferrer">
                <div className="w-full flex items-center gap-8 border-0 border-b border-solid border-[--primary] pb-2">
                  <span className="text-[--primary] text-4xl border-0 border-solid border-[--primary] rounded-full">
                    {/* <BsTelephoneFill /> */}
                    <p>📱</p>
                  </span>
                  <p className="text-[--gr-9]">Bizi arayın</p>
                </div>
              </a>

              <a href="http://wa.me/908508407807" rel="noopener noreferrer">
                <div className="w-full flex items-center gap-8 border-0 border-b border-solid border-[--primary] pb-2">
                  <span className="text-[--primary] text-4xl">
                    <FaWhatsapp />
                  </span>
                  <p className="text-[--gr-9]">WhatsApp</p>
                </div>
              </a>

              <a href="mailto:support@liwasoft.com" rel="noopener noreferrer">
                <div className="w-full flex items-center gap-8 border-0 border-b border-solid border-[--primary] pb-2">
                  <span className="text-[--primary] text-[1.8rem]">
                    <TfiEmail />
                  </span>
                  <p className="text-[--gr-9]">support@liwasoft.com</p>
                </div>
              </a>

              <a href="http://forum.liwasoft.com/" rel="noopener noreferrer">
                <div className="w-full flex items-center gap-8 border-0 border-b border-solid border-[--primary] pb-2">
                  <span className="text-[--primary] text-[1.9rem]">
                    <MdForum />
                  </span>
                  <p className="text-[--gr-9]">forum.liwasoft.com</p>
                </div>
              </a>

              <a
                href="https://www.youtube.com/@liwasoft/videos"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="w-full flex items-center gap-8 border-0 border-b border-solid border-[--primary] pb-2">
                  <span className="text-[--primary] text-3xl">
                    <TbBrandYoutube />
                  </span>
                  <p className="text-[--gr-9]">Youtube</p>
                </div>
              </a>

              <a
                href="https://www.instagram.com/liwasoft/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="w-full flex items-center gap-8 border-0 border-b border-solid border-[--primary] pb-2">
                  <span className="text-[--primary] text-3xl">
                    <LuInstagram />
                  </span>
                  <p className="text-[--gr-9]">Instagram</p>
                </div>
              </a>

              <div className="w-full text-center mt-3 text-sm font-light text-blue-700">
                <a
                  href="https://liwasoft.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  liwasoft.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const MenuIcon = ({ menuOpen, setMenuOpen }) => {
  return (
    <div
      className="sm:hidden w-6 h-4 flex flex-col self-center relative cursor-pointer"
      onClick={() => setMenuOpen(!menuOpen)}
    >
      <span
        className={`transition-transform rounded w-6 h-[.2rem] bg-[--gr-9] block absolute top-1/2 left-1/2 -translate-x-1/2  
      ${menuOpen ? "translate-y-[-50%] -rotate-45" : "-translate-y-2"}`}
      ></span>
      <span
        className={`transition-transform rounded h-[.2rem] bg-[--gr-9] block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 
      ${menuOpen ? "w-0" : "w-6"}`}
      ></span>
      <span
        className={`transition-transform rounded w-6 h-[.2rem] bg-[--gr-9] block absolute top-1/2 left-1/2 -translate-x-1/2 
      ${menuOpen ? "translate-y-[-50%] rotate-45" : "translate-y-[.3rem]"}`}
      ></span>
    </div>
  );
};
export default LoginNav;
