//import { useTranslation } from "react-i18next";

export default function useLang() {
  const setLang = (lang) => {
    localStorage.setItem("lang", JSON.stringify(lang));
  };

  const getLang = () => {
    return JSON.parse(localStorage.getItem("lang"));
  };

  return {
    setLang,
    getLang,
  };
}
