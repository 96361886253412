import { useTranslation } from "react-i18next";
import { formatOrders } from "../../pages/ticketDetails";
import OrdersTable from "../tickets/odersTable";
import TagsTable from "../tickets/tagsTable";
import TotalTable from "../tickets/totalTable";
import { IoClose } from "react-icons/io5";
import TableSkeleton from "../common/tableSkeleton";
import React, { useRef, useState } from "react";
import { extractIp, FormatCalendar, htmlToPng } from "../../redux/utils";
import { FaDownload } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const TicketPopUp = ({ open, setOpen, ticket, loading }) => {
  const { branches } = useSelector((state) => state.branches);
  const branchUrl = useParams().id;
  const { t } = useTranslation();
  const downloadPng = useRef();
  const [isConverting, setIsConverting] = useState(false);

  const currentBranch =
    branches.length > 0
      ? branches.find((branch) => extractIp(branch.ipAddress) === branchUrl)
          ?.name
      : branchUrl;

  const handlePngDownload = async (e) => {
    const ticketID = e.currentTarget.dataset.id;
    const ticketName = t("ticketDetails.ticket");
    setIsConverting(true);

    htmlToPng(
      downloadPng,
      ticketName,
      ticketID,
      currentBranch,
      t("png.downloaded"),
      t("png.converting"),
      t("png.error1"),
      setIsConverting
    );
  };

  return (
    <div
      className={`
      fixed inset-0 shadow-md flex justify-center items-center transition-colors px-[2%] z-[9999]
      ${open ? "visible bg-black/20" : "hidden"}
    `}
    >
      <div
        className={`w-full max-w-[35rem] ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <div className="bg-[--bg-3] w-full rounded-xl shadow py-4 px-2 transition-all border border-solid border-[--gr-4]">
          <div className="w-full flex justify-end mb-2">
            <IoClose
              onClick={() => setOpen(false)}
              className="text-4xl p-1 text-white bg-[--primary] hover:bg-[--primary-hover] rounded-full cursor-pointer"
            />
          </div>

          <div className="max-h-[85dvh] overflow-y-auto px-2">
            {ticket ? (
              <div ref={downloadPng}>
                <div className="flex pb-1">
                  <div className="w-full">
                    <h1 className="mb-2 block text-lg text-[--tx-1] font-bold Poppins text-center">
                      {ticket.entities.map((entity) => (
                        <React.Fragment key={entity.id}>
                          {entity.name}{" "}
                        </React.Fragment>
                      ))}
                    </h1>
                  </div>
                  <button
                    disabled={isConverting}
                    className="text-white text-2xl bg-[--primary] p-2 rounded-lg cursor-pointer"
                    onClick={handlePngDownload}
                    data-id={`${ticket.id}`}
                  >
                    <FaDownload />
                  </button>
                </div>

                <div>
                  <div className="p-2 pb-0 text-[.7rem] text-[--tx-1] bg-[--gr-1] flex justify-between mb-[-2px]">
                    <p>ID: {ticket.id}</p>
                    <p>{FormatCalendar(ticket.date, "/", true, false)}</p>
                  </div>
                </div>
                {formatOrders(ticket.orders).map((order) => (
                  <div key={order.id} className="mb-1">
                    <OrdersTable orders={order} className="border-t-0" />
                  </div>
                ))}

                <div className="border border-solid border-[--gr-3] bg-[--gr--0] pt-1">
                  <div className="bg-[--components-color] rounded-lg border-[0px] border-solid border-[--gr-3] flex-1 mb-2">
                    <h1 className="block text-sm font-normal text-[--tx-1] Poppins text-center">
                      {t("ticketDetails.ticketTags")}
                    </h1>
                    <TagsTable tags={ticket.tags} className={"px-2"} />
                  </div>

                  <div>
                    <TotalTable ticket={ticket} />
                  </div>
                </div>
              </div>
            ) : !loading ? (
              <TableSkeleton />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketPopUp;
