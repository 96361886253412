import * as XLSX from "xlsx";
import { FormatCalendar } from "../redux/utils";

export function arrayToExcelMerged(data1, data2, translations, branchName) {
  const formattedName1 = data1.name
    .replace(/^PTR-|[^a-zA-Z\sıIğĞüÜşŞiİöÖçÇ]/g, "")
    .trim();
  const formattedName2 = data2.name
    .replace(/^PTR-|[^a-zA-Z\sıIğĞüÜşŞiİöÖçÇ]/g, "")
    .trim();
  const filename = `${branchName.branchLeft}-${formattedName1}-${branchName.branchRight}-${formattedName2}`;
  // Create an array to hold all rows including headers
  const allRows = [];

  // Function to add header information
  function addHeader() {
    allRows.push([
      translations.branchName,
      translations.name,
      translations.startD,
      translations.endD,
      translations.header,
    ]);
  }

  // Add header for first dataset
  addHeader();
  allRows.push([
    branchName.branchLeft,
    formattedName1,
    FormatCalendar(data1.startDate, ".", true, false),
    FormatCalendar(data1.endDate, ".", true, false),
    data1.header,
  ]);

  // Loop through each table in the first dataset
  data1.tables.forEach((table) => {
    // Add the table name as a row
    allRows.push([`${translations.tableName}:`, table.name]);

    // Add column headers as a row
    const columnHeaders = table.columns.map((column) => column.header || "");
    allRows.push(columnHeaders);

    // Add table rows
    table.rows.forEach((row) => {
      const rowData = row.cells.map((cell) => cell || "");
      allRows.push(rowData);
    });
  });

  // Add header for second dataset
  addHeader();
  allRows.push([
    branchName.branchRight,
    formattedName2,
    data2.startDate,
    data2.endDate,
    data2.header,
  ]);

  // Loop through each table in the second dataset
  data2.tables.forEach((table) => {
    // Add the table name as a row
    allRows.push([`${translations.tableName}:`, table.name]);

    // Add column headers as a row
    const columnHeaders = table.columns.map((column) => column.header || "");
    allRows.push(columnHeaders);

    // Add table rows
    table.rows.forEach((row) => {
      const rowData = row.cells.map((cell) => cell || "");
      allRows.push(rowData);
    });
  });

  // Convert the array of rows to worksheet
  const ws = XLSX.utils.aoa_to_sheet(allRows);

  // Create workbook and add worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Write workbook to binary
  const wbout = XLSX.write(wb, { type: "binary" });

  // Create a Blob object and download link
  const url = URL.createObjectURL(
    new Blob([s2ab(wbout)], { type: "application/octet-stream" })
  );
  const a = document.createElement("a");
  a.href = url;
  a.download = `${filename}.xlsx`;
  a.click();

  // Clean up
  setTimeout(() => URL.revokeObjectURL(url), 100);
}

// Function to convert string to ArrayBuffer
function s2ab(s) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}
