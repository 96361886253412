import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { formatToUrl } from "../utils";
import { axiosPrivate } from "../api";

const api = axiosPrivate();
const port = process.env.REACT_APP_BACKEND_PORT;

const initialState = {
  startWorkPeriod: {
    loading: false,
    success: false,
    error: null,
  },
  getWorkPeriod: {
    loading: false,
    success: false,
    error: null,
    data: null,
  },
};

const workPeriodSlice = createSlice({
  name: "work periods",
  initialState: initialState,
  reducers: {
    resetGetWorkPeriodState: (state) => {
      state.getWorkPeriod.loading = false;
      state.getWorkPeriod.error = null;
      state.getWorkPeriod.success = false;
    },
    resetStartEndWorkPeriod: (state) => {
      state.startWorkPeriod.loading = false;
      state.startWorkPeriod.error = null;
      state.startWorkPeriod.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startEndWorkPeriod.pending, (state) => {
        state.startWorkPeriod.loading = true;
        state.startWorkPeriod.success = false;
        state.startWorkPeriod.error = null;
      })
      .addCase(startEndWorkPeriod.fulfilled, (state) => {
        state.startWorkPeriod.loading = false;
        state.startWorkPeriod.success = true;
        state.startWorkPeriod.error = null;
      })
      .addCase(startEndWorkPeriod.rejected, (state, action) => {
        state.startWorkPeriod.loading = false;
        state.startWorkPeriod.success = false;
        state.startWorkPeriod.error = action.error.message;
      })
      .addCase(getWorkPeriodActions.pending, (state) => {
        state.getWorkPeriod.success = false;
        state.getWorkPeriod.loading = true;
        state.getWorkPeriod.error = null;
      })
      .addCase(getWorkPeriodActions.fulfilled, (state, action) => {
        state.getWorkPeriod.loading = false;
        state.getWorkPeriod.success = true;
        state.getWorkPeriod.error = null;
        state.getWorkPeriod.data = {
          ...state.getWorkPeriod.data,
          [action.payload.branchUrl]: action.payload.data,
        };
      })
      .addCase(getWorkPeriodActions.rejected, (state, action) => {
        state.getWorkPeriod.loading = false;
        state.getWorkPeriod.success = false;
        state.getWorkPeriod.error = action.error;
      });
  },
});

export const startEndWorkPeriod = createAsyncThunk(
  "/start_end_Work",
  async ({ branchUrl, truthVal }) => {
    const formattedUrl = formatToUrl(branchUrl);
    //const response = await api.post(
    //  `${formattedUrl}/start_end_work_period`,
    //  truthVal
    //);
    const response = await api.post(`${port}/other/work`, {
      path: formattedUrl,
      truthVal,
    });

    return response.data;
  }
);

export const getWorkPeriodActions = createAsyncThunk(
  "branch/getWorkPeriod",
  async ({ branchUrl }) => {
    const formattedUrl = formatToUrl(branchUrl);
    //const response = await api.get(`${formattedUrl}/get_work_period_unpaid_tickets`);
    const response = await api.get(`${port}/other/work`, {
      params: {
        path: formattedUrl,
      },
    });
    return {
      branchUrl,
      data: response.data,
    };
  }
);

export default workPeriodSlice.reducer;
export const { resetGetWorkPeriodState, resetStartEndWorkPeriod } =
  workPeriodSlice.actions;
