import React from "react";
import LoginForm from "../components/loginForm/loginForm";
import LoginNav from "../components/loginForm/loginNav";
import Footer from "../components/footer/footer";

const Login = () => {
  return (
    <>
      <div className="bg">
        <LoginNav bgcolor={"bg-[--nav-bg-2] fixed top-0 z-50"} />
        <LoginForm />
      </div>
      <Footer />
    </>
  );
};

export default Login;
