import * as XLSX from "xlsx";
import { FormatCalendar } from "../redux/utils";

export function arrayToExcel(data, translations, branchName) {
  const formattedName = data.name
    .replace(/^PTR-|[^a-zA-Z\sıIğĞüÜşŞiİöÖçÇ]/g, "")
    .trim();
  const filename = `${branchName}-${formattedName}`;
  // Create an array to hold all rows including headers
  const allRows = [];

  // Add the first row with endDate, header, name, and startDate
  allRows.push([
    branchName && translations.branchName,
    translations.name,
    translations.startD,
    translations.endD,
    translations.header,
  ]);
  allRows.push([
    branchName,
    formattedName,
    FormatCalendar(data.startDate, ".", true, false),
    FormatCalendar(data.endDate, ".", true, false),
    data.header,
  ]);

  // Loop through each table
  data.tables.forEach((table) => {
    // Add the table name as a row
    allRows.push([{ t: "s", v: table.name }]);

    // Add column headers as a row
    const columnHeaders = table.columns.map((column) => column.header || "");
    allRows.push(columnHeaders);

    // Add table rows
    table.rows.forEach((row) => {
      const rowData = row.cells.map((cell) => cell || "");
      allRows.push(rowData);
    });
  });

  // Convert the array of rows to worksheet
  const ws = XLSX.utils.aoa_to_sheet(allRows);

  // Create workbook and add worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Write workbook to binary
  const wbout = XLSX.write(wb, { type: "binary" });

  // Create a Blob object and download link
  const url = URL.createObjectURL(
    new Blob([s2ab(wbout)], { type: "application/octet-stream" })
  );
  const a = document.createElement("a");
  a.href = url;
  a.download = `${filename}.xlsx`;
  a.click();

  // Clean up
  setTimeout(() => URL.revokeObjectURL(url), 100);
}

// Function to convert string to ArrayBuffer
function s2ab(s) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}
