import SummarySkeleton from "./summarySkeleton";
import { useTranslation } from "react-i18next";
import { FormatCalendar, extractIp } from "../../redux/utils";
import { Link } from "react-router-dom";
import { HiRefresh } from "react-icons/hi";
import toast from "react-hot-toast";
import { IoClose } from "react-icons/io5";

const BranchCard = ({ branch, allSummaryData, handleSummaryClick }) => {
  const { t } = useTranslation();

  const formatDate = (date) => {
    const extractedDate = date.match(/\d+/)[0];
    const formalDate = new Date(Number(extractedDate));
    const formattedDate = FormatCalendar(formalDate, ".", false, false);
    return formattedDate;
    //return formattedDate.toLocaleDateString();
  };

  const handleBranchDetails = () => {
    if (!allSummaryData?.[branch.ipAddress]?.success) {
      customToast(`${t("branches.inActive", { branch: "" })}`);
    }
  };

  const data = allSummaryData && allSummaryData[branch.ipAddress]?.data;

  return (
    <div className="bg-[var(--nav-txt)] h-max flex flex-col rounded-[8px] shadow-sm shadow-[var(--sh-1)] relative">
      <div
        className="h-[160px] md:h-[250px] truncate rounded-b cursor-pointer"
        onClick={handleBranchDetails}
      >
        <Link
          to={`/info/${extractIp(branch.ipAddress)}`}
          className={`${
            (!allSummaryData?.[branch.ipAddress]?.success ||
              branch.status !== 1) &&
            "pointer-events-none"
          }`}
        >
          <img
            src={`https://liwapos.com/Image/customersImage/${branch.branch_image}`}
            alt={branch.nameSurname}
            className={`h-full w-full object-cover mb-4 md:mb-0 hover:scale-105 duration-1000 cursor-pointer transition-all ease-in-out`}
          />
        </Link>
      </div>
      <div className="px-2 mt-3 flex flex-col flex-wrap justify-between items-stretch text-[var(--tx-3)] product-info">
        <div className="mb-3">
          <p className="Poppins font-bold product-name text-blue-300">
            {branch.name}
          </p>
          <hr className="bg-blue-300 h-[2px] w-1/2" />
        </div>
        <p className="Poppins mb-1">
          {t("branches.licence")}:
          <span
            className={`Poppins ${
              branch.status === 1 ? "text-green-400" : "text-red-400"
            }`}
          >
            {branch.status === 1 ? " Active" : " Inactive"}
          </span>
        </p>

        <p className="Poppins mb-1">
          {t("branches.endDate")}: {formatDate(branch.endDate)}
        </p>
        <div className="mb-1 w-full">
          {allSummaryData && (
            <div
              className={`w-full Poppins overflow-x-auto py-3 rounded-lg text-base font-semibold bg-[--btn-txt] mt-2 border border-solid border-[--gr-2]`}
            >
              {allSummaryData?.[branch.ipAddress]?.loading ? (
                <div className="px-3">
                  <SummarySkeleton />
                </div>
              ) : allSummaryData?.[branch.ipAddress]?.success &&
                typeof data === "object" ? (
                <Summary data={data} />
              ) : (
                <div className="Poppins flex justify-between px-3">
                  <p className="text-red-400 font-medium">Offline</p>
                  <span
                    className="text-3xl cursor-pointer"
                    onClick={() => handleSummaryClick(branch.ipAddress)}
                  >
                    <HiRefresh />
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Summary = ({ data }) => {
  const summaryTable = data.tables[0].rows;

  return (
    <div className="">
      <table className="w-full h-max text-sm text-left rtl:text-right text-[--gr-5] border border-solid border-[--btn-txt]">
        <tbody>
          {summaryTable.map((cell, index) => (
            <tr className="odd:bg-[--btn-txt] even:bg-[--gr--0]" key={index}>
              {cell.cells.map((rowItem, index) => (
                <th
                  key={index}
                  scope="row"
                  className="py-2 px-3 font-medium text-[--gr-9] whitespace-nowrap odd:text-left even:text-right"
                  dangerouslySetInnerHTML={{ __html: rowItem }}
                ></th>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const customToast = (text) => {
  const toastId = toast(
    <div className="flex w-max gap-2">
      <div className="border-0 border-r-[1px] pr-2 py-4 border-solid border-stone-400 h-full text-center">
        <p>{text}</p>
      </div>
      <button
        className="text-[1.7rem] text-red-500"
        onClick={() => toast.dismiss(toastId)}
      >
        <IoClose />
      </button>
    </div>,
    {
      position: "top-right",
      duration: 2000,
      style: {
        border: "1px solid #713200",
        padding: "0",
        color: "text-[--tx-3]",
        maxWidth: "max-content",
      },
    }
  );
  return toastId;
};

export default BranchCard;
