import Sidebar from "../components/common/sidebar";
import React, { useEffect, useRef, useState } from "react";
import OrdersTable from "../components/tickets/odersTable";
import { useDispatch, useSelector } from "react-redux";
import SidebarSkeleton from "../components/common/sidebarSkeleton";
import ErrorDisplay from "../components/common/error";
import TableSkeleton from "../components/common/tableSkeleton";
import { useNavigate, useParams } from "react-router-dom";
import {
  getUnpaidTickets,
  getTicketDetail,
} from "../redux/tickets/ticketSlice";
import {
  resetGetTicketDetail,
  resetGetUnpaidTickets,
} from "../redux/tickets/ticketSlice";
import {
  extractIp,
  FormatCalendar,
  formatNumber,
  htmlToPng,
} from "../redux/utils";
import TagsTable from "../components/tickets/tagsTable";
import CustomInput from "../components/common/customInput";
import { filterTicketOrders, filterTicketTags } from "../utils/filterTickets";
import { useTranslation } from "react-i18next";
import { customToast } from "../components/branches/branchCard";
import { FaDownload } from "react-icons/fa6";

//import { ticket as localTicket } from "../data/data";

const ActiveAdisyon = () => {
  const { t } = useTranslation();

  const branchUrl = useParams().id;
  const downloadPng = useRef();
  const dispatch = useDispatch();

  const { branches } = useSelector((state) => state.branches);
  const currentBranch =
    branches.length > 0
      ? branches.find((branch) => extractIp(branch.ipAddress) === branchUrl)
          ?.name
      : branchUrl;

  const [selectedRow, setSelectedRow] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [ticketData, setTicketData] = useState(null);
  const [isConverting, setIsConverting] = useState(false);

  const {
    loading: ticketsLoading,
    success: ticketsSuccess,
    tickets,
    error: ticketsError,
  } = useSelector((state) => state.tickets.getUnpaidTickets);

  const {
    ticket,
    loading: ticketLoading,
    success: ticketSuccess,
    error: ticketError,
  } = useSelector((state) => state.tickets.getTicketDetail);

  useEffect(() => {
    setSelectedRow(null);
    dispatch(getUnpaidTickets({ branchUrl }));
  }, [dispatch, branchUrl]);

  useEffect(() => {
    if (ticketsSuccess && tickets) {
      if (!selectedRow) setSelectedRow(tickets.unPaidTickets[0]?.id);
      if (selectedRow) {
        dispatch(
          getTicketDetail({
            branchUrl,
            ticket_id: selectedRow,
          })
        );
      }
    }
  }, [dispatch, branchUrl, selectedRow, tickets, ticketsSuccess]);

  useEffect(() => {
    //setSelectedRow(null);
  }, [branchUrl]);

  /*   const formatToSidebarData = (data) => {
    const arr = [];
    //console.log(data)
    data.map((item) => {
      return item.entities.map((ent) => {
        const res = {
          id: item.id,
          name: ent.name,
          total: item.totalAmount ? item.totalAmount : null,
        };
        arr.push(res);
        return res;
      });
    });
    console.log(arr);
    return arr;
  }; */

  const formatToSidebarData = (data) => {
    const arr = [];
    //console.log(data)
    data.map((item) => {
      let element = {};
      item.entities.map((ent) => {
        element.id = item.id;
        element.name = element.name ? `${element.name}\n${ent.name}` : ent.name;
        element.total = item.totalAmount ? item.totalAmount : null;
        return element;
      });
      return arr.push(element);
    });
    //console.log(arr);
    return arr;
  };

  useEffect(() => {
    return () => {
      dispatch(resetGetTicketDetail());
      dispatch(resetGetUnpaidTickets());
    };
  }, [dispatch]);

  useEffect(() => {
    if (ticketSuccess) {
      setTicketData(ticket);
    }
  }, [ticket, ticketSuccess]);

  const filteredOrders = filterTicketOrders(ticketData?.orders, searchQuery);
  const filteredTags = filterTicketTags(ticketData?.tags, searchQuery);
  //tickets && console.log(tickets);

  /// WINDOWS WIDTH ILE ILGILI
  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));

    return () => {
      window.removeEventListener("resize", () =>
        setWindowWidth(window.innerWidth)
      );
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 950) {
      document.body.classList.add("body_overflow_hidden");
    } else {
      document.body.classList.remove("body_overflow_hidden");
    }
    return () => {
      document.body.classList.remove("body_overflow_hidden");
    };
    //console.log(windowWidth);
  }, [windowWidth]);

  ///************* BRANCH STATUS AND LICENCE CONTROL *****************///
  const branchIp = useParams().id;
  const navigate = useNavigate();
  const { branches: allBranches } = useSelector((state) => state.branches);
  const allSummaryData = useSelector((state) => state.reports.reportSummary);
  useEffect(() => {
    const thisBranch = [];
    if (allBranches.length > 0) {
      const pushBranch = allBranches.filter(
        (branch) => extractIp(branch.ipAddress) === String(branchIp)
      );
      thisBranch.push(pushBranch[0]);
    }

    if (!allSummaryData) return;
    if (thisBranch.length > 0 && Object.keys(allSummaryData).length > 0) {
      const thisBranchIp = thisBranch[0].ipAddress;
      if (!allSummaryData?.[thisBranchIp].loading) {
        if (
          !allSummaryData?.[thisBranchIp].success ||
          thisBranch[0]?.status !== 1
        ) {
          console.log(false);
          navigate("/");
          customToast(`${t("branches.inActive", { branch: "" })}`);
          //console.log(allSummaryData?.[thisBranchIp]);
          //console.log(thisBranch[0]?.status);
        } else {
          console.log(true);
        }
      }
    }
  }, [allBranches, allSummaryData, branchIp, selectedRow, navigate, t]);
  ///************* BRANCH STATUS AND LICENCE CONTROL *****************///

  const handlePngDownload = async (e) => {
    const ticketID = e.currentTarget.dataset.id;
    const ticketName = t("ticketDetails.ticket");
    setIsConverting(true);

    htmlToPng(
      downloadPng,
      ticketName,
      ticketID,
      currentBranch,
      t("png.downloaded"),
      t("png.converting"),
      t("png.error1"),
      setIsConverting
    );
  };

  return (
    <section className="relative pb-2 full_height-footer">
      <div className="flex 950m:gap-2 ">
        <aside>
          {ticketsLoading ? (
            <SidebarSkeleton />
          ) : ticketsError ? (
            <ErrorDisplay
              error={ticketsError}
              onRetry={() => dispatch(getUnpaidTickets({ branchUrl }))}
            />
          ) : ticketsSuccess ? (
            <>
              <Sidebar
                title={`${
                  formatToSidebarData(tickets.unPaidTickets).length
                } ${" "}${t("nav.tickets")}`}
                data={formatToSidebarData(tickets.unPaidTickets)}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
              />
            </>
          ) : null}
        </aside>

        <main className="flex-1 overflow-auto">
          {ticketLoading || ticketsLoading ? (
            <div
              className={`${
                !ticketLoading && ticketsLoading ? "950m:hidden" : ""
              }`}
            >
              <TableSkeleton />
            </div>
          ) : ticketError ? (
            <ErrorDisplay
              error={ticketError}
              onRetry={() => {
                if (!selectedRow) setSelectedRow(tickets.unPaidTickets[0].id);
                if (selectedRow) {
                  dispatch(
                    getTicketDetail({
                      branchUrl,
                      ticket_id: selectedRow,
                    })
                  );
                }
              }}
            />
          ) : ticketData ? (
            <div className="flex flex-col sm:items-center 950m:max-h-[80dvh] 950m:overflow-y-auto">
              <div className="max-w-[30rem] sm:min-w-[30rem] min-w-[auto]">
                <div className="">
                  <CustomInput
                    type="text"
                    placeholder={t("nav.search")}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full left-0 mb-2 p-3 rounded text-[--gr-4] text-sm border border-[--gr-2] border-solid cursor-text"
                  />
                </div>

                <div
                  className="bg-[--bg-3] py-4 px-2 rounded-sm"
                  ref={downloadPng}
                >
                  <div className="mb-2">
                    <div className="w-full flex">
                      <div className="w-full">
                        <h1 className="block text-lg font-bold text-[var(--tx-1)] Poppins text-center">
                          {ticketData.entities.map((entity) => (
                            <React.Fragment key={entity.id}>
                              {entity.name}
                            </React.Fragment>
                          ))}
                        </h1>
                      </div>

                      <button
                        disabled={isConverting}
                        className="text-white text-2xl bg-[--primary] p-2 rounded-lg"
                        onClick={handlePngDownload}
                        data-id={`${ticketData.id}`}
                      >
                        <FaDownload />
                      </button>
                    </div>

                    <div className="pt-2 text-xs text-[--gr-9] flex justify-between Poppins">
                      <p>
                        {t("ticketDetails.date")}:{" "}
                        {FormatCalendar(ticketData.date, "-", true, false)}
                      </p>
                      <p className="font-bold text-[--opposite] w-max">
                        ID: <span className="">{ticketData.id}</span>
                      </p>
                    </div>
                  </div>

                  {filteredOrders.length > 0 && (
                    <div className="mb-2 bg-[--components-color] rounded-lg border-[.5px] border-solid border-[--gr-3] flex-1">
                      <h1 className="block text-base text-[--tx-1] Poppins text-center">
                        {t("ticketDetails.orders")}
                      </h1>
                      {formatOrders(filteredOrders).map((order) => (
                        <div key={order.id}>
                          <OrdersTable orders={order} />
                        </div>
                      ))}
                    </div>
                  )}

                  <div className="border border-solid border-[--gr-3] rounded-md bg-[--gr--0] py-1 px-2">
                    {filteredTags.length > 0 && (
                      <div className="bg-[--components-color] flex-1">
                        <h1 className="block font-normal text-[--tx-1] text-sm Poppins text-center">
                          {t("ticketDetails.ticketTags")}
                        </h1>
                        <TagsTable tags={filteredTags} />
                      </div>
                    )}
                  </div>

                  {filteredTags.length > 0 || filteredOrders.length > 0 ? (
                    <div className="mt-2 border border-solid border-[--gr-3] rounded-sm bg-[--gr-1] py-1 px-2">
                      <div className="bg-[--components-color] text-sm text-[--tx-2] font-normal overflow-x-auto">
                        <table className="w-full text-center text-[--gr-5]">
                          <tbody>
                            {ticketData?.calculations?.map((entity, index) => (
                              <tr
                                key={index}
                                className="odd:bg-[--btn-txtt] even:bg-[--gr--0] border-b flex justify-between"
                              >
                                <td className="flex-col flex items-start justify-start">
                                  {entity.name}
                                </td>
                                <td className="font-normal text-green-500">
                                  {entity.calculationAmount}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        {/* Document Total AND Paid Total */}
                        <table className="w-full text-center text-[--gr-5]">
                          <tbody>
                            <tr
                              key={0}
                              className="odd:bg-[--gr-1] even:bg-[--gr-1] border-b flex justify-between"
                            >
                              <td className="flex-col flex items-start justify-start">
                                {t("ticketDetails.docTotal")}
                              </td>
                              <td className="font-[600] text-[--oposite]">
                                {formatNumber(ticketData.totalAmount)}
                              </td>
                            </tr>
                            <tr
                              key={1}
                              className="odd:bg-[--gr-1] even:bg-[--gr-1] border-b flex justify-between"
                            >
                              <td className="flex-col flex items-start justify-start">
                                {t("ticketDetails.paidTotal")}
                              </td>
                              <td className="font-[600] text-[--oposite]">
                                {formatNumber(
                                  ticketData.totalAmount -
                                    ticketData.remainingAmount
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <ErrorDisplay error="No data found" text={t("404.retry")} />
                  )}
                </div>
              </div>
            </div>
          ) : null}
        </main>
      </div>
    </section>
  );
};

export const formatOrders = (data) => {
  //console.log(data)
  const orders = {};

  data?.forEach((order) => {
    const id = order.number;

    if (!orders[id]) orders[id] = [];

    orders[id].push(order);
  });

  const formattedOrders = Object.keys(orders).map((id) => {
    return {
      id: id,
      orders: orders[id],
    };
  });

  return formattedOrders;
};

export default ActiveAdisyon;
