import React from "react";
import Select from "react-select";

const CustomSelect = ({
  value,
  isSearchable,
  options,
  onChange,
  className,
  isDisabled,
}) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      options={options}
      className={`${className} text-[--tx-7]`}
      isDisabled={isDisabled}
      isSearchable={isSearchable !== undefined ? isSearchable : true}
      styles={{
        control: (provided, state) => ({
          ...provided,
          boxShadow: "none",
          border: "--gr-3",
          backgroundColor: "--btn-txt",
          color: "--gr-7",
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor:
            state.label === value.label ? "var(--gr-4)" : "var(--btn-txt)",
          color:
            state.label === value.label ? "var(--oposite)" : "var(--oposite)",
        }),
        singleValue: (provided, state) => ({
          ...provided,
          color: "var(--gr-7)",
        }),
        menu: (provided, state) => ({
          ...provided,
          backgroundColor: "--btn-txt",
        }),
        menuList: (provided, state) => ({
          ...provided,
          maxHeight: "16rem",
          borderBottomLeftRadius: ".3rem",
          borderBottomRightRadius: ".3rem",
          paddingBottom: "0",
          //scrollbarWidth: "none", // Remove scrollbar for Firefox
          //"&::-webkit-scrollbar": {
          //  display: "none", // Hide scrollbar for Webkit browsers (Chrome, Safari)
          //},
        }),
        input: (provided, state) => ({
          ...provided,
          color: "var(--tx-2)", // Change this to the desired text color
        }),
      }}
    />
  );
};

export default CustomSelect;
