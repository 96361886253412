import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useLang from "../../hooks/useLang";
//import { Us, Tr } from "react-flags-select";

const LanguageSelector = ({ className }) => {
  const { i18n } = useTranslation();
  const getLang = useLang().getLang();
  const setLang = useLang();
  const lang = typeof getLang === "string" ? getLang.toUpperCase() : "TR";

  const [curLang, setCurLang] = useState(lang);

  //const languageOptions = [
  //  { code: "en", label: "EN", flag: <Us /> },
  //  { code: "tr", label: "TR", flag: <Tr /> },
  //  // Add more language options as needed
  //];
  //const [isDropdownOpen, setDropdownOpen] = useState(false);

  const changeLanguage = () => {
    const language = curLang === "TR" ? "EN" : "TR";
    setCurLang(language);
    i18n.changeLanguage(language.toLowerCase());
    //setDropdownOpen(false);
    setLang.setLang(language.toLowerCase());
  };
  return (
    /* <div className="sm:mr-4 relative z-50 pl-1">
      <button className="flex items-center text-lg text-[--tx-2]" onClick={() => setDropdownOpen(!isDropdownOpen)} >
        {languageOptions.find((option) => option.code === i18n.language)?.flag}
        <span className="w-1"></span>
        {languageOptions.find((option) => option.code === i18n.language)?.label}
      </button> */

    //{/* Dropdown */}
    /*     {isDropdownOpen && (
      <div className="absolute -bottom-6 px-1 border bg-[var(---gr-2)]">
        {languageOptions.map((option) => (
          option.code !== i18n.language && (
          <button
            key={option.code}
            onClick={() => changeLanguage(option.code)}
            className="flex gap-1 items-center px-1 py-[0.1rem] text-lg text-[--tx-3]  w-full text-left"
          >
            {option?.flag} {option?.label}
          </button>)
        ))}
      </div>
    )}
    </div> */

    <div>
      <div
        className={`w-[3.3rem] h-7 flex items-center rounded-full p-1 cursor-pointer transition-colors duration-300 border border-[--tx-3] border-solid ${className}`}
        onClick={changeLanguage}
      >
        <div
          className={`bg-white w-5 h-5 rounded-full shadow-md transform transition-transform duration-300 flex items-center justify-center text-sm ${
            curLang === "TR" ? "translate-x-6" : ""
          }`}
        >
          {curLang}
        </div>
      </div>
    </div>
  );
};

export default LanguageSelector;
