import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  excuteAutoCommands,
  getAutomationCommands,
  resetExcuteAutoCommand,
  resetGetAutoCommand,
} from "../redux/otherActions/autoCommandSlice";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import TableSkeleton from "../components/common/tableSkeleton";
import ErrorDisplay from "../components/common/error";
import { useTranslation } from "react-i18next";
import { extractIp } from "../redux/utils";
import { customToast } from "../components/branches/branchCard";

const AutomationCommand = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const branchUrl = useParams().id;
  const { loading, success, error } = useSelector(
    (state) => state.autoCommand.excuteAutoCommand
  );
  const {
    loading: getLoading,
    success: getSuccess,
    error: getError,
    data,
  } = useSelector((state) => state.autoCommand.getAutoCommand);
  const [msg, setMsg] = useState(null);

  const Excute_Automation_Command = (msg) => {
    //console.log(branchUrl, "branch ip")
    setMsg(msg.name);
    dispatch(excuteAutoCommands({ branchUrl, str: msg.name }));
  };

  useEffect(() => {
    if (data && data[branchUrl]) {
      if (loading === "none") {
        toast.loading("Loading...", { position: "top-right" });
      } else {
        if (success) {
          toast.success(t("actions.commandMsg", { msg: msg }), {
            position: "top-right",
          });
          dispatch(resetExcuteAutoCommand());
        }
        if (error) {
          toast.error(error, { position: "top-right" });
          dispatch(resetExcuteAutoCommand());
        }
      }
    }
  }, [loading, success, error, dispatch, branchUrl, data, msg, t]);

  useEffect(() => {
    if (!data?.[branchUrl]) {
      dispatch(getAutomationCommands({ branchUrl }));
      dispatch(resetGetAutoCommand());
    }
    //console.log(branchUrl)
  }, [branchUrl, data, dispatch]);

  const onRetry = () => {
    dispatch(getAutomationCommands({ branchUrl }));
    dispatch(resetGetAutoCommand());
  };

  ///************* BRANCH STATUS AND LICENCE CONTROL *****************///
  const branchIp = useParams().id;
  const navigate = useNavigate();
  const { branches: allBranches } = useSelector((state) => state.branches);
  const allSummaryData = useSelector((state) => state.reports.reportSummary);
  useEffect(() => {
    const thisBranch = [];
    if (allBranches.length > 0) {
      const pushBranch = allBranches.filter(
        (branch) => extractIp(branch.ipAddress) === String(branchIp)
      );
      thisBranch.push(pushBranch[0]);
    }

    if (!allSummaryData) return;
    if (thisBranch.length > 0 && Object.keys(allSummaryData).length > 0) {
      const thisBranchIp = thisBranch[0].ipAddress;
      if (!allSummaryData?.[thisBranchIp].loading) {
        if (
          !allSummaryData?.[thisBranchIp].success ||
          thisBranch[0]?.status !== 1
        ) {
          console.log(false);
          navigate("/");
          customToast(`${t("branches.inActive", { branch: "" })}`);
          //console.log(allSummaryData?.[thisBranchIp]);
          //console.log(thisBranch[0]?.status);
        } else {
          console.log(true);
        }
      }
    }
  }, [allBranches, allSummaryData, branchIp, navigate, t]);
  ///************* BRANCH STATUS AND LICENCE CONTROL *****************///

  return (
    <div className={`full_height-footer_ mb-6 sm:mb-4 ${className}`}>
      {getLoading ? (
        <TableSkeleton />
      ) : getSuccess && data?.[branchUrl] !== undefined ? (
        <div>
          <div className="w-full text-left text-[--gr-5]">
            <div className="text-slate-300">
              <div>
                <p className="px-6 py-1 font-medium text-lg max-sm:text-base bg-[--gr-4]">
                  {t("actions.command")}
                </p>
              </div>
            </div>

            <div className="gap-1 sm:gap-2 flex flex-col mt-2 font-medium w-full text-base max-sm:text-sm">
              {data[branchUrl].map((entity, index) => (
                <div
                  className="px-6 py-4  cursor-pointer bg-[--primary] rounded-sm text-white"
                  onClick={() => Excute_Automation_Command(entity)}
                  key={index}
                >
                  <p>{entity.displayName}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        getError && (
          <ErrorDisplay
            error={getError}
            text={"couldn't fetch the commands"}
            onRetry={onRetry}
          />
        )
      )}
    </div>
  );
};

export default AutomationCommand;
