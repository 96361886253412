import axios from "axios";
import toast from "react-hot-toast";

const api = axios.create({
  headers: { "Content-Type": "application/json" },
  withCredentials: false,
});

const privateApi = axios.create({
  withCredentials: false,
  headers: { "Content-Type": "application/json" },
});

const auth = () => {
  const authItemString = localStorage.getItem("auth");
  const authItem = JSON.parse(authItemString);
  return authItem;
};

const clearAuth = () => {
  localStorage.removeItem("auth");
};

// const setAuth = (authItem) => {
//   localStorage.setItem("auth", JSON.stringify(authItem));
// };

// const refresh = async () => {
//   try {
//     const currentAuth = auth();

//     const response = await api.get("auth/refresh", {
//       withCredentials: true,
//     });

//     if (response.status !== 201) {
//       throw new Error("Failed to refresh token");
//     }

//     setAuth?.({
//       ...currentAuth,
//       token: response.data.token,
//     });
//     return response.data.token;
//   } catch (error) {
//     window.location.href = "/login";
//     throw error;
//   }
// };

export const axiosPrivate = () => {
  privateApi.interceptors.request.use(
    (config) => {
      if (!config.headers["Authorization"]) {
        config.headers["Authorization"] = `Bearer ${auth().token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  privateApi.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response?.status === 401) {
        // await refresh();
        //console.log(auth().token, "in error");
        clearAuth();
        window.location.href = "/login";
        toast.error("Unauthorized user!", { position: "top-right" });
      }
      return Promise.reject(error);
    }
  );

  return privateApi;
};

export default api;
