export const filterUsers = (users, searchQuery) => {
  if (!users || !users.users) return [];

  const normalizedQuery = searchQuery.toLowerCase().trim();

  return users.users.filter((user) => {
    const nameMatch = user.name.toLowerCase().includes(normalizedQuery);
    const roleMatch = findRoleName(user.userRoleId, users)
      .toLowerCase()
      .includes(normalizedQuery);
    return nameMatch || roleMatch;
  });
};

export const findRoleName = (roleId, users) => {
  const role =
    users &&
    users.userRoles &&
    users.userRoles.find((role) => role.id === roleId);
  return role ? role.name : null;
};
