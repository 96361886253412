import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth/authSlice";
import branchSlice from "./branches/branchSlice";
import reportsSlice from "./reports/reportsSlice";
import productsSlice from "./priceList/productsSlice";
import orderTagsSlice from "./priceList/orderTagsSlice";
import themeSlice from "./theme/themeSlice";
import usersSlice from "./users/usersSlice";
import ticketSlice from "./tickets/ticketSlice";
import firstCompareSlice from "./compare/firstCompare";
import secondCompareSlice from "./compare/secondCompare";
import autoCommandSlice from "./otherActions/autoCommandSlice";
import workPeriodSlice from "./otherActions/workPeriodSlice";
import restartMsgServerSlice from "./otherActions/restartMsgServerSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    branches: branchSlice,
    reports: reportsSlice,
    products: productsSlice,
    theme: themeSlice,
    users: usersSlice,
    tickets: ticketSlice,
    Compare1: firstCompareSlice,
    Compare2: secondCompareSlice,
    orderTags: orderTagsSlice,
    autoCommand: autoCommandSlice,
    workPeriod: workPeriodSlice,
    restartMsgServer: restartMsgServerSlice,
  },
});

export default store;
