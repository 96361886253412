import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { formatToUrl } from "../utils";
import api, { axiosPrivate } from "../api";

const privateApi = axiosPrivate();
const port = process.env.REACT_APP_BACKEND_PORT;

const initialState = {
  getBranches: {
    branches: [],
    loading: false,
    error: null,
    success: false,
  },
  getReports: {
    reports: [],
    loading: false,
    error: null,
    success: false,
  },
  getReportDetails: {
    report: null,
    loading: false,
    error: null,
    success: false,
  },
};

const firstCompareSlice = createSlice({
  name: "firstCompare",
  initialState: initialState,
  reducers: {
    resetFirstCompareGetBranches: (state) => {
      state.getBranches = initialState.getBranches;
    },
    resetFirstCompareGetReports: (state) => {
      state.getReports = initialState.getReports;
    },
    resetFirstCompareGetReportDetails: (state) => {
      state.getReportDetails = initialState.getReportDetails;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBranches.pending, (state) => {
        state.getBranches = {
          branches: [],
          loading: true,
          error: null,
          success: false,
        };
      })
      .addCase(getBranches.fulfilled, (state, action) => {
        state.getBranches = {
          branches: action.payload,
          loading: false,
          error: null,
          success: true,
        };
      })
      .addCase(getBranches.rejected, (state, action) => {
        state.getBranches = {
          branches: [],
          loading: false,
          error: action.error.message,
          success: false,
        };
      })
      .addCase(getReports.pending, (state) => {
        state.getReports = {
          reports: [],
          loading: true,
          error: null,
          success: false,
        };
      })
      .addCase(getReports.fulfilled, (state, action) => {
        state.getReports = {
          reports: action.payload,
          loading: false,
          error: null,
          success: true,
        };
      })
      .addCase(getReports.rejected, (state, action) => {
        state.getReports = {
          reports: [],
          loading: false,
          error: action.error.message,
          success: false,
        };
      })
      .addCase(getReport.pending, (state) => {
        state.getReportDetails = {
          report: null,
          loading: true,
          error: null,
          success: false,
        };
      })
      .addCase(getReport.fulfilled, (state, action) => {
        state.getReportDetails = {
          report: action.payload,
          loading: false,
          error: null,
          success: true,
        };
      })
      .addCase(getReport.rejected, (state, action) => {
        state.getReportDetails = {
          report: null,
          loading: false,
          error: action.error.message,
          success: false,
        };
      });
  },
});

export const getBranches = createAsyncThunk(
  "firstCompare/getAllBranches",
  async ({ userId }) => {
    const response = await api.get(
      `https://liwapos.com/samba.mobil/Json/JsonSambaPatronCustomers?userid=${userId}`
    );
    return response.data;
  }
);

export const getReports = createAsyncThunk(
  "firstCompare/getAllReports",
  async ({ branchUrl }) => {
    const formattedUrl = formatToUrl(branchUrl);
    //const response = await privateApi.get(`${formattedUrl}/get_reports`);
    const response = await privateApi.get(`${port}/reports/get_reports`, {
      params: {
        path: formattedUrl,
      },
    });

    return response.data;
  }
);

export const getReport = createAsyncThunk(
  "firstCompare/getReportDetails",
  async ({ branchUrl, reportId, start_date, end_date }) => {
    const formattedUrl = formatToUrl(branchUrl);

    const params =
      start_date && end_date
        ? { start_date, end_date }
        : start_date
        ? { start_date }
        : end_date
        ? { end_date }
        : {};

    //const response = await privateApi.get(`${formattedUrl}/get_report_table`, {
    //  params: {
    //    report: reportId,
    //    use_work_period: false,
    //    ...params,
    //  },
    //});

    const response = await privateApi.get(`${port}/reports/get_report_table`, {
      params: {
        params: {
          report: reportId,
          use_work_period: false,
          ...params,
        },
        path: formattedUrl,
      },
    });

    return response.data;
  }
);

export default firstCompareSlice.reducer;
export const {
  resetFirstCompareGetBranches,
  resetFirstCompareGetReports,
  resetFirstCompareGetReportDetails,
} = firstCompareSlice.actions;
