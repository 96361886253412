import { useTranslation } from "react-i18next";
import { formatNumber } from "../../redux/utils";

const TotalTable = ({ ticket }) => {
  const { t } = useTranslation();
  return (
    <div className="bg-[--gr-1] border-[0px] border-solid border-[--gr-3] text-base text-[--tx-2] font-normal overflow-x-auto">
      <table className="w-full text-sm text-center text-[--gr-5]">
        <tbody>
          {ticket?.calculations?.map((entity, index) => (
            <tr
              key={index}
              className="odd:bg-[--btn-txtt] even:bg-[--gr-1] border-0 border-solid border-[--gr-3] flex justify-between"
            >
              <td className="px-2 flex-col flex items-start justify-start">
                {entity.name}
              </td>
              <td className="px-2 font-bold text-[--primary]">
                {entity.calculationAmount}
              </td>
            </tr>
          ))}

          <tr
            key={0}
            className="odd:bg-[--btn-txtt] even:bg-[--gr-1] border-0 border-solid border-[--gr-3] flex justify-between"
          >
            <td className="p-2 flex-col flex items-start justify-start">
              {t("ticketDetails.docTotal")}
            </td>
            <td className="p-2 font-[600] text-[--oposite]">
              {formatNumber(ticket.totalAmount)}
            </td>
          </tr>

          <tr
            key={1}
            className="odd:bg-[--btn-txtt] even:bg-[--gr-1] flex justify-between"
          >
            <td className="p-2 flex-col flex items-start justify-start">
              {t("ticketDetails.paidTotal")}
            </td>
            <td className="p-2 font-[600] text-[--oposite]">
              {formatNumber(ticket.totalAmount - ticket.remainingAmount)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TotalTable;
