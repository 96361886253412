import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../lang/langselector";
import ThemeToggle from "../common/themeToggle";
import LogoutButton from "../common/logoutBtn";
import { useSelector } from "react-redux";
import { FaRegUser } from "react-icons/fa";
import { useState } from "react";
import RefreshAllSummaries from "../common/refreshAll";

const BranchesNav = () => {
  const { t } = useTranslation();
  const { branches } = useSelector((state) => state.branches);
  const [unameHover, setUnameHover] = useState(false);

  const branchUsername = () => {
    if (branches[0]?.nameSurname) {
      if (branches[0].nameSurname.length > 20) {
        return branches[0].nameSurname.slice(0, 20) + "...";
      } else {
        return branches[0].nameSurname;
      }
    }
  };
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const url =
    location.pathname.split("/")[location.pathname.split("/").length - 1];

  return (
    <section className="w-full overflow-visible bg-[var(--nav-bg-1)]">
      <div className="w-full">
        <div className="w-full flex justify-between h-16 items-center">
          <div className="flex align-bottom gap-4 items-center">
            <Link
              to="/"
              className="text-[var(--tx-5)] font-[conthrax] text-2xl font-semibold"
            >
              Liwa
              <span className="text-[var(--tx-6)] font-[conthrax]">Soft</span>
            </Link>
            <div className="hidden md:flex align-bottom h-full relative">
              <p
                onMouseEnter={() => setUnameHover(true)}
                onMouseLeave={() => setUnameHover(false)}
                className="Poppins text-[--tx-2]"
              >
                {branchUsername()}: {t("branches.totalB")}
                {branches[0]?.nameSurname.length > 20 && unameHover && (
                  <span className="absolute top-8 left-0 bg-[--nav-bg-1] p-2 z-50 rounded-lg text-sm">
                    {branches[0]?.nameSurname}
                  </span>
                )}
              </p>
            </div>
          </div>

          <div className="flex gap-2">
            <div className="md:hidden items-center gap-2 flex">
              <div className="flex items-center">
                <Link to={""}>
                  <RefreshAllSummaries />
                </Link>
              </div>

              <div className="flex items-center">
                <ThemeToggle className="text-[1.5rem]" />
                <LanguageSelector />
              </div>
            </div>

            <div className="relative inset-y-0 left-0 flex items-center md:hidden">
              <button
                className="relative inline-flex items-center justify-center rounded-md p-2 text-[--gr-5] hover:bg-[--bg-2] hover:text-white text-xl"
                onClick={() => setOpen(!open)}
              >
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Open main menu</span>
                <FaRegUser />
              </button>
              {open && (
                <div
                  className="lg:hidden absolute z-50 bg-[--nav-bg-1] top-12 right-0 w-24 rounded-sm"
                  onClick={() => setOpen(!open)}
                >
                  <div className="px-2 pb-3 pt-2 rounded-sm">
                    <ul>
                      <li>
                        <Link to={"profile"}>
                          <p
                            className={`${
                              url === "profile"
                                ? "bg-[var(--gr-4)] text-white rounded"
                                : "text-[var(--tx-6)] hover:bg-[var(--gr-4)] hover:text-white"
                            } px-3 py-2 font-normal`}
                          >
                            {t("nav.profile")}{" "}
                          </p>
                        </Link>
                      </li>

                      <li>
                        <Link to={""}>
                          <LogoutButton />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* All together */}
          <div className="md:flex items-center gap-2 hidden">
            <div className="flex items-center">
              <Link to={""}>
                <RefreshAllSummaries />
              </Link>

              {/*   <Link to={"profile"}>
                <p
                  className={`${
                    url === "profile"
                      ? "bg-[--gr-4] text-white rounded"
                      : "text-[--tx-6] hover:bg-[var(--gr-4)] hover:text-white"
                  } 
                px-3 py-2 font-normal`}
                >
                  {t("nav.profile")}{" "}
                </p>
              </Link> */}

              <Link to={""}>
                <LogoutButton />
              </Link>
            </div>
            <div className="flex items-center">
              <ThemeToggle />
              <LanguageSelector />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BranchesNav;
